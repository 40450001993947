import React, { useState, useRef, useCallback } from 'react';
import cx from 'clsx';
import useIsMounted from 'ismounted';
import { useTranslation } from 'gatsby-plugin-react-i18next';


function closeWindow() {
  window.close();
}

function FieldLabel({ isAlternative, label, alternativeLabel }) {
  return (
    <p
      className={cx(
        'text-center text-xl sm:text-3xl mb-2',
        {
          'text-primary': !isAlternative,
          'text-green-500': isAlternative
        }
      )}
    >
      {isAlternative ? alternativeLabel : label}
    </p>
  );
}

function Method({ wallet }) {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { exchangeRateMessage, address, destinationTag, network, bottomMessage } = wallet;
  const [ isAddressCopied, setIsAddressCopied ] = useState(false);
  const [ isTagCopied, setIsTagCopied ] = useState(false);
  const addressInputRef = useRef();
  const tagInputRef = useRef();
  const toClipboard = useCallback((value, messageStateSetter) => {
    const { clipboard } = navigator;

    if (value && clipboard && clipboard.writeText) {
      clipboard.writeText(value).then(() => {
        if (!isMounted.current) {
          return;
        }

        messageStateSetter(true);
        setTimeout(() => {
          if (isMounted.current) {
            messageStateSetter(false);
          }
        }, 3000);
      });
    }
  }, [ isMounted ]);

  return (
    <>
      <div className="flex justify-center py-6">
        <img
          alt={t('cashier:wallet.addressQrImgAlt')}
          src={`https://chart.googleapis.com/chart?cht=qr&chl=${address}&choe=UTF-8&chs=150x150`}
        />
      </div>
      {network && <p className="text-center text-2xl sm:text-3xl text-gray-700 mb-8">Network: {network}</p>}
      <p className="text-center text-xl sm:text-2xl text-gray-700 mb-8">{exchangeRateMessage}</p>
      <FieldLabel isAlternative={isAddressCopied} label={t('cashier:wallet.addressText')} alternativeLabel={t('cashier:wallet.addressCopiedText')} />
      <div className="flex justify-center flex-col">
        <input
          className="form-input block w-full text-xl sm:text-2xl text-center"
          value={address}
          onClick={() => {
            if (addressInputRef.current) {
              const { current: input } = addressInputRef;
              toClipboard(address, setIsAddressCopied);

              input.setSelectionRange(0, input.value.length);
            }
          }}
          ref={addressInputRef}
          readOnly
        />
        <p className="text-center text-md sm:text-xl mb-1">
          {t('cashier:wallet.addressManualCopyText')}
        </p>
      </div>
      <div className="flex justify-center mb-10">
        <button
          onClick={() => toClipboard(address, setIsAddressCopied)}
          className="button primary"
        >
          {t('cashier:wallet.addressCopyText')}
        </button>
      </div>
      {
        destinationTag &&
        <>
          <FieldLabel isAlternative={isTagCopied} label={t('cashier:wallet.destinationTagText')} alternativeLabel={t('cashier:wallet.destinationTagCopiedText')} />
          <div className="flex justify-center flex-col">
            <input
              className="form-input block w-full text-xl sm:text-2xl text-center"
              value={destinationTag}
              onClick={() => {
                if (tagInputRef.current) {
                  const { current: input } = tagInputRef;
                  toClipboard(destinationTag, setIsTagCopied);

                  input.setSelectionRange(0, input.value.length);
                }
              }}
              ref={tagInputRef}
              readOnly
            />
            <p className="text-center text-md sm:text-xl">
              {t('cashier:wallet.destinationTagManualCopyText')}
            </p>
          </div>
          <div className="flex justify-center mt-2 mb-10">
            <button
              onClick={() => toClipboard(destinationTag, setIsTagCopied)}
              className="button primary"
            >
              {t('cashier:wallet.destinationTagCopyText')}
            </button>
          </div>
        </>
      }
      <div className="flex justify-center">
        <button
          onClick={closeWindow}
          className="button secondary"
        >
          {t('closeText')}
        </button>
      </div>
      <p className="text-center text-md sm:text-xl mt-10">
        {t(bottomMessage)}
      </p>
    </>
  );
}

export default Method;