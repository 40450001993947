import React, { useEffect, useState, useCallback, useRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useDebouncedCallback } from 'use-debounce';

import { isBrowser } from '../util';
import { ChevronRightSvg } from './svg';


function getItemsPerSlide(itemsPerSlide, index = 0) {
  if (typeof itemsPerSlide === 'number' && !isNaN(itemsPerSlide)) {
    return itemsPerSlide;
  }

  return Array.isArray(itemsPerSlide) && itemsPerSlide.length > index && typeof itemsPerSlide[index] === 'number'
    ? itemsPerSlide[index]
    : undefined
  ;
}

const transformStyle= {
  transform: 'translateY(-50%)'
};

export default function Carousel({
  children, galleryViewColumns,
  itemsPerSlide: itemsPerSlideProp = [ 3, 4, 5 ],
  hideDots = false,
  bottomArrow = false,
  arrows = false,
  arrowsClass = ' px-16 md:px-20 lg:px-24',
  className = undefined,
  itemClassName = undefined,
  slideClassName = 'flex items-center justify-center w-full'
}) {
  const numberOfItems = React.Children.count(children);
  const [ itemsPerSlide, setItemsPerSlide ] = useState(getItemsPerSlide(itemsPerSlideProp));
  const [ forceChange, setForceChange ] = useState(0);
  const [ activeSlide, setActiveSlide ] = useState(0);
  const numOfSlides = typeof itemsPerSlide === 'number' && itemsPerSlide > 0 ? Math.ceil(numberOfItems / itemsPerSlide) : 1;
  const handleIndexChange = useCallback(i => {
    if (i < 0) {
      i = numOfSlides - 1;
    } else if (i >= numOfSlides) {
      i = 0;
    }

    setActiveSlide(i);
  }, [ numOfSlides ]);

  if (galleryViewColumns === undefined) {
    galleryViewColumns = numberOfItems;
  }

  useEffect(() => {
    if (typeof forceChange !== 'number' && !isBrowser()) {
      return;
    }

    const windowWidth = window.innerWidth;
    let breakpointIndex = undefined;

    if (windowWidth < 400) {
      breakpointIndex = 0; // micro
    } else if (windowWidth < 768) {
      breakpointIndex = 1; // sm
    } else if (windowWidth < 1024) {
      breakpointIndex = 2; // md
    } else if (windowWidth < 1280) {
      breakpointIndex = 3; // lg
    } else {
      breakpointIndex = 4; // xl
    }

    setItemsPerSlide(getItemsPerSlide(itemsPerSlideProp, breakpointIndex));
  }, [ forceChange, itemsPerSlideProp ]);

  const [ handleResizeDebounced ] = useDebouncedCallback(
    () => {
      setForceChange(forceChange + 1);
    },
    250,
    {
      maxWait: 600
    }
  );

  const updateViewHeight = useRef(null);

  const [ handleHeightUpdateDebounced ] = useDebouncedCallback(
    () => {
      if (updateViewHeight.current) {
        updateViewHeight.current();
      }
    },
    250,
    {
      maxWait: 600
    }
  );

  const handleViewActionRegistration = useCallback(({ updateHeight }) => {
    updateViewHeight.current = updateHeight;
    setTimeout(() => {
      updateViewHeight.current();
    }, 500);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResizeDebounced);
    window.addEventListener('resize', handleHeightUpdateDebounced);

    return () => {
      window.removeEventListener('resize', handleResizeDebounced);
      window.removeEventListener('resize', handleHeightUpdateDebounced);
    };
  }, [ handleResizeDebounced, handleHeightUpdateDebounced ]);

  if (itemsPerSlide === undefined || itemsPerSlide === 0) {
    // show all at once in a flexbox
    const itemStyle = {
      width: `${(1 / galleryViewColumns) * 100}%`
    };

    return (
      <div className="flex flex-wrap justify-center items-center">
        {React.Children.map(children, child => {
          return (
            <div style={itemStyle}>
              {child}
            </div>
          );
        })}
      </div>
    );
  } else if (numberOfItems > 0) {
    const slides = [];
    const childArray = React.Children.toArray(children);
    const itemStyle = {
      width: `${(1 / itemsPerSlide) * 100}%`
    };
    let slideLabel = '';
    let slideUrl = '';

    for (let i = 0; i < numberOfItems; i++) {
      if (i % itemsPerSlide === 0) {
        if (slides.length > 0) {
          const lastSlideIndex = slides.length - 1;

          slides[lastSlideIndex] = (
            <div key={lastSlideIndex} className={slideClassName}>
              {slides[lastSlideIndex]}
            </div>
          );
        }

        slides.push([]);
      }

      const currentSlideIndex = slides.length > 0 ? slides.length - 1 : 0;
      const currentChild = childArray[i];

      if (i === activeSlide) {
        if (currentChild.props.alt) {
          slideLabel = currentChild.props.alt;
        }

        if (currentChild.props.href) {
          slideUrl = currentChild.props.href;
        }
      }

      slides[currentSlideIndex].push(
        <div
          key={i}
          className={itemClassName ? itemClassName : 'flex-initial'}
          style={itemStyle}
        >
          {currentChild}
        </div>
      );
    }

    const lastSlideIndex = slides.length - 1;

    slides[lastSlideIndex] = (
      <div key={lastSlideIndex} className={slideClassName}>
        {slides[lastSlideIndex]}
      </div>
    );

    return (
      <div className={className}>
        <div className={`relative${arrows ? arrowsClass : ''}`}>
          <SwipeableViews
            animateHeight
            index={activeSlide}
            onChangeIndex={handleIndexChange}
            action={handleViewActionRegistration}
          >
            {slides}
          </SwipeableViews>
          {bottomArrow && (
            <div className="absolute bottom-0 right-0 pb-2 md:pb-5 px-3 md:px-10 flex items-center justify-center">
              <p className="text-accent m-0 text-2xl md:text-4xl">
                {!slideUrl && slideLabel}
                {slideUrl && <a href={slideUrl}>{slideLabel}</a>}
                <span className="pl-2 md:pl-4 text-3xl md:text-5xl">{activeSlide + 1} / {slides.length}</span>
              </p>
              <button
                className="outline-none bg-transparent border-0 rounded-full p-4"
                onClick={() => handleIndexChange(activeSlide + 1)}
              >
                <ChevronRightSvg />
              </button>
            </div>
          )}
          {arrows && numOfSlides > 1 && (
            <>
              <button
                className="outline-none bg-transparent border-0 rounded-full p-4 absolute left-0 top-1/2"
                onClick={() => handleIndexChange(activeSlide - 1)}
                style={transformStyle}
              >
                <ChevronRightSvg sm r180 />
              </button>
              <button
                className="outline-none bg-transparent border-0 rounded-full p-4 absolute right-0 top-1/2"
                onClick={() => handleIndexChange(activeSlide + 1)}
                style={transformStyle}
              >
                <ChevronRightSvg sm />
              </button>
            </>
          )}
        </div>
        {!hideDots &&
          <div className="text-center mobile:hidden">
            {slides.map((slide, i) => {
              return (
                <button
                  className={`${i === activeSlide ? 'bg-accent' : 'bg-primary'} hover:bg-accent text-zero rounded-full w-8 h-8 m-2`}
                  onClick={() => setActiveSlide(i)}
                  key={i}
                >
                  {i}
                </button>
              );
            })}
          </div>
        }
      </div>
    );
  }

  return null;
}