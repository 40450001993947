import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import TextField from '../form/textField';
import { SearchSvg } from '../svg';


function Search({
  value,
  onChange
}) {
  const { navigate, t } = useI18next();

  return (
    <div className="flex items-center w-1/3 max-w-md m-auto justify-center">
      <TextField
        name="searchTerm"
        onChange={el => {
          if (onChange) {
            onChange(el.target.value);
          }
        }}
        type="search"
        wrapperClasses=""
        placeholder={t('game.searchFieldPlaceholder')}
        xl
        hideError
        value={value || ''}
        onKeyPress={ev => {
          if (ev.key === 'Enter') {
            navigate(`/search/?q=${encodeURIComponent(value)}`);
          }
        }}
      />
      <SearchSvg className="ml-2" onClick={ev => {
        if (value) {
          navigate(`/search/?q=${encodeURIComponent(value)}`);
        }
      }} />
    </div>
  );
}

export default Search;
