import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { fetchAnonKey, checkAnonKey, fetchPlayer } from '../store/player/actions';
import { refreshPlayerMeta } from '../store/player/actions';
import { isBrowser } from '../util';


let initialRun = false;

export default function RootWrapper({ store, element, anonKey, playerKey }) {
  const { dispatch } = store;

  if (isBrowser() && !initialRun) {
    initialRun = true;

    if (anonKey) {
      if (playerKey) {
        dispatch(fetchPlayer(anonKey, playerKey));
      } else {
        dispatch(checkAnonKey(anonKey));
      }
    } else {
      dispatch(fetchAnonKey());
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(refreshPlayerMeta());
    }, 25000);

    return () => {
      clearInterval(interval);
    };
  }, [ dispatch ]);

  return (
    <Provider store={store}>
      {element}
      <ToastContainer
        position={toast.POSITION.BOTTOM_LEFT}
        draggablePercent={50}
        closeOnClick
        pauseOnHover
      />
    </Provider>
  );
}