import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { ChevronRightSvg } from '../svg';
import { useApiImageRoot } from '../../hooks';


function MethodsList({ methodType, methods }) {
  const apiImageRoot = useApiImageRoot();
  const { t } = useTranslation();

  return (
    <>
      <h2 className="text-center">
        {t(`cashier:${methodType === 'deposit' ? 'depositTitle' : 'withdrawTitle'}`)}
      </h2>
      <ul className="list-none mt-0 flex flex-wrap items-center justify-center micro:max-w-none max-w-2xl m-auto">
        {methods.map(({ name, code, timeMessage, image }) => {
          return (
            <li key={code} className="w-full p-2">
              <Link className="flex flex-no-wrap items-center" to={`/cashier/${methodType}/${code}/`}>
                <img className="flex-none micro:w-24 w-32 micro:mr-2 mr-3" src={`${apiImageRoot}${image}`} alt={name} />
                <div className="flex-1">
                  <h3 className="mb-0 micro:text-2xl text-3xl">{name}</h3>
                  <p className="mb-0 micro:text-xl text-2x">{timeMessage}</p>
                </div>
                <div className="flex-none p-2 ml-auto">
                  <ChevronRightSvg sm />
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default MethodsList;