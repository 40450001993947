import { SET, START_LOADING, RESET } from './types';

const initialState = {
  transactions: undefined,
  loading: false
};

export default function playerTransactions(
  state = initialState,
  action
) {
  const { type, transactions } = action;
  let newState = {};

  switch (type) {
    case SET:
      newState.transactions = transactions;
      newState.loading = false;
      break;

    case START_LOADING:
      newState.loading = true;
      break;

    case RESET:
      newState = initialState;
      break;

    default:
  }

  return { ...state, ...newState };
};
