import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import Modal from 'react-modal';

import './index.css';
import TawkToButton from '../components/tawkToButton';
import PendingWithdrawals from '../components/pendingWithdrawals';
import { setGoft, setPap } from '../store/api/actions';


function isGoftSet(s) {
  return !!s.api.goft;
}

function Layout({ children, location: { search } }) {
  const dispatch = useDispatch();
  const goftExists = useSelector(isGoftSet);

  useEffect(() => Modal.setAppElement('#___gatsby'), []);

  useEffect(() => {
    const params = qs.parse(search);

    const gofToken = params.goft || localStorage.getItem('gofToken') || '';
    const aAid = params.a_aid || localStorage.getItem('aAid') || '';
    const aBid = params.a_bid || localStorage.getItem('aBid') || '';

    if (!goftExists && gofToken) {
      dispatch(setGoft(gofToken));
      localStorage.setItem('gofToken', gofToken);
    }

    if (aAid && aBid) {
      dispatch(setPap({
        aAid,
        aBid
      }));
      localStorage.setItem('aAid', aAid);
      localStorage.setItem('aBid', aBid);
    }
  }, [ search, goftExists, dispatch ]);

  return (
    <>
      <PendingWithdrawals />
      <TawkToButton />
      {children}
    </>
  );
}

export default Layout;
