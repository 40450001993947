import { makeApiFetch } from '..';
import { SET_ACTIVE, SET_HISTORY, START_LOADING, STOP_LOADING, RESET } from './types';

import sanitizeBonus from './sanitizeBonus';


export const setActive = active => ({
  type: SET_ACTIVE,
  active
});

export const setHistory = history => ({
  type: SET_HISTORY,
  history
});

export const startLoading = () => ({
  type: START_LOADING
});

export const stopLoading = () => ({
  type: STOP_LOADING
});

export const reset = () => ({
  type: RESET
});

export const fetchPlayerBonus = () => (dispatch, getState) => {
  const { player, playerBonus } = getState();

  if (!player.playerKey || playerBonus.loading) {
    return;
  }

  dispatch(startLoading());

  return makeApiFetch(
    `player/bonusHistory`,
    getState(),
    {
      data: {
        player_id: player.id,
        limit: 5000,
        offset: 0
      },
      method: 'post'
    }
  )
    .then(resp => {
      if (resp.info && resp.info.success) {
        const { activeBonus, bonusHistory } = resp.data;

        if (activeBonus) {
          if (Array.isArray(activeBonus) && activeBonus.length > 0) {
            dispatch(setActive(sanitizeBonus(activeBonus[0])));
          } else if (activeBonus && typeof activeBonus.id === 'number') {
            dispatch(setActive(sanitizeBonus(activeBonus)));
          } else {
            dispatch(setActive(null));
          }
        }

        if (Array.isArray(bonusHistory) && bonusHistory.length > 0) {
          dispatch(setHistory(bonusHistory.map(history => sanitizeBonus(history))));
        }

        dispatch(stopLoading());

        return;
      }

      return Promise.reject();
    })
    .catch(() => {
      dispatch(reset());
    });
};
