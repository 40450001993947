const cache = new Map();

const cacheObj = {
  set: (key, value) => cache.set(key, value),
  get: key => cache.get(key),
  delete: key => cache.delete(key),
  has: key => cache.has(key),
  store: cache
};

export default function useCache() {
  return cacheObj;
}