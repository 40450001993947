import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import './gameList.css';


function GameList({ games, emptyMsg, className = '' }) {
  const { t } = useTranslation();

  if (games && Array.isArray(games.edges)) {
    games = games.edges;
  }

  if (!Array.isArray(games) || games.length === 0) {
    if (!emptyMsg) {
      emptyMsg = t('genericNoGames');
    }

    return (
      <p className="h-64 mx-0 my-4 flex justify-center items-center">
        <span className="text-lg sm:text-2xl text-muted">{emptyMsg}</span>
      </p>
    );
  }

  return (
    <ol className={`flex flex-wrap justify-center list-none mx-0 my-4 ${className}`}>
      {games.map(game => {
        if (game.node) {
          game = game.node;
        }

        return (
          <li key={game.slug} className="block p-1 sm:p-2 md:p-3 w-1/3 sm:w-1/4 lg:w-1/5">
            <Link
              to={`/game/${game.slug}/`}
              className="block gameListItem"
            >
              <span className="block rounded-t-lg overflow-hidden">
                <img src={game.images.filled.url} alt={game.title} />
                <span className="icon" />
              </span>
              <p className="text-lg sm:text-xl truncate my-0 pt-1 px-2 bg-accent text-white title">{game.title}</p>
              <p className="text-base sm:text-lg truncate my-0 px-2 text-muted">{game.provider}</p>
            </Link>
          </li>
        );
      })}
    </ol>
  );
}

export default GameList;
