function dataToParams(data) {
  if (!data) {
    return data;
  }

  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

function apiFetch(
  url,
  {
    apiRoot,
    apiKey,
    anonKey,
    playerKey,
    lang = 'en_EN',
    method = 'GET',
    responseType,
    data,
    headers
  } = {}
) {
  headers = {
    ...headers,
    'api-key': apiKey,
    'x-language': lang
  };

  if (anonKey) {
    headers['x-anon-session'] = anonKey;
  }

  if (playerKey) {
    headers['x-player-session'] = playerKey;
  }

  let body = undefined;

  if (method === 'GET') {
    const params = dataToParams(data);

    if (params) {
      url += `?${params}`;
    }
  } else {
    body = data ? JSON.stringify(data) : undefined;
  
    if (body) {
      headers['Content-Type'] = 'application/json;charset=utf-8';
    }
  }

  let promise = fetch(
    `${apiRoot}${url}`,
    {
      body,
      headers,
      method: method,
      mode: 'cors'
    }
  );

  if (!responseType) {
    if (method === 'HEAD') {
      responseType = 'text';
    } else {
      responseType = 'json';
    }
  }

  if (responseType === 'json') {
    promise = promise.then(res => res.json());
  } else if (responseType === 'text') {
    promise = promise.then(res => res.text());
  }

  return promise;
}

exports.apiFetch = apiFetch;