const gameCategories = {
  'all': 'All',
  'video-slots': 'Video slots',
  'table-games': 'Table games',
  'video-poker': 'Video poker',
  'scratch-cards': 'Scratch cards',
  'video-bingo': 'Video bingo',
  'new': 'New',
  'jackpot': 'Jackpot'
};

const availableGameCategories = Object.keys(gameCategories).filter(c => c !== 'all');


exports.gameCategories = gameCategories;
exports.availableGameCategories = availableGameCategories;