const { navigate } = require('gatsby');

// const { isBrowser } = require('./isBrowser');
const { generateLanguageUrl } = require('./generateLanguageUrl');


// let redirectOnClose = false;
// let interval;
// let cashierWindow;
// let initLang;

// if (isBrowser()) {
//   window.registerCashierSubmit = () => {
//     redirectOnClose = true;
//   };
// }

// function isWindowOpen() {
//   if (!isBrowser()) {
//     return false;
//   }

//   return !!cashierWindow && !cashierWindow.closed;
// }

function openCashier(lang) {
  navigate(generateLanguageUrl('/cashier/', lang));

  // if (isWindowOpen()) {
  //   cashierWindow.focus();

  //   return;
  // }

  // initLang = lang;

  // cashierWindow = window.open(
  //   generateLanguageUrl('/cashier/', lang),
  //   'cashier',
  //   'dependent=1,personalbar=0,location=0,toolbar=0,menubar=0,height=600,width=500'
  // );

  // interval = setInterval(finishCashier, 200);
}

// function finishCashier() {
//   if (!isBrowser()) {
//     return;
//   }

//   if (!isWindowOpen()) {
//     clearInterval(interval);
//     cashierWindow = undefined;

//     if (redirectOnClose) {
//       redirectOnClose = false;
//       navigate(generateLanguageUrl('/profile/transaction-log/', initLang));
//     }
//   }

//   initLang = undefined;
// }


module.exports = {
  openCashier
};