import { SET, START_LOADING, RESET } from './types';

const initialState = {
  balance: undefined,
  bonus: undefined,
  status: undefined,
  loading: false
};

export default function playerBalance(
  state = initialState,
  action
) {
  const { type, balance, bonus, status } = action;
  let newState = {};

  switch (type) {
    case SET:
      newState.balance = balance;
      newState.bonus = bonus;
      newState.status = status;
      newState.loading = false;
      break;

    case START_LOADING:
      newState.loading = true;
      break;

    case RESET:
      newState = initialState;
      break;

    default:
  }

  return { ...state, ...newState };
};
