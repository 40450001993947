import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';
import useIsMounted from 'ismounted';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as Yup from 'yup';

import { usePlayer, useApiFetch } from '../../hooks';
import { countries, countryOptions } from '../../util';
import Yupmik from '../yupmik';
import { setPlayerData } from '../../store/player/actions';


function InfoItem({ label, value }) {
  return (
    <div className="sm:w-1/2 text-xl sm:text-2xl truncate">
      <span className="inline-block w-2/5 text-right mr-2 sm:mr-3 md:mr-4">{label}:</span>
      <span className="font-bold">{value}</span>
    </div>
  );
}

function ChangePassword({ t }) {
  const isMounted = useIsMounted();
  const [ submitting, setSubmitting ] = useState(false);
  const [ formErrors, setFormErrors ] = useState();
  const [ complete, setComplete ] = useState(false);
  const { apiUserFetch } = useApiFetch();
  const handleUpdate = useCallback(data => {
    setSubmitting(true);
    setFormErrors(undefined);
    setComplete(false);

    apiUserFetch('player/changepass', {
      data: {
        old_password: data.old_password,
        new_password: data.new_password
      },
      method: 'POST'
    })
      .then(resp => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);

        if (!resp || !resp.info || !resp.info.resultCode) {
          throw new Error();
        }

        if (resp.info.resultCode === 'invalid_old_password') {
          setFormErrors({
            '__form__': t('auth:settings.passwordIncorrectMessage')
          });

          return;
        }

        setComplete(true);
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);
        setFormErrors({
          '__form__': t('auth:unexpectedError')
        })
      });
  }, [ apiUserFetch, isMounted, t ]);

  const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string()
      .label(t('auth:settings.currentPasswordLabel'))
      .min(8)
      .required()
      .meta({
        password: true
      }),
    new_password: Yup.string()
      .label(t('auth:settings.newPasswordLabel'))
      .min(8)
      .required()
      .meta({
        password: true,
        cols: 2
      }),
    new_password_confirm: Yup.string()
      .label(t('auth:settings.confirmNewPasswordLabel'))
      .oneOf([Yup.ref('new_password'), null], t('auth:settings.passwordMatchLabel'))
      .required()
      .meta({
        password: true,
        cols: 2
      }),
  });

  return (
    <div className="paper my-3">
      <h2 className="text-center">{t('auth:settings.changePasswordLabel')}</h2>
      {complete && <p className="text-center text-green-600">{t('auth:settings.passwordChangeSuccesfully')}</p>}
      <Yupmik
        validationSchema={changePasswordSchema}
        onSubmit={handleUpdate}
        submitting={submitting}
        errors={formErrors}
        submitText={t('auth:settings.updatePasswordLabel')}
      />
    </div>
  );
}

export default function Settings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const player = usePlayer();
  const { apiUserFetch } = useApiFetch();
  const [ submitting, setSubmitting ] = useState(false);
  const [ formErrors, setFormErrors ] = useState();
  const handleSettingsUpdate = useCallback(data => {
    const localData = { ...data };

    localData.newsletter = localData.newsletter === '1';

    localData.countrycode = localData.countrycode.value;
    localData.phone = localData.phone ? '+' + localData.phone : localData.phone;
    localData.mobile = localData.mobile ? '+' + localData.mobile : localData.mobile;

    if (localData.birthdate && typeof localData.birthdate !== 'string') {
      localData.birthdate = format(localData.birthdate, 'yyyy-MM-dd');
    }

    setSubmitting(true);
    setFormErrors(undefined);

    apiUserFetch('player/update/long', {
      data: localData,
      method: 'POST'
    })
      .then(resp => {
        if (!resp || !resp.info || !resp.info.success) {
          throw new Error();
        }

        dispatch(setPlayerData(resp.data));

        if (isMounted.current) {
          setSubmitting(false);
        }
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);
        setFormErrors({
          '__form__': t('auth:unexpectedError')
        })
      });
  }, [ apiUserFetch, dispatch, isMounted, t ]);

  const initialPhone = !player.phone || player.phone[0] !== '+' ? player.phone : player.phone.substr(1);
  const initialMobile = !player.mobile || player.mobile[0] !== '+' ? player.mobile : player.mobile.substr(1);

  const editPlayerSchema = Yup.object().shape({
    fname: Yup.string()
      .label(t('auth:settings.firstNameLabel'))
      .min(2)
      .default('')
      .matches(new RegExp('^[a-z ,.\'-]+$', 'ui'), t('forms:genericCharError'))
      .required()
      .meta({
        cols: 2
      }),
    lname: Yup.string()
      .label(t('auth:settings.lastNameLabel'))
      .min(2)
      .default('')
      .matches(new RegExp('^[a-z ,.\'-]+$', 'ui'), t('forms:genericCharError'))
      .required()
      .meta({
        cols: 2
      }),
    birthdate: Yup.date()
      .label(t('auth:settings.birthdayLabel'))
      .required()
      .meta({
        cols: 2
      }),
    countrycode: Yup.mixed()
      .label(t('auth:settings.countryLabel'))
      .oneOf(countryOptions, null)
      .required()
      .meta({
        cols: 2,
        select: true
      }),
    address: Yup.string()
      .label(t('auth:settings.addressLabel'))
      .default('')
      .max(50)
      .required()
      .meta({
        cols: 2
      }),
    phone: Yup.string()
      .label(t('auth:settings.phoneLabel'))
      .default('')
      .required()
      .max(24)
      .meta({
        prefix: '+',
        cols: 2
      }),
    mobile: Yup.string()
      .label(t('auth:settings.mobileLabel'))
      .default('')
      .required()
      .max(24)
      .meta({
        prefix: '+',
        cols: 2
      }),
    city: Yup.string()
      .label(t('auth:settings.cityLabel'))
      .default('')
      .required()
      .max(50)
      .meta({
        cols: 2
      }),
    newsletter: Yup.boolean()
      .label(t('auth:settings.newslettersLabel'))
      .default(false)
      .meta({
        cols: 2,
        vCenter: true
      })
  });

  return (
    <>
      <div className="paper sm:flex flex-wrap my-3">
        <InfoItem label={t('auth:settings.usernameLabel')} value={player.username} />
        <InfoItem label={t('auth:settings.emailLabel')} value={player.email} />
        <InfoItem label={t('auth:settings.nameLabel')} value={player.fname} />
        <InfoItem label={t('auth:settings.surnameLabel')} value={player.lname} />
        <InfoItem label={t('auth:settings.countryLabel')} value={countries[player.countrycode] || t('auth:settings.unknownCountryLabel')} />
        <InfoItem label={t('auth:settings.cityLabel')} value={player.city} />
        <InfoItem label={t('auth:settings.phoneLabel')} value={player.phone} />
        <InfoItem label={t('auth:settings.mobileLabel')} value={player.mobile} />
        {player.birthdate && <InfoItem label={t('auth:settings.birthdayLabel')} value={format(new Date(player.birthdate), 'do MMM yyyy')} />}
      </div>
      <div className="paper my-3">
        <h2 className="text-center">{t('auth:settings.label')}</h2>
        <Yupmik
          initialValues={{ ...player, phone: initialPhone, mobile: initialMobile }}
          validationSchema={editPlayerSchema}
          onSubmit={handleSettingsUpdate}
          submitting={submitting}
          errors={formErrors}
          submitText={t('auth:settings.updateSettingsLabel')}
        />
      </div>
      <ChangePassword t={t} />
    </>
  );
}