import { SET_ACTIVE, SET_HISTORY, START_LOADING, STOP_LOADING, RESET } from './types';


const initialState = {
  active: undefined,
  history: undefined,
  loading: false
};

export default function playerBonus(
  state = initialState,
  action
) {
  const { type, active, history } = action;
  let newState = {};

  switch (type) {
    case SET_ACTIVE:
      newState.active = active;
      break;

    case SET_HISTORY:
      newState.history = history;
      break;
  
    case START_LOADING:
      newState.loading = true;
      break;

    case STOP_LOADING:
      newState.loading = false;
      break;

    case RESET:
      newState = initialState;
      break;

    default:
  }

  return { ...state, ...newState };
};
