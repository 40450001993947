import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import StandardPage from '../standardPage';
import ContentfulRichText from '../../components/contentfulRichText';
import ContentListItem from '../../components/contentListItem';


export default function List({ data }) {
  const { t } = useTranslation();
  const { allContentfulBlog, contentfulPage: { url: baseUrl } } = data;

  return (
    <StandardPage data={data}>
      <div className="mt-20">
        {allContentfulBlog.edges.map(({ node: { slug, title, summary, hero } }) => {
          const url = `/${baseUrl}/${slug}/`;

          return (
            <ContentListItem
              key={slug}
              title={title}
              content={<ContentfulRichText raw={summary.raw} />}
              image={(hero && <Link to={url}><Img fluid={hero.fluid} alt={hero.alt || ''} /></Link>) || null}
              detailsButtonText={t('expandContentButton')}
              url={url}
            />
          );
        })}
      </div>
    </StandardPage>
  );
};

export const pageQuery = graphql`
  query($key: String!, $cid: String!, $language: String!, $headerImageMaxWidth: Int!, $imgQuality: Int!) {
    contentfulPage(contentful_id: {eq: $cid}) {
      title
      content {
        raw
      }
      metaTitle
      metaDescription
      url
      hero {
        fluid(
          maxWidth: $headerImageMaxWidth,
          quality: $imgQuality
        ) {
          ...GatsbyContentfulFluid
        }
        alt: title
      }
    }
    alternateLanguagePages: allContentfulPage(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
    allContentfulBlog(filter: {language: {code: {eq: $language}}}, sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          slug
          title
          summary {
            raw
          }
          hero {
            fluid(
              maxWidth: $headerImageMaxWidth,
              quality: $imgQuality
            ) {
              ...GatsbyContentfulFluid
            }
            alt: title
          }
        }
      }
    }
  }
`;
