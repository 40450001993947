import { makeApiFetch } from '..';
import { SET, START_LOADING, RESET_ALL } from './types';
import { buildProviderHelpers } from '../../util';


export const setGameProviders = list => ({
  type: SET,
  list
});

export const startLoading = () => ({
  type: START_LOADING
});

export const reset = () => ({
  type: RESET_ALL
});

export const fetchPlayerProviders = () => (dispatch, getState) => {
  const { player, gameProviders } = getState();

  if (!player.playerKey || gameProviders.loading) {
    return;
  }

  dispatch(startLoading());

  return makeApiFetch(
    `games/providers/all`,
    getState()
  )
    .then(resp => {
      if (resp.info && resp.info.success) {
        const list = resp.data.providers.filter(p => p !== 'all');

        buildProviderHelpers(list);

        dispatch(setGameProviders(list));

        return;
      }

      return Promise.reject();
    })
    .catch(() => {
      dispatch(reset());
    });
};
