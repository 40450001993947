import { SET, START_LOADING, RESET } from './types';

const initialState = {
  transactionIds: undefined,
  amountStr: '',
  amount: 0,
  loading: false
};

export default function pendingWithdrawals(
  state = initialState,
  action
) {
  const { type, transactionIds, amountStr, amount } = action;
  let newState = {};

  switch (type) {
    case SET:
      newState.transactionIds = transactionIds;
      newState.amountStr = amountStr;
      newState.amount = amount;
      newState.loading = false;
      break;

    case START_LOADING:
      newState.loading = true;
      break;

    case RESET:
      newState = initialState;
      break;

    default:
  }

  return { ...state, ...newState };
};
