import React from 'react';
import Img from 'gatsby-image';


export default function ReviewCard({ name, url, logo }) {
  console.log(logo);
  return (
    <div className="max-w-xl mx-auto mt-4 mb-8 flex bg-trueGray-800 items-center">
      {logo && logo.fluid && <div className="p-4 w-24 md:w-36"><Img
        fluid={logo.fluid}
        alt={logo.alt || ''}
      /></div>}
      <div className="p-4">
        <p className="text-xl pb-3">{name}</p>
        <a className="button primary inline-block" href={url}>Visit casino</a>
      </div>
    </div>
  );
};
