import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import MultiCategoryGameList from '../components/games/multiCategoryGameList';
import Seo from '../components/seo';
import ContentfulPageContent from '../components/contentfulPageContent';
import StandardPage from './standardPage';
import { usePathParams } from '../hooks';
import { generateLanguageUrl } from '../util';


const SlotsPage = props => {
  const { t } = useTranslation();
  const { allBogGame, contentfulPage, alternateLanguagePages } = props.data;
  const { gamesPerPage, gameCategories, matchPath, language } = props.pageContext;
  const basePageUrl = matchPath.substr(1, matchPath.length - 3);

  const [ pathnameCategory, pathnamePage ] = usePathParams(props.location.pathname, basePageUrl);

  let pageNum = parseInt(pathnameCategory);
  if (pathnamePage && isNaN(pageNum)) {
    pageNum = parseInt(pathnamePage);
  }

  if (isNaN(pageNum)) {
    pageNum = 1;
  }

  const selectedCategory = gameCategories.includes(pathnameCategory) ? pathnameCategory : 'all';

  const pageSuffix = pageNum > 1 ? ` - ${t('pageNumSuffix', { pageNum })}` : '';
  const h1Text = `${contentfulPage.title}${pageSuffix}`;
  const metaTitleText = `${contentfulPage.metaTitle}${pageSuffix}`;
  const wrapperId = 'game-list-wrapper';

  return (
    <StandardPage topPageData={false} {...props}>
      <Seo
        title={metaTitleText}
        description={contentfulPage.metaDescription}
        alternateLanguagePages={alternateLanguagePages}
        canonical={generateLanguageUrl(basePageUrl, language)}
      />
      <div className="centeredContentWrapper" id={wrapperId}>
        <h1 className="text-center">{h1Text}</h1>
        <MultiCategoryGameList
          gqlData={allBogGame}
          linkUrlBase={basePageUrl}
          availableCategories={gameCategories}
          selectedCategory={selectedCategory}
          currentPage={pageNum}
          gamesPerPage={gamesPerPage}
          scrollToOnPagination={wrapperId}
          appendPaginationCategory
        />
        {
          contentfulPage.content && contentfulPage.content.raw && (
            <ContentfulPageContent data={contentfulPage} />
          )
        }
      </div>
    </StandardPage>
  );
};

export default SlotsPage;

export const pageQuery = graphql`
  query($gamesPerPage: Int!, $gameCategories: [String!]!, $headerImageMaxWidth: Int!, $contentImgMaxWidth: Int!, $imgQuality: Int!, $cid: String!, $key: String!, $language: String!) {
    allBogGame(skip: 0, limit: $gamesPerPage, filter: {category: {in: $gameCategories}, isMobile: {eq: true}}, sort: {order: ASC, fields: position}) {
      totalCount
      edges {
        node {
          slug
          title
          images {
            background {
              url
            }
            filled {
              url
            }
            icon {
              url
            }
            preview {
              url
            }
          }
          provider
          category
          position
        }
      }
    }
    contentfulPage(buildPage: {eq: false}, contentful_id: {eq: $cid}) {
      title
      summary {
        raw
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            cid: contentful_id
            fixed(
              width: $contentImgMaxWidth,
              quality: $imgQuality
            ) {
              src
            }
            alt: title
            href: description
          }
        }
      }
      hero {
        fluid(
          maxWidth: $headerImageMaxWidth,
          quality: $imgQuality
        ) {
          ...GatsbyContentfulFluid
        }
        alt: title
      }
      metaTitle
      metaDescription
    }
    alternateLanguagePages: allContentfulPage(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
  }
`;
