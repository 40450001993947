import React, { useState, useEffect, useRef } from 'react';
import { subMonths, format, parseISO } from 'date-fns';
import useIsMounted from 'ismounted';

import DateField from '../form/dateField';
import { useApiFetch, useApiImageRoot, usePlayer } from '../../hooks';
import { formatCurrency, noop } from '../../util';


function GameLogItem({
  log: {
    timeStamp, gamename, system, credit, debit, balance, url
  },
  currencyCode
}) {
  const [ showDetails, setShowDetails ] = useState(false);
  const apiRoot = useApiImageRoot();
  /**
   * {
   *  "info":{"success":true,"resultCode":"success","message":"success"},
   *  "data": [
   *    {
   *      "id":837970429,
   *      "time_stamp":"2023-05-03 12:26:31",
   *      "details":"gv_f36ae3ea-2171-4eb4-8229-12cd142bae21***35621***Indi (gv)",
        * "credit":0,
        * "debit":0,
        * "balance":5,
        * "transid":837970429,
        * "agentuser":null,
        * "system":"G Games",
        * "accountuser":"ceraTest",
        * "url":"\/players\/gamehistory\/ODM3OTcwNDI5LmJiODkyOTBlODg5MDM3MmM0MjViMzUwMTliMjc5Njc0?h=1000",
        * "gamename":"Indi (gv)",
        * "urldesc":"Player history G Games (Indi (gv))"
      * },
      * {
      *   "id":837970177,
        * "time_stamp":"2023-05-03 12:25:20",
        * "details":"qs_105644273-c6ba9f7dc81ac94c8782a41e0220ea0e***35577***Big Bad Wolf Megaways (qs)",
        * "credit":0,
        * "debit":0,
        * "balance":5,
        * "transid":837970177,
        * "agentuser":null,
        * "system":"Quickspin",
        * "accountuser":"ceraTest",
        * "url":"\/players\/gamehistory\/ODM3OTcwMTc3LmJiODkyOTBlODg5MDM3MmM0MjViMzUwMTliMjc5Njc0?h=1000",
        * "gamename":"Big Bad Wolf Megaways (qs)",
        * "urldesc":"Player history Quickspin (Big Bad Wolf Megaways (qs))"
      * }
    * ]
    * }
    */

  return (
    <div>
      <div role="button" tabIndex="0" className="flex py-4 border-t-2 cursor-pointer" onKeyPress={noop} onClick={ev => {
          if (ev.target.tagName === 'BUTTON') {
            return;
          }

          setShowDetails(!showDetails);
        }}>
        <div className="w-1/5 flex-none text-center">
          <span className="text-lg">{format(timeStamp, 'do MMM yyyy\', \'pp')}</span>
        </div>
        <div className="w-2/5 flex-none text-left px-3">
          <span className="text-xl">{gamename}</span>
        </div>
        <div className="w-1/5 flex-none text-center px-3">
          <span className="text-xl">{system}</span>
        </div>
        <div className="w-1/5 flex-none text-center">
          <a href={apiRoot + url} className="button secondary">Details</a>
        </div>
      </div>
      {showDetails && (
        <div className="flex items-center my-4 pt-4 border-t border-gray-700">
          <div className="w-1/3 flex-none text-center px-2">
            <span className="text-xl">Credit: {formatCurrency(credit, currencyCode)}</span>
          </div>
          <div className="w-1/3 flex-none text-center px-2">
            <span className="text-xl">Debit: {formatCurrency(debit, currencyCode)}</span>
          </div>
          <div className="w-1/3 flex-none text-center px-2">
            <span className="text-xl">Balance: {formatCurrency(balance, currencyCode)}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default function GameLogs() {
  const [ to, setTo ] = useState(new Date());
  const [ from, setFrom ] = useState(subMonths(to, 1));
  const activeDates = useRef();
  const { apiUserFetch } = useApiFetch();
  const { id: playerId, currencycode } = usePlayer();
  const [ gameLog, setGameLog ] = useState(null);
  const [ error, setError ] = useState();
  const [ isReset, reset ] = useState(true);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!isReset) {
      return;
    }

    const dates = { to, from };
    activeDates.current = dates;

    if (from > to) {
      setError('From date must be before To date.');

      return;
    }
    setError(undefined);
    setGameLog(undefined);

    reset(false);

    apiUserFetch('player/gamesHistory', {
      data: {
        player_id: playerId,
        offset: 0,
        limit: 999999,
        from: format(from, 'yyyy-MM-dd') + ' 00:00:00',
        to: format(to, 'yyyy-MM-dd') + ' 23:59:59'
      },
      method: 'post'
    })
      .then(response => {
        if (!isMounted.current || dates !== activeDates.current) {
          return;
        }

        if (!response || !response.info || !response.info.success) {
          throw new Error();
        }

        let filteredLogs = [];

        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          filteredLogs = response.data;

          filteredLogs = filteredLogs.map(log => {
            log.timeStamp = new Date(log.time_stamp);

            return log;
          });
        }

        setGameLog(filteredLogs);
      })
      .catch(() => {
        if (isMounted.current) {
          setError('Error while loading game logs for period...');
        }
      });
  }, [ to, from, isReset, apiUserFetch, playerId, isMounted ]);

  return (
    <div className="paper my-3">
      <h2 className="text-center">Game logs</h2>
      <div className="sm:flex">
        <div className="sm:w-1/2 p-2">
          <DateField
            name="fromDate"
            label="From"
            onChange={value => {
              reset(true);
              setFrom(parseISO(value));
            }}
            value={format(from, 'yyyy-MM-dd')}
          />
        </div>
        <div className="sm:w-1/2 p-2">
          <DateField
            name="toDate"
            label="To"
            onChange={value => {
              reset(true);
              setTo(parseISO(value));
            }}
            value={format(to, 'yyyy-MM-dd')}
          />
        </div>
      </div>
      <div className="p-2">
        {gameLog === undefined && <p className="text-center text-2xl">Loading...</p>}
        {error && <p className="text-center text-2xl text-red-600">{error}</p>}
        {Array.isArray(gameLog) && gameLog.length === 0 && (
          <p className="text-center text-2xl">No logs found...</p>
        )}
        {Array.isArray(gameLog) && gameLog.length > 0 && (
          <div>
            <div className="flex">
              <div className="w-1/5 flex-none text-center">
                <span className="text-2xl truncate">Time</span>
              </div>
              <div className="w-2/5 flex-none text-left pl-4">
                <span className="text-2xl truncate">Game</span>
              </div>
              <div className="w-1/5 flex-none text-center">
                <span className="text-2xl truncate">Provider</span>
              </div>
              <div className="w-1/5 flex-none text-center">
              </div>
            </div>
            {gameLog.map(log => (
              <GameLogItem
                key={log.id}
                log={log}
                currencyCode={currencycode}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}