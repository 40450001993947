import React, { useState, useCallback } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import TextField from '../form/textField';
import { useApiFetch } from '../../hooks';


export default function Promotions() {
  const [ code, setCode ] = useState('');
  const [ error, setError ] = useState(false);
  const [ msg, setMsg ] = useState(undefined);
  const { t } = useTranslation();
  const { apiUserFetch } = useApiFetch();

  const activatePromo = useCallback(() => {
    if (error === undefined) {
      return;
    }

    setMsg(undefined);

    if (!code) {
      setError(t('auth:promotions.codeEmptyError'));

      return;
    }

    setError(undefined);

    apiUserFetch(
      'player/bonus/activate',
      {
        data: { promotionCode: code, fromCashier: false },
        method: 'post'
      }
    )
      .then(response => {
        const { data, info } = response;

        if (!info.success || !data.message) {
          let error = t('auth:promotions.unknownError');

          if (data.message) {
            error = data.message;
          }

          setError(error);

          return;
        }

        setError(false);
        setMsg(data.message);
      })
      .catch(() => {
        setError(t('auth:promotions.unknownError'));
      });
  }, [ error, code, t, apiUserFetch ]);

  return (
    <div className="my-8">
      <p className="text-center text-xl sm:text-2xl">{t('auth:promotions.info')}</p>
      <div className="flex items-start justify-center">
        <TextField
          name="code"
          error={error}
          placeholder={t('auth:promotions.promoCode')}
          onChange={ev => setCode(ev.target.value)}
          value={code}
          onKeyPress={ev => {
            if (ev.key === 'Enter') {
              activatePromo();
            }
          }}
        />
        <div className="px-4 pt-1">
          <button
            disabled={error === undefined}
            type="submit"
            className="button primary"
            onClick={activatePromo}
          >
            {t('auth:promotions.promoActivate')}
          </button>
        </div>
      </div>
      {msg && <p className="text-center text-xl sm:text-2xl">{msg}</p>}
    </div>
  );
}