import React, { useCallback } from 'react';

import { useApiYup } from '../hooks';
import Yupmik from './yupmik';


export default function ApiForm({
  fields, onSubmit, submitting, formErrors, submitText
}) {
  const { validationSchema, postProccessing } = useApiYup(fields);

  const handleSubmit = useCallback(data => {
    onSubmit(postProccessing(data));
  }, [ postProccessing, onSubmit ]);

  return (
    <Yupmik
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      submitting={submitting}
      errors={formErrors}
      submitText={submitText}
    />
  );
}