import React from 'react';
import { graphql } from 'gatsby';
import { Router } from '@reach/router';

import Seo from '../components/seo';
import StandardPage from './standardPage';
import ProfileComp from '../components/profile';


function Profile(props) {
  const { matchPath } = props.pageContext;
  return (
    <StandardPage {...props}>
      <Seo
        title="Profile"
        description=""
      />
      <div className="centeredContentWrapper">
        <Router basepath={matchPath.substr(0, matchPath.length - 2)}>
          <ProfileComp path="/" />
          <ProfileComp path="/:section" />
        </Router>
      </div>
    </StandardPage>
  );
}

export default Profile;

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $traceSVGColor: String!) {
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
