import React, { useState, useCallback, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import * as Yup from 'yup';
import useIsMounted from 'ismounted';

import Seo from '../components/seo';
import StandardPage from './standardPage';
import Yupmik from '../components/yupmik';
import { useApiFetch, usePathParams, useRedirectUser } from '../hooks';


const emailSchema = Yup.object().shape({
  email: Yup.string()
    .label('Email')
    .email()
    .default('')
    .required()
    .meta({
      cols: 2
    })
});

const EmailForm = () => {
  const { apiUserFetch } = useApiFetch();
  const isMounted = useIsMounted();
  const [ showComplete, setShowComplete ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);
  const [ formErrors, setFormErrors ] = useState();

  const handleSubmit = useCallback(data => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    setFormErrors(undefined);

    apiUserFetch('player/forgottenpass', {
      method: 'POST',
      data
    })
      .then(response => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false, response);

        if (!response || !response.info) {
          setFormErrors({ '__form__': 'We\'re having trouble with password recovery at the moment. Please try again in a moment.' });

          return;
        }

        if (response.info.resultCode === 'player_not_found') {
          setFormErrors({ '__form__': 'No player with the specified e-mail address exists.' });

          return;
        }

        setShowComplete(true);
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setFormErrors({ '__form__': 'We\'re having trouble with password recovery at the moment. Please try again in a moment.' });
      });
  }, [ submitting, isMounted, apiUserFetch ]);

  return (
    <div className="centeredContentWrapper sm:max-w-6xl">
      <h1 className="text-center">Password recovery</h1>
      {showComplete && (
        <div>
          <h2 className="text-center">Email sent</h2>
          <p className="text-center my-10 text-xl sm:text:2xl">Please check your email inbox for further instructions.</p>
          <div className="flex justify-evenly mb-12">
            <Link className="button secondary" to="/">Home</Link>
            <Link className="button primary" to="/login/">Login</Link>
          </div>
        </div>
      )}
      {!showComplete && (
        <>
          <p className="text-center text-xl sm:text:2xl">Please specify the email address for your account so we can send you the password recovery information.</p>
          <Yupmik
            validationSchema={emailSchema}
            onSubmit={handleSubmit}
            submitting={submitting}
            errors={formErrors}
          />
        </>
      )}
    </div>
  );
};

const passwordsSchema = Yup.object().shape({
  password: Yup.string()
    .label('New password')
    .min(8)
    .matches(
      /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      {
        message: 'Pleaase choose password with at least one capital letter, one number and at least 8 characters long.',
        excludeEmptyString: true
      })
    .required()
    .meta({
      password: true,
      cols: 2
    }),
  password_confirm: Yup.string()
    .label('Confirm new password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required()
    .meta({
      password: true,
      cols: 2
    }),
});

const RecoveryToken = ({ hash }) => {
  const { apiUserFetch } = useApiFetch();
  const isMounted = useIsMounted();
  const [ showComplete, setShowComplete ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);
  const [ formErrors, setFormErrors ] = useState();

  const handleSubmit = useCallback(data => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    setFormErrors(undefined);

    data.token = hash;

    apiUserFetch('player/resetPassword', {
      method: 'POST',
      data
    })
      .then(response => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);

        if (!response || !response.info) {
          setFormErrors({ '__form__': 'We\'re having trouble with password recovery at the moment. Please try again in a moment.' });

          return;
        }

        if (response.info.resultCode === 'invalid_token') {
          setFormErrors({ '__form__': 'The specified token is expired or doesn\'t exist.' });

          return;
        }

        if (response.info.resultCode === 'success') {
          setShowComplete(true);
        }
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setFormErrors({ '__form__': 'We\'re having trouble with password recovery at the moment. Please try again in a moment.' });
      });
  }, [ submitting, isMounted, apiUserFetch, hash ]);

  return (
    <div className="centeredContentWrapper sm:max-w-6xl">
      <h1 className="text-center">Set new password</h1>
      {showComplete && (
        <div>
          <p className="text-center my-10 text-2xl sm:text:4xl text-green-700">New password set successfuly!</p>
          <div className="flex justify-evenly mb-12">
            <Link className="button secondary" to="/">Home</Link>
            <Link className="button primary" to="/login/">Login</Link>
          </div>
        </div>
      )}
      {!showComplete && (
        <>
          {submitting && <p className="text-center my-10 text-2xl sm:text:4xl">Setting new password...</p>}
          <Yupmik
            validationSchema={passwordsSchema}
            onSubmit={handleSubmit}
            submitting={submitting}
            errors={formErrors}
          />
        </>
      )}
    </div>
  );
};

const PasswordRecovery = props => {
  const { contentfulPage, alternateLanguagePages } = props.data;
  const { metaTitle, metaDescription } = contentfulPage;

  useRedirectUser('/');

  const [ hash ] = usePathParams(props.location.pathname, 'recover/player');
  const [ show, setShow ] = useState(false);
  useEffect(() => {
    setShow(true);
  }, [ hash ]);

  return (
    <StandardPage topPageData={false} {...props}>
      <Seo title={metaTitle} description={metaDescription} alternateLanguagePages={alternateLanguagePages} />
      {show && hash && <RecoveryToken hash={hash} />}
      {show && !hash && (
        <>
          <EmailForm />
        </>
      )}
    </StandardPage>
  );
};

export default PasswordRecovery;

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $traceSVGColor: String!, $key: String!, $language: String!) {
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contentfulPage(language: {code: {eq: $language}}, key: {eq: $key}) {
      title
      metaTitle
      metaDescription
    }
    alternateLanguagePages: allContentfulPage(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
  }
`;
