import React, { useCallback } from 'react';
import cx from 'clsx';
import { noop } from '../util';


function TabItem({ tab, tabDefinitions, active, onClick }) {
  let name = tab;

  if (tabDefinitions.hasOwnProperty(tab)) {
    name = tabDefinitions[tab];
  }

  return (
    <div
      className={cx({
        'block': true,
        'flex-grow': true,
        'm-1': true,
        'py-2': true,
        'px-4': true,
        'text-white': !active,
        'text-xl': true,
        'sm:text-2xl': true,
        'whitespace-no-wrap': true,
        'text-center': true,
        'text-accent1': active,
        'transition duration-150 ease-in-out': true,
        'cursor-pointer': true,
        'outline-none': true
      })}
      onClick={onClick}
      onKeyUp={noop}
      role="button"
      tabIndex="0"
    >
      {name}
    </div>
  );
}

function Tabs({ tabDefinitions, availableTabs, selected, onChange }) {
  const getClickHandler = useCallback(tab => () => {
    if (onChange) {
      onChange(tab);
    }
  }, [ onChange ]);

  if (!availableTabs) {
    availableTabs = Object.keys(tabDefinitions);
  }

  return (
    <div className="flex flex-wrap justify-center list-none mx-0 my-2 shadow-lg">
      {availableTabs.map(tabKey => (
        <TabItem
          key={tabKey}
          tab={tabKey}
          tabDefinitions={tabDefinitions}
          active={tabKey === selected}
          onClick={getClickHandler(tabKey)}
        />
      ))}
    </div>
  );
}

export default Tabs;
