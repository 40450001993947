import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { format } from 'date-fns';

import { usePendingWithdrawals, useApiFetch, useTransactions, usePlayer } from '../hooks';
import { MoneySvg } from './svg';
import { noop, formatCurrency } from '../util';
import { fetchPlayerTransactions } from '../store/playerTransactions/actions';
import { fetchPendingWithdrawals } from '../store/pendingWithdrawals/actions';
import { fetchPlayerBalance } from '../store/playerBalance/actions';


function PendingWithdrawals() {
  const dispatch = useDispatch();
  const player = usePlayer();
  const { transactionIds: pwTransactionIds } = usePendingWithdrawals();
  const playerTransactions = useTransactions();
  const [ show, setShow ] = useState(false);
  const [ cancelResponse, setCancelResponse ] = useState(undefined);
  const { apiUserFetch } = useApiFetch();
  const pwTest = "Pending withdrawals";
  const handleCancelResponse = resp => {
    const { data, info } = resp;

    if (info.success && data.messages) {
      setCancelResponse(data.messages);
    }
  };

  useEffect(() => {
    if (!Array.isArray(pwTransactionIds) || pwTransactionIds.length === 0) {
      return;
    }

    dispatch(fetchPlayerTransactions(pwTransactionIds));
  }, [ pwTransactionIds, player.id, dispatch ]);

  if (!Array.isArray(pwTransactionIds) || pwTransactionIds.length === 0) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={show}
        onRequestClose={() => setShow(false)}
        contentLabel={pwTest}
        className="p-5 bg-trueGray-200 rounded-md text-trueGray-800 text-center"
        overlayClassName="flex justify-center items-center bg-black bg-transparent fixed inset-0 z-50 bg-opacity-50"
      >
        <p className="text-xl">{pwTest}</p>
        {
          cancelResponse === undefined &&
          <>
            <p className="pb-5">{
              pwTransactionIds.length === 1
                ? 'You have one pending withdrawal.'
                : `You have a total of ${pwTransactionIds.length} pending withdrawals.`
            }</p>
            {Array.isArray(playerTransactions) && playerTransactions.length > 0 && (
              <>
                {playerTransactions.map(({ amount, requestDate, id, isPW }) => {
                  if (!isPW) {
                    return null;
                  }

                  return (
                    <div key={id} className="flex items-center justify-between">
                      <p className="text-lg">
                        <span className="text-xl truncate">{formatCurrency(amount, player.currencycode)}</span>
                        on
                        <span className="text-xl truncate">{format(requestDate, 'do MMM yyyy\', \'pp')}</span>
                      </p>
                      <button className="button secondary" onClick={() => {
                        apiUserFetch('player/transactions/pendingWithdrawals/cancel', {
                          method: 'post',
                          data: {
                            trans_ids: [ id ]
                          }
                        })
                          .then(() => {
                            dispatch(fetchPendingWithdrawals());
                            dispatch(fetchPlayerBalance());
                          })
                          .catch(err => handleCancelResponse(err.response));
                      }}>Cancel</button>
                    </div>
                  );
                })}
              </>
            )}
            <button className="button secondary" onClick={() => {
              setCancelResponse(null);

              apiUserFetch('player/transactions/pendingWithdrawals/cancel', {
                method: 'post',
                data: {
                  trans_ids: pwTransactionIds
                }
              })
                .then(handleCancelResponse)
                .catch(err => handleCancelResponse(err.response));
            }}>Cancel all</button>
          </>
        }
        {
          cancelResponse === null &&
          <p>Canceling...</p>
        }
        {
          cancelResponse &&
          <p>{cancelResponse}</p>
        }
      </Modal>
      <span
        aria-label={pwTest}
        role="button"
        title={pwTest}
        className="cursor-pointer fixed left-4 bottom-4 xl:bottom-4 m-0 rounded-full bg-accent1 p-2 z-10"
        onClick={() => {
          setShow(true);
          setCancelResponse(undefined);
        }}
        onKeyPress={noop}
        tabIndex="0"
      >
        <MoneySvg hColor="text-white" className="w-10 h-10 xl:w-12 xl:h-12" />
      </span>
    </>
  );
}

export default PendingWithdrawals;