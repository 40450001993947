import { SET_GOFT, SET_PAP } from './types';


export const setGoft = goft => ({
  type: SET_GOFT,
  goft
});

export const setPap = ({ aAid, aBid }) => ({
  type: SET_PAP,
  aAid,
  aBid
});