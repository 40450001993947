function parse(path, basePath) {
  return path.substr(basePath.length + 2).split('/').filter(p => !!p);
}

export default function usePathParams(path, basePath) {
  if (path.indexOf(`/${basePath}/`) === 0) {
    return parse(path, basePath);
  }

  basePath = encodeURI(basePath);

  if (path.indexOf(`/${basePath}/`) === 0) {
    return parse(path, basePath);
  }

  return [];
}