import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { fetchMethodsIfNeeded } from '../../store/cashierMethods/actions';
import { fetchBonusesIfNeeded } from '../../store/bonuses/actions';
import ChooseBonus from './chooseBonus';
import ChooseType from './chooseType';
import Method from './method';
import MethodsList from './methodsList';
import MessageBlock from '../messageBlock';
import { usePlayer } from '../../hooks';


function methodTypesSeletor(s) {
  return {
    deposit: s.cashierMethods.deposit,
    withdrawal: s.cashierMethods.withdrawal
  };
}

function bonusesSeletor(s) {
  return s.bonuses.list;
}

function Cashier({ methodType, methodCode }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methodTypes = useSelector(methodTypesSeletor, shallowEqual);
  const [ bonusMessage, setBonusMessage ] = useState();

  const bonuses = useSelector(bonusesSeletor);

  useEffect(() => {
    dispatch(fetchMethodsIfNeeded());
    dispatch(fetchBonusesIfNeeded());
  }, [ dispatch ]);

  const { city, phone } = usePlayer();

  if (!city) {
    return (
      <MessageBlock error>{t('cashier:profileCityError')}</MessageBlock>
    );
  }

  if (!phone) {
    return (
      <MessageBlock error>{t('cashier:profilePhoneError')}</MessageBlock>
    );
  }

  if (!methodTypes.deposit || !bonuses) {
    return (
      <MessageBlock>{t('cashier:loading')}</MessageBlock>
    );
  }

  if (methodType && ![ 'deposit', 'withdrawal' ].includes(methodType)) {
    return (
      <MessageBlock>{t('cashier:methodTypeError')}</MessageBlock>
    );
  }

  const methods = methodType ? methodTypes[methodType] : [];
  const method = methodCode ? methods.find(m => m.code === methodCode) : undefined;

  if (methodCode) {
    if (!method) {
      return (
        <MessageBlock>{t('cashier:providerError')}</MessageBlock>
      );
    }

    if (methodType === 'deposit' && !bonusMessage) {
      return (
        <ChooseBonus bonuses={bonuses} method={method} onSubmit={setBonusMessage} />
      );
    }

    return (
      <Method methodType={methodType} method={method} bonusMessage={bonusMessage} />
    );
  }

  if (methodType) {
    return (
      <MethodsList
        methodType={methodType}
        methods={methods}
      />
    );
  }

  return (
    <ChooseType methodTypes={methodTypes} />
  );
}

export default function CashierLayout(props) {
  const { t } = useTranslation();

  return (
    <>
      <Cashier {...props} />
      <p className="mt-4 mb-0 text-center text-xs text-gray-700">
        {t('cashier:footerMessage')}
      </p>
    </>
  );
};