const { apiFetch } = require('./apiFetch');
const { openCashier, closeCashier } = require('./cashier');
const { currencyDefinitions, currencies, currencyOptions, formatAmount, formatCurrency } = require('./currencies');
const { countryOptions, countries } = require('./countries');
const { gameCategories, availableGameCategories } = require('./gameCategories');
const { gameProviders, allProviderKeys, defaultProviderKeys, gameProviderNames, buildProviderHelpers } = require('./gameProviders');
const { generateLanguageUrl } = require('./generateLanguageUrl');
const { isBrowser } = require('./isBrowser');
const { isMobileBrowser } = require('./isMobileBrowser');
const { noop } = require('./noop');
const { paginateGames } = require('./paginateGames');
const { urlFromSlug } = require('./urlFromSlug');

module.exports = {
  apiFetch,
  availableGameCategories,
  openCashier,
  closeCashier,
  currencyDefinitions,
  currencies,
  currencyOptions,
  countryOptions,
  countries,
  formatAmount,
  formatCurrency,
  gameCategories,
  gameProviders,
  allProviderKeys,
  defaultProviderKeys,
  gameProviderNames,
  //gameProviderOptions,
  buildProviderHelpers,
  generateLanguageUrl,
  isBrowser,
  isMobileBrowser,
  noop,
  paginateGames,
  urlFromSlug
};