import { MENU_SHOW, MENU_HIDE } from './types';


export default function ui(
  state = {
    showMenu: false
  },
  action
) {
  const { type } = action;

  switch (type) {
    case MENU_SHOW:
      return { ...state, showMenu: true };

    case MENU_HIDE:
      return { ...state, showMenu: false };

    default:
      return state;
  }
};
