import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useActiveBonus, useCurrencyCode, usePlayerBalance } from '../../hooks';
import { formatCurrency } from '../../util';
import { ChevronRightSvg } from '../svg';
import { fetchPlayerBonus } from '../../store/playerBonus/actions';


function BonusPanel({
  bonus: {
    bonusMeta, isActive, pointsNeeded, contributionTotal,
    amount, depositAmount, issuedOn, finishedOn, validUntil,
    playerMessage
  },
  currencyCode,
  t,
  children
}) {
  const [ isOpen, setOpen ] = useState(false);

  return (
    <div className="paper">
      <div className="flex items-center px-4">
        <div className="flex-1 sm:flex sm:items-center">
          <p className="text-2xl sm:text-3xl m-0 font-bold w-1/2 truncate">{bonusMeta.name}</p>
          <p className="flex-1 text-xl sm:text-2xl m-0 truncate">{t('auth:bonuses.bonusCodeLabel')}: {bonusMeta.code ? <span className="font-bold">{bonusMeta.code}</span> : <span className="italic">{t('auth:bonuses.noCodeLabel')}</span>}</p>
        </div>
        {!isActive && (
          <button className="p-4" onClick={() => setOpen(!isOpen)}>
            <ChevronRightSvg sm r90={!isOpen} rn90={isOpen} />
          </button>
        )}
      </div>
      {(isOpen || isActive) && (
        <div className="p-4">
          <div className="border-t-2 border-accent p-4 lg:flex lg:items-start">
            <div className="lg:w-1/2">
              {children}
              {playerMessage && (
                <p className="text-center text-lg sm:text-xl max-w-3xl mx-auto">{playerMessage}</p>
              )}
            </div>
            <div className="lg:w-1/2 flex items-start flex-wrap">
              <div className="w-1/2 text-right text-xl sm:text-2xl pr-2">{t('auth:bonuses.startTimeLabel')}:</div>
              <div className="w-1/2 text-xl sm:text-2xl font-bold">{issuedOn}</div>
              {
                isActive
                  ?
                    <>
                      <div className="w-1/2 text-right text-xl sm:text-2xl pr-2">{t('auth:bonuses.expirationTimeLabel')}:</div>
                      <div className="w-1/2 text-xl sm:text-2xl font-bold">{validUntil}</div>
                    </>
                  :
                    <>
                      <div className="w-1/2 text-right text-xl sm:text-2xl pr-2">{t('auth:bonuses.endTimeLabel')}:</div>
                      <div className="w-1/2 text-xl sm:text-2xl font-bold">{finishedOn}</div>
                    </>
              }
              <div className="w-1/2 text-right text-xl sm:text-2xl pr-2">{t('auth:bonuses.pointsNeededLabel')}:</div>
              <div className="w-1/2 text-xl sm:text-2xl font-bold">{pointsNeeded}</div>
              <div className="w-1/2 text-right text-xl sm:text-2xl pr-2">{t('auth:bonuses.pointsGainedLabel')}:</div>
              <div className="w-1/2 text-xl sm:text-2xl font-bold">{contributionTotal}</div>
              <div className="w-1/2 text-right text-xl sm:text-2xl pr-2">{t('auth:bonuses.depositAmountLabel')}:</div>
              <div className="w-1/2 text-xl sm:text-2xl font-bold">{formatCurrency(depositAmount, currencyCode)}</div>
              <div className="w-1/2 text-right text-xl sm:text-2xl pr-2">{t('auth:bonuses.bonusAmountLabel')}:</div>
              <div className="w-1/2 text-xl sm:text-2xl font-bold">{formatCurrency(amount, currencyCode)}</div>
              <div className="w-1/2 text-right text-xl sm:text-2xl pr-2">{t('auth:bonuses.wageringLabel')}:</div>
              <div className="w-1/2 text-xl sm:text-2xl font-bold">X {bonusMeta.wageringRequirement}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function bonusHistorySelector(s) {
  return s.playerBonus.history;
}

export default function Bonuses() {
  const { t } = useTranslation();
  const activeBonus = useActiveBonus();
  const bonusHistory = useSelector(bonusHistorySelector);
  const { loadingBalance, bonus } = usePlayerBalance();
  const currencyCode = useCurrencyCode();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPlayerBonus());
  }, [ dispatch ]);

  const bonusBalanceText = useMemo(() => {
    if (loadingBalance) {
      return t('auth:bonuses.loading');
    }

    return formatCurrency(bonus, currencyCode);
  }, [ loadingBalance, bonus, currencyCode, t ]);

  return (
    <div className="my-8">
      {activeBonus === null && <p className="text-center text-xl sm:text-2xl m-0">{t('auth:bonuses.noActiveBonus')}</p>}
      {activeBonus === undefined && <p className="text-center text-xl sm:text-2xl m-0">{t('auth:bonuses.loadingBonuses')}</p>}
      {activeBonus && (
        <>
          <h3 className="text-center">{t('auth:bonuses.activeBonusLabel')}</h3>
          <BonusPanel bonus={activeBonus} currencyCode={currencyCode} t={t}>
            <p className="text-center text-xl sm:text-2xl">{t('auth:bonuses.bonusBalanceLabel')}: <strong>{bonusBalanceText}</strong></p>
          </BonusPanel>
        </>
      )}
      {Array.isArray(bonusHistory) && bonusHistory.length > 0 && (
        <>
          <h3 className="text-center mt-4">{t('auth:bonuses.bonusHistoryLabel')}</h3>
          {bonusHistory.map(bonus => <BonusPanel key={bonus.id} bonus={bonus} currencyCode={currencyCode} t={t} />)}
        </>
      )}
    </div>
  );
}