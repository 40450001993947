import React, { useRef, useEffect, useState, useCallback, useContext } from 'react';
import useIsMounted from 'ismounted';
import { Link, useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby';

import { useApiFetch, useIsPlayer } from '../../hooks';
import { openCashier } from '../../util';


function GameFrame({ game, ratio, siteTitle, loadPlayUrls, baseDomain = '', requestOptions = {}, skipResize, noTitle }) {
  const { t, i18n: { language } } = useTranslation();
  const wrapperEl = useRef(null);
  const [ responseScripts, setResponseScripts ] = useState();
  const [ playUrl, setPlayUrl ] = useState(null);
  const [ playForFunUrl, setPlayForFunUrl ] = useState(null);
  const [ wrapperStyle, setWrapperStyle ] = useState();
  const [ gameUrl, setGameUrl ] = useState(null);
  const [ showDetails, setShowDetails ] = useState(false);
  const { apiUserFetch } = useApiFetch();
  const isMounted = useIsMounted();
  const isPlayer = useIsPlayer();
  const { siteUrl } = useContext(I18nextContext);
  const [ playStarted, setPlayStarted ] = useState(false);

  const handleResize = useCallback(
    () => {
      if (skipResize) {
        return;
      }

      setWrapperStyle({
        height: wrapperEl.current.clientWidth / ratio,
      });
    },
    [ ratio, skipResize ]
  );

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ handleResize ]);

  const {
    apiId, title, description, provider, freeRoundsSupported,
    images, supportsPlayForFun, isMobile
  } = game;

  useEffect(() => {
    if (!loadPlayUrls && playStarted) {
      return;
    }

    const handleEmbedResponse = res => {
      if (res.data.embed_code && res.data.embed_code.includes('SportFrame.')) {
        let innerHTML = res.data.embed_code;
        innerHTML = innerHTML.substring(innerHTML.indexOf('<script type="text/javascript">') + 31);
        innerHTML = `
          function run() {
            if (!window.SportFrame) {
              setTimeout(run, 500);
              return;
            }

            (function () {
              ${innerHTML.substring(0, innerHTML.indexOf('</script>'))}
            })();
          }

          run();
        `;

        setResponseScripts([
          {
            src: `https://sport.betcobog.com/js/Partner/IntegrationLoader.js`
          },
          {
            src: `https://sport.betcobog.com/js/partner/bootstrapper.min.js?gl=0.11&v=3.0`
          },
          {
            innerHTML
          }
        ]);

        return true;
      }

      return false;
    };

    if (!isPlayer && supportsPlayForFun && playForFunUrl === null) {
      setPlayStarted(true);
      setPlayForFunUrl(undefined);
      setResponseScripts(undefined);

      apiUserFetch(
        `player/game/${apiId}`,
        {
          data: {
            ...requestOptions,
            homeurl: `${siteUrl}/`,
            cashierurl: `${siteUrl}/cashier`,
            play_for_fun: true
          }
        }
      )
        .then(response => {
          if (isMounted.current) {
            if (handleEmbedResponse(response)) {
              return;
            }

            if (response && response.info && response.info.success) {
              setPlayForFunUrl(response.data.url);
              setGameUrl(response.data.url);
            }
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setPlayForFunUrl(null);
          }
        });
    }

    if (isPlayer && playUrl === null) {
      setPlayStarted(true);
      setPlayUrl(undefined);
      setResponseScripts(undefined);

      apiUserFetch(
        `player/game/${apiId}`,
        {
          data: {
            ...requestOptions,
            homeurl: `${siteUrl}/`,
            cashierurl: `${siteUrl}/cashier`,
            play_for_fun: false
          }
        }
      )
        .then(response => {
          if (isMounted.current) {
            if (handleEmbedResponse(response)) {
              return;
            }

            if (response && response.info && response.info.success) {
              setPlayUrl(response.data.url);
              setGameUrl(response.data.url);
            }
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setPlayUrl(null);
          }
        });
    }
  }, [
    apiId, apiUserFetch, playForFunUrl, playUrl, requestOptions,
    isMounted, isPlayer, loadPlayUrls, supportsPlayForFun, siteUrl,
    baseDomain, playStarted
  ]);

  return (
    <>
      <div className="md:flex md:items-center bg-main-bg px-4 py-2 sm:px-6 sm:py-3">
        {noTitle && <p className="h1 sm:flex-1 m-0 sm:mr-4 text-3xl sm:text-5xl truncate">{title}</p>}
        {!noTitle && <h1 className="sm:flex-1 m-0 sm:mr-4 text-3xl sm:text-5xl truncate" style={{ lineHeight: '1.5' }}>{title}</h1>}
        <div className="flex justify-center items-center flex-wrap sm:flex-none">
          <button className="flex-none button secondary mr-10" onClick={() => navigate(-1)}>&larr; Back</button>
          {!isPlayer && !supportsPlayForFun && (
            <Link to="https://www.luckystar.io/blog/welcome-to-lucky-star?a_aid=66f2ee9966220&a_bid=e7658fe3" className="button primary mr-2 mt-2 sm:mt-0">{t('game.signUpNeeded')}</Link>
          )}
          {!isPlayer && playForFunUrl && isMobile && (
            <a className="flex-none button primary mr-2 mt-2 sm:mt-0" href={playForFunUrl} target="_blank" rel="noopener noreferrer">{t('game.playForFunButton')}</a>
          )}
          <button className="flex-none button secondary mt-2 sm:mt-0" onClick={() => setShowDetails(!showDetails)}>{t('game.details')}</button>
        </div>
      </div>
      {showDetails &&
        <div className="flex items-top bg-main-bg px-8 py-4">
          <div className="flex-1">
            <p className="text-primary m-0">{description.en || <em>{t('game.noDescription')}</em>}</p>
          </div>
          <ul className="list-none ml-4 flex-none lg:flex lg:flex-wrap lg:items-start">
            <li className="sm:mb-2 lg:flex-none lg:w-1/2"><p className="mb-0">{t('game.providerLabel')}: <strong>{provider}</strong></p></li>
          </ul>
        </div>
      }
      <div ref={wrapperEl} className="w-full pb-10" style={wrapperStyle}>
        {
          !isMobile && gameUrl &&
          <iframe
            title={`${title} game window`}
            src={gameUrl}
            allowFullScreen
            className="w-full h-full overflow-hidden relative border-none p-0 m-0"
          />
        }
        {responseScripts && (
          <>
            <div id="sport_div_iframe" />
            <Helmet script={responseScripts} />
          </>
        )}
        {
          !isMobile && !responseScripts && !gameUrl &&
          <img src={images.preview.url} alt={`${title} ${siteTitle} game`} className="block w-full h-full" />
        }
      </div>
    </>
  );
}

GameFrame.defaultProps = {
  ratio: 8 / 5 // a.k.a. 16 / 10 aspect ratio
};

export default GameFrame;
