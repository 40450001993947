import React from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import Seo from '../components/seo';
import StandardPage from './standardPage';
import { useRedirectUser } from '../hooks';


const LoginPage = props => {
  const { t } = useTranslation();
  const { contentfulPage, alternateLanguagePages } = props.data;
  const { title, metaTitle, metaDescription } = contentfulPage;

  useRedirectUser('/');

  return (
    <StandardPage topPageData={false} {...props}>
      <Seo title={metaTitle} description={metaDescription} alternateLanguagePages={alternateLanguagePages} />
      <div className="centeredContentWrapper">
        <h1 className="text-center">{title}</h1>
        <Link
                to="https://www.luckystar.io/blog/welcome-to-lucky-star?a_aid=66f2ee9966220&a_bid=e7658fe3"
                className="uppercase text-2xl 2xl:text-3xl p-2 m-1 font-bold"
                activeClassName="active"
              >
                LuckyStar {t('auth:loginButton')}
              </Link>
        <div className="py-5 flex justify-center">
          <Link className="text-xl sm:text-2xl text-accent hover:text-accent1" to="/recover/player/">{t('auth:passwordRecoveryButton')}</Link>
        </div>
      </div>
    </StandardPage>
  );
};

export default LoginPage;

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $traceSVGColor: String!, $key: String!, $language: String!) {
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contentfulPage(language: {code: {eq: $language}}, key: {eq: $key}) {
      title
      metaTitle
      metaDescription
    }
    alternateLanguagePages: allContentfulPage(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
  }
`;
