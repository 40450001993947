import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { usePlayer } from '../hooks';
import MessageBlock from './messageBlock';


function AccessControl({ children, anon = false, showMessage = true }) {
  const player = usePlayer();
  const { t } = useTranslation();

  if (!player.anonKey) {
    if (!showMessage) {
      return null;
    }

    return (
      <MessageBlock>{t('auth:accessControl.cheking')}</MessageBlock>
    );
  }

  if (!anon && !player.playerKey) {
    if (!showMessage) {
      return null;
    }

    return (
      <MessageBlock>{t('auth:accessControl.loginNeeded')}</MessageBlock>
    );
  }

  return children;
}

export default AccessControl;