import React, { useState, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';


const storageKey = 'cookiesOk';

function CookiesTooltip() {
  const [ hide, setHide ] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!localStorage.getItem(storageKey)) {
      setHide(false);
    }
  }, []);

  if (hide) {
    return null;
  }

  return (
    <div className={
      `fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full bg-white ` +
      `z-50 max-w-xl flex items-center justify-center rounded-t-xl py-2 px-4 `
    }>
      <p className="text- text-gray-700 mb-0 mr-4">{t('cookies.message')}</p>
      <button className="button text-accent1" onClick={() => {
        setHide(true);
        localStorage.setItem(storageKey, true);
      }} aria-label={t('cookies.acceptLabel')}>{t('cookies.acceptText')}</button>
    </div>
  );
}

export default CookiesTooltip;