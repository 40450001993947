import React, { useState, useCallback, useMemo } from 'react';
import { navigate } from 'gatsby';
import useIsMounted from 'ismounted';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import * as Yup from 'yup';

import { currencyDefinitions, currencyOptions, countryOptions, isBrowser } from '../util';
import { useApiFetch } from '../hooks';
import Yupmik from './yupmik';


function SignUp({ onSignUp, goftToken, aAid, aBid }) {
  const { t, i18n: { language } } = useTranslation();
  const [ submitting, setSubmitting ] = useState(false);
  const [ responseErrors, setResponseErrors ] = useState(undefined);
  const { apiUserFetch } = useApiFetch();
  const isMounted = useIsMounted();
  const defaultFormError = { '__form__': t('auth:signUp.defaultError') };

  const handleSubmit = useCallback(data => {
    if (submitting) {
      return;
    }

    setResponseErrors(undefined);
    setSubmitting(true);

    data = {
      ...data,
      nickname: data.username,
      terms: data.terms ? 1 : 0,
      newsletter: data.newsletter ? true : false,
      countrycode: data.countrycode.value,
      languagecode: data.languagecode.value,
      currencycode: data.currencycode.value,
      ref_code: currencyDefinitions[data.currencycode.value].refCode,
      phone: '+' + data.phone,
      mobile: '+' + data.mobile
    };

    delete data.above18;

    if (goftToken) {
      data.goft_token = goftToken;
    }

    if (aAid && aBid) {
      data.a_aid = aAid;
      data.a_bid = aBid;
    }

    apiUserFetch('register/long', {
      method: 'POST',
      data
    })
      .then(response => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);

        if (response && response.info) {
          if (response.info.success) {
            if (onSignUp) {
              onSignUp(response.data);
            }
          } else {
            if (response.info.resultCode === 'invalid_input') {
              const newResponseErrors = {};

              for (const [ , { code: field, validators } ] of response.data.fields.entries()) {
                for (const [ , { key: errorKey } ] of validators.entries()) {
                  switch (field + ':' + errorKey) {
                    case 'username:uniqueUsername':
                      newResponseErrors.username = t('auth:signUp.usernameTakenError');
                      break;

                    case 'email:uniqueEmail':
                      newResponseErrors.email = t('auth:signUp.emailTakenError');
                      break;

                    case 'birthdate:validIsAdult':
                      newResponseErrors.birthdate = t('auth:signUp.mustBeAdultError');
                      break;

                    case 'captcha:validCaptcha':
                      newResponseErrors.captcha = t('auth:signUp.captchaInvalidError');
                      break;

                    default:
                      newResponseErrors[field] = t('auth:signUp.unknownError');
                      break;
                  }
                }
              }

              if (Object.keys(newResponseErrors).length > 0) {
                setResponseErrors(newResponseErrors);
              }
            } else {
              setResponseErrors(defaultFormError);
            }
          }
        }
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);
        setResponseErrors(defaultFormError);
      });
  }, [
    submitting,
    setSubmitting,
    setResponseErrors,
    apiUserFetch,
    onSignUp,
    isMounted,
    goftToken,
    defaultFormError,
    aAid,
    aBid,
    t
  ]);

  const playerSchema = useMemo(() => {
    let termsLabel;
    switch (language) {
      case 'es':
        termsLabel = <>I accept the <Link className="standard" to="/terminos-y-condiciones/">Terms and conditions</Link> and <Link className="standard" to="/política-de-privacidad/">Privacy policy</Link></>;
        break;

      case 'de':
        termsLabel = <>I accept the <Link className="standard" to="/geschaftsbedingungen/">Terms and conditions</Link> and <Link className="standard" to="/datenschutz-bestimmungen/">Privacy policy</Link></>;
        break;

      default:
        termsLabel = <>I accept the <Link className="standard" to="/terms-and-conditions/">Terms and conditions</Link> and <Link className="standard" to="/terms-and-conditions/">Privacy policy</Link></>;
    }

    return Yup.object().shape({
      email: Yup.string()
        .label(t('auth:signUp.emailLabel'))
        .email()
        .default('')
        .required()
        .meta({
          cols: 2
        }),
      username: Yup.string()
        .label(t('auth:signUp.usernameLabel'))
        .min(4)
        .max(20)
        .default('')
        .required()
        .matches(new RegExp(), t('forms:alphanumericOnlyError'))
        .meta({
          cols: 2
        }),
      password: Yup.string()
        .label(t('auth:signUp.passwordLabel'))
        .min(8)
        .required()
        .meta({
          password: true,
          cols: 2
        }),
      password_confirm: Yup.string()
        .label(t('auth:signUp.passwordConfirmLabel'))
        .oneOf([Yup.ref('password'), null], t('auth:signUp.passwordMatch'))
        .required()
        .meta({
          password: true,
          cols: 2
        }),
      fname: Yup.string()
        .label(t('auth:signUp.firstNameLabel'))
        .min(2)
        .default('')
        .matches(new RegExp('^[a-z ,.\'-]+$', 'ui'), t('forms:genericCharError'))
        .required()
        .meta({
          cols: 2
        }),
      lname: Yup.string()
        .label(t('auth:signUp.lastNameLabel'))
        .min(2)
        .default('')
        .matches(new RegExp('^[a-z ,.\'-]+$', 'ui'), t('forms:genericCharError'))
        .required()
        .meta({
          cols: 2
        }),
      birthdate: Yup.date()
        .label(t('auth:signUp.birthdayLabel'))
        .required()
        .meta({
          cols: 2
        }),
      languagecode: Yup.mixed()
        .label(t('auth:signUp.languageLabel'))
        .oneOf([
          {
            label: t('auth:signUp.languageEnLabel'),
            value: 'en_EN'
          },
          {
            label: t('auth:signUp.languageEsLabel'),
            value: 'es_ES'
          },
          {
            label: t('auth:signUp.languageDeLabel'),
            value: 'de_DE'
          }
        ], null)
        .required()
        .meta({
          cols: 2,
          select: true
        }),
      currencycode: Yup.mixed()
        .label(t('auth:signUp.currencyLabel'))
        .oneOf(currencyOptions, null)
        .required()
        .meta({
          cols: 2,
          select: true
        }),
      countrycode: Yup.mixed()
        .label(t('auth:signUp.countryLabel'))
        .oneOf(countryOptions, null)
        .required()
        .meta({
          cols: 2,
          select: true
        }),
      address: Yup.string()
        .label(t('auth:signUp.addressLabel'))
        .default('')
        .required()
        .meta({
          cols: 2
        }),
      city: Yup.string()
        .label(t('auth:signUp.cityLabel'))
        .default('')
        .required()
        .max(50)
        .meta({
          cols: 2
        }),
      zip: Yup.string()
        .label(t('auth:signUp.postalCodeLabel'))
        .default('')
        .required()
        .max(16)
        .meta({
          cols: 2
        }),
      phone: Yup.string()
        .label(t('auth:signUp.phoneLabel'))
        .default('')
        .required()
        .max(24)
        .meta({
          prefix: '+',
          cols: 2
        }),
      mobile: Yup.string()
        .label(t('auth:signUp.mobileLabel'))
        .default('')
        .required()
        .max(24)
        .meta({
          prefix: '+',
          cols: 2
        }),
      captcha: Yup.string()
        .label(t('auth:signUp.captchaLabel'))
        .default('')
        .required()
        .meta({
          captcha: 'captcha?width=200&height=100',
          cols: 2
        }),
      terms: Yup.boolean()
        .label(t('auth:signUp.termsLabel'))
        .default(false)
        .required(t('auth:signUp.termsRequired'))
        .meta({
          label: termsLabel
        }),
      above18: Yup.boolean()
        .label(t('auth:signUp.above18Label'))
        .default(false)
        .required(t('auth:signUp.above18Required')),
      newsletter: Yup.boolean()
        .label(t('auth:signUp.newsletterLabel'))
        .default(false)
    });
  }, [ t, language ]);

  if (isBrowser()) {
    navigate('https://www.luckystar.io/blog/welcome-to-lucky-star?a_aid=66f2ee9966220&a_bid=e7658fe3');
  }

  return (
    <Yupmik
      validationSchema={playerSchema}
      onSubmit={handleSubmit}
      submitting={submitting}
      errors={responseErrors}
      submitText={t('auth:signUp.button')}
    />
  );
};

export default SignUp;