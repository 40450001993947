import React, { useState, useCallback, useEffect } from 'react';
import useIsMounted from 'ismounted';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as Yup from 'yup';

import { useApiFetch, usePlayer } from '../../hooks';
import Yupmik from '../yupmik';
import { formatCurrency } from '../../util';


function DepositLimit({ t }) {
  const { apiUserFetch } = useApiFetch();
  const isMounted = useIsMounted();
  const { id: playerId, currencycode } = usePlayer();

  const [ loading, setLoading ] = useState(false);
  const [ depositLimit, setDepositLimit ] = useState();
  useEffect(() => {
    if (!playerId || loading || depositLimit !== undefined) {
      return;
    }

    setLoading(true);

    apiUserFetch(`player/limits/${playerId}`)
      .then(resp => {
        if (!isMounted.current) {
          return;
        }

        setLoading(false);

        if (!resp || !resp.info || !resp.info.success) {
          throw new Error();
        }

        setDepositLimit(resp.data.dailyDepositLimit);
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setLoading(false);
        setDepositLimit(null);
      });
  }, [ apiUserFetch, isMounted, playerId, loading, depositLimit ]);

  const [ submitting, setSubmitting ] = useState(false);
  const [ formErrors, setFormErrors ] = useState();
  const handleUpdate = useCallback(data => {
    setSubmitting(true);
    setFormErrors(undefined);

    apiUserFetch('player/limits/set/dailyDepositLimit', {
      data,
      method: 'POST'
    })
      .then(resp => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);

        if (!resp || !resp.info || !resp.info.resultCode) {
          throw new Error();
        }

        if (resp.info.resultCode === 'error' && resp.data.message) {
          setFormErrors({
            '__form__': resp.data.message
          });

          return;
        }

        setDepositLimit(undefined);
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);
        setFormErrors({
          '__form__': t('auth:unexpectedError')
        })
      });
  }, [ apiUserFetch, isMounted, t ]);

  const depositLimitSchema = Yup.object().shape({
    newValue: Yup.number()
      .label(t('auth:depositLimit.newDailyLimit'))
      .required()
      .meta({
        cols: 2
      }),
    password: Yup.string()
      .label(t('auth:depositLimit.passwordLabel'))
      .min(8)
      .required()
      .meta({
        password: true,
        cols: 2
      }),
  });

  return (
    <div className="paper mt-3 mb-20">
      <h2 className="text-center">{t('auth:depositLimit.label')}</h2>
      {loading && <p className="text-center text-xl sm:text-2xl">{t('auth:depositLimit.loadingDepositLimit')}</p>}
      {!loading && depositLimit && (
        <>
          {depositLimit && depositLimit.maxDailyDepositLimit_text && (
            <p className="text-xl sm:text-2xl">{depositLimit.maxDailyDepositLimit_text}</p>
          )}
          {depositLimit && depositLimit.currentDailyDepositLimit && (
            <p className="text-xl sm:text-2xl">{t('auth:depositLimit.currentDailyLimit')}: <strong>{formatCurrency(depositLimit.currentDailyDepositLimit, currencycode)}</strong></p>
          )}
          {depositLimit && depositLimit.currentDailyDepositLimitPending && (
            <p className="text-xl sm:text-2xl">{t('auth:depositLimit.pendingDailyLimit')}: <strong>{depositLimit.currentDailyDepositLimitPending_text}</strong></p>
          )}
          <Yupmik
            validationSchema={depositLimitSchema}
            onSubmit={handleUpdate}
            submitting={submitting}
            errors={formErrors}
          />
        </>
      )}
    </div>
  );
}

function FreezeAccount({ t }) {
  const isMounted = useIsMounted();
  const [ submitting, setSubmitting ] = useState(false);
  const [ formErrors, setFormErrors ] = useState();
  const { apiUserFetch } = useApiFetch();
  const handleSubmit = useCallback(data => {
    setSubmitting(true);
    setFormErrors(undefined);

    data.newValue = data.newValue.value;

    apiUserFetch('player/limits/set/freezeAccount', {
      data,
      method: 'POST'
    })
      .then(resp => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);

        if (!resp || !resp.info || !resp.info.resultCode) {
          throw new Error();
        }

        if (resp.info.message === 'restriction_error') {
          setFormErrors({
            '__form__': t('auth:freezeAccount.incorrectPasswordMessage')
          });

          return;
        }

        navigate('/logout/');
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);
        setFormErrors({
          '__form__': t('auth:unexpectedError')
        })
      });
  }, [ apiUserFetch, isMounted, t ]);

  const freezeAcountSchema = Yup.object().shape({
    newValue: Yup.mixed()
      .label(t('auth:freezeAccount.periodLabel'))
      .oneOf([
        {
          value: 1,
          label: t('auth:freezeAccount.dayLabel')
        },
        {
          value: 3,
          label: t('auth:freezeAccount.threeDaysLabel')
        },
        {
          value: 7,
          label: t('auth:freezeAccount.weekLabel')
        },
        {
          value: 31,
          label: t('auth:freezeAccount.monthLabel')
        },
        {
          value: 365,
          label: t('auth:freezeAccount.yearLabel')
        }
      ], null)
      .required()
      .meta({
        cols: 2,
        select: true,
        searchable: false
      }),
    password: Yup.string()
      .label(t('auth:freezeAccount.passwordLabel'))
      .min(8)
      .required()
      .meta({
        cols: 2,
        password: true
      }),
  });

  return (
    <div className="paper mt-3 mb-20">
      <h2 className="text-center">{t('auth:freezeAccount.label')}</h2>
      <p className="text-xl sm:text-2xl">{t('auth:freezeAccount.usageMessage')}</p>
      <Yupmik
        validationSchema={freezeAcountSchema}
        onSubmit={handleSubmit}
        submitting={submitting}
        errors={formErrors}
      />
    </div>
  );
}

function DeactivateAccount({ t }) {
  const isMounted = useIsMounted();
  const [ submitting, setSubmitting ] = useState(false);
  const [ formErrors, setFormErrors ] = useState();
  const { apiUserFetch } = useApiFetch();
  const handleSubmit = useCallback(data => {
    setSubmitting(true);
    setFormErrors(undefined);

    apiUserFetch('player/limits/set/deactivateAccount', {
      data,
      method: 'POST'
    })
      .then(resp => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);

        if (!resp || !resp.info || !resp.info.resultCode) {
          throw new Error();
        }

        if (resp.info.resultCode === 'invalid_input') {
          setFormErrors({
            '__form__': t('auth:deactivateAccount.incorrectPasswordMessage')
          });
          return;
        }

        navigate('/logout/');
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);
        setFormErrors({
          '__form__': t('auth:unexpectedError')
        })
      });
  }, [ apiUserFetch, isMounted, t ]);

  const deactivateAccountSchema = Yup.object().shape({
    password: Yup.string()
      .label(t('auth:deactivateAccount.passwordLabel'))
      .min(8)
      .required()
      .meta({
        cols: 2,
        password: true
      }),
  });

  return (
    <div className="paper mt-3 mb-20">
      <h2 className="text-center">{t('auth:deactivateAccount.label')}</h2>
      <p className="text-xl sm:text-2xl">{t('auth:deactivateAccount.usageMessage')}</p>
      <Yupmik
        validationSchema={deactivateAccountSchema}
        onSubmit={handleSubmit}
        submitting={submitting}
        errors={formErrors}
      />
    </div>
  );
}

export default function Restrictions() {
  const { t } = useTranslation();

  return (
    <>
      <DepositLimit t={t} />
      <FreezeAccount t={t} />
      <DeactivateAccount t={t} />
    </>
  );
}