import React from 'react';
import { graphql, Link } from 'gatsby';

import Seo from '../components/seo';
import ContentfulPageContent from '../components/contentfulPageContent';
import StandardPage from './standardPage';


function LiveCasinoProvider({ slug, name, img }) {
  return (
    <Link
      to={`/game/${slug}/`}
      className="block"
    >
      <img
        src={img.childImageSharp.fluid.src}
        alt={`${name} live casino`}
        className="rounded-t-lg"
      />
      <span className="block bg-accent1 text-xl md:text-2xl sm:py-4 sm:px-6 py-2 px-4 text-white font-bold">
        {name}
      </span>
    </Link>
  );
}

const LiveDealerPage = props => {
  const { contentfulPage, alternateLanguagePages, asiaGaming, evolution, betgames, ezugi } = props.data;

  return (
    <StandardPage topPageData={false} {...props}>
      <Seo
        title={contentfulPage.metaTitle}
        description={contentfulPage.metaDescription}
        alternateLanguagePages={alternateLanguagePages}
      />
      <div className="centeredContentWrapper">
        <h1 className="text-center">{contentfulPage.title}</h1>
        <div className="flex flex-wrap mx-auto mb-12 max-w-4xl">
          <div className="p-2 sm:p-3 md:p-4 w-1/2 micro:w-full">
            <LiveCasinoProvider
              slug="ag-live-casino-mobile"
              name="Asia Gaming"
              img={asiaGaming}
            />
          </div>
          <div className="p-2 sm:p-3 md:p-4 w-1/2 micro:w-full">
            <LiveCasinoProvider
              slug="es-livecasino-mobile"
              name="Evolution"
              img={evolution}
            />
          </div>
          <div className="p-2 sm:p-3 md:p-4 w-1/2 micro:w-full">
            <LiveCasinoProvider
              slug="be-livecasino-mobile"
              name="Betgames"
              img={betgames}
            />
          </div>
          <div className="p-2 sm:p-3 md:p-4 w-1/2 micro:w-full">
            <LiveCasinoProvider
              slug="ez-livecasino-mobile"
              name="Ezugi"
              img={ezugi}
            />
          </div>
        </div>
        {
          contentfulPage.content && contentfulPage.content.raw && (
            <ContentfulPageContent data={contentfulPage} />
          )
        }
      </div>
    </StandardPage>
  );
};

export default LiveDealerPage;

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $traceSVGColor: String!, $contentImgMaxWidth: Int!, $imgQuality: Int!, $cid: String!, $key: String!, $language: String!) {
    asiaGaming: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "live-casino/asia-gaming.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    evolution: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "live-casino/evolution.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    betgames: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "live-casino/betgames.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ezugi: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "live-casino/ezugi.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contentfulPage(buildPage: {eq: false}, contentful_id: {eq: $cid}) {
      title
      summary {
        raw
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            cid: contentful_id
            fixed(
              width: $contentImgMaxWidth,
              quality: $imgQuality
            ) {
              src
            }
            alt: title
            href: description
          }
        }
      }
      hero {
        fluid(
          maxWidth: $headerImageMaxWidth,
          quality: $imgQuality
        ) {
          ...GatsbyContentfulFluid
        }
        alt: title
      }
      metaTitle
      metaDescription
    }
    alternateLanguagePages: allContentfulPage(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
  }
`;
