function urlFromSlug(slug) {
  if (typeof slug === 'string') {
    if (!slug.startsWith('/')) {
      slug = `/${slug}`;
    }

    if (!slug.endsWith('/')) {
      slug = `${slug}/`;
    }
  }

  return slug;
}

exports.urlFromSlug = urlFromSlug;