import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


function ApgSeal() {
  const [ load, setLoad ] = useState(false);

  const { license } = useStaticQuery(
    graphql`
      query {
        license: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "licence.png"}) {
          childImageSharp {
            fluid(
              maxWidth: 125,
              traceSVG: {
                color: "#ae1010"
              }
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (!load) {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://c572703c-7343-4503-a94e-e1ffe96a338f.snippet.antillephone.com/apg-seal.js';

    document.getElementsByTagName('head')[0].appendChild(script);

    return () => {
      script.remove();
    };
  }, [ load ]);

  return (
    <>
      {!load && (
        <div
          className="flex justify-center"
        >
          <button
            className="w-full border-0 outline-none"
            style={{ maxWidth: 128 }}
            onClick={() => setLoad(true)}
          >
            <Img
              fluid={license.childImageSharp.fluid}
              alt="License seal"
            />
          </button>
        </div>
      )}
      <div
        className="flex justify-center mb-6 sm:mb-10"
        id="apg-c572703c-7343-4503-a94e-e1ffe96a338f"
        data-apg-seal-id="c572703c-7343-4503-a94e-e1ffe96a338f"
        data-apg-image-size="128"
        data-apg-image-type="basic-small"
      />
    </>
  );
}

export default ApgSeal;