import { SET_PLAYER_DATA, RESET_PLAYER_DATA } from './types';

const initialState = {
  anonKey: undefined,
  playerKey: undefined,
  username: undefined,
  address: undefined,
  birthdate: undefined,
  countrycode: undefined,
  currencycode: undefined,
  level: undefined,
  email: undefined,
  fname: undefined,
  lname: undefined,
  newsletter: undefined,
  id: undefined,
  city: undefined,
  phone: undefined,
  mobile: undefined
};

export default function player(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_PLAYER_DATA:
      const { data } = action;

      const updatedState = {};

      for (const key of Object.keys(initialState)) {
        if (data.hasOwnProperty(key)) {
          updatedState[key] = data[key];
        }
      }

      return { ...state, ...updatedState };

    case RESET_PLAYER_DATA:
      return { ...initialState };

    default:
      return state;
  }
};
