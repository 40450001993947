import React from 'react';

export default function sanitizeMethods(methods, isWithdrawal) {
  if (!methods) {
    return undefined;
  }

  return methods.map(method => {
    const {
      name, code, text: description, timeStr: timeMessage, isInstant,
      fee: feeMessage, limits, image, processor, form, category, isCrypto
    } = method;

    let userMessageKey;

    if (code === 'interac_eto') {
      userMessageKey = 'cashier:interac_message';
    }

    let bottomMessage;
    let network;

    if (isCrypto) {
      bottomMessage = `cashier:wallet:bottomMessage:${code}`;

      switch (code) {
        case 'alphapo_btc':
          network = 'Bitcoin';
          break;

        case 'alphapo_eth':
          network = 'ERC20';
          break;

        case 'coinpayments_usdt.erc20':
          network = 'ERC20';
          break;

        case 'alphapo_ltc':
          network = 'Litecoin';
          break;

        case 'alphapo_xrp':
          network = 'Ripple';
          break;

        case 'coinpayments_dash':
          network = '';
          break;

        case 'coinpayments_doge':
          network = '';
          break;

        case 'coinpayments_bnb':
          network = 'BEP2';
          break;

        default:
          break;
      }
    }

    let footer;
    if (isWithdrawal && code === 'skrilltrusted') {
      footer = (
        <>
          <p className="mt-8 mb-0 text-center text-xs">
            The crypto exchange will occur along with your withdrawal and be provided by our Partner.<br />
            By proceeding, I agree to the <a href="https://utpay.io/terms-of-use" target="_blank" rel="noreferrer">Partner&#39;s Terms of Use</a> and <a href="https://utpay.io/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>.
          </p>
        </>
      );
    }

    return {
      name: name === 'Creditcard' ? 'Visa' : name,
      code: code === 'creditcard' ? 'visa' : code,
      description,
      timeMessage,
      feeMessage,
      isInstant,
      limits: {
        min: {
          text: limits.minAmountStr,
          amount: limits.minAmountVal
        },
        max: {
          text: limits.maxAmountStr,
          amount: limits.maxAmountVal
        }
      },
      image,
      processor,
      form,
      category,
      userMessageKey,
      bottomMessage,
      footer,
      network
    };
  });
}