import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import { noop } from '../util';


function Menu({ className, itemClassName, linkClassName, items, language, children, onItemClick = noop }) {
  const { t } = useTranslation();
  const { site: { siteMetadata: { env: { defaultLanguage } } } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            env {
              defaultLanguage
            }
          }
        }
      }
    `
  );

  return (
    <ul className={className}>
      {items.map(({ to, key, element = null, partiallyActive = false, language: itemLanguage }) => {
        to = to[language] || to[defaultLanguage];

        return (
          <li key={`${itemLanguage ? itemLanguage : ''}${to}`} className={itemClassName}>
            <Link
              className={linkClassName}
              to={to}
              partiallyActive={!!partiallyActive}
              activeClassName="active"
              language={itemLanguage}
              onClick={onItemClick}
            >
              {element}
              {key && t(`menus.${key}`)}
            </Link>
          </li>
        );
      })}
      {children}
    </ul>
  );
}

export default Menu;
