import React from 'react';


export default function Hamburger({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" viewBox="0 0 52.492 35.932" className={className}>
      <path d="M22,35.932a1,1,0,0,1-1-1V32.939a1,1,0,0,1,1-1H51.492a1,1,0,0,1,1,1v1.993a1,1,0,0,1-1,1ZM1,19.962a1,1,0,0,1-1-1V16.969a1,1,0,0,1,1-1H51.492a1,1,0,0,1,1,1v1.993a1,1,0,0,1-1,1ZM1,3.992a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H30.495a1,1,0,0,1,1,1V2.993a1,1,0,0,1-1,1Z" fill="#FF6057"/>
    </svg>
  );
};
