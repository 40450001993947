import React from 'react';

import ContentfulPageContent from './contentfulPageContent';
import Seo from './seo';


export default function ContentfulPage({ data, alternateLanguagePages, alternateLanguageBases }) {
  return (
    <>
      <Seo
        title={data.metaTitle}
        description={data.metaDescription}
        alternateLanguagePages={alternateLanguagePages}
        alternateLanguageBases={alternateLanguageBases}
      />
      {data.title && (
        <h1 className="text-left">{data.title}</h1>
      )}
      <ContentfulPageContent data={data} />
    </>
  );
};