import { useCallback, useMemo, useState, useEffect } from 'react';

import { useGameProviders } from '.';
import { isBrowser } from '../util';


export default function useGameProvidersFilter(initialProviders = 'all', storeId) {
  const uStoreId = storeId ? `gameProvidersFilter-${storeId}` : undefined;
  const availableProviders = useGameProviders();
  const doStorageCheck = isBrowser() && window.localStorage;

  if (uStoreId && doStorageCheck) {
    const storedProviders = window.localStorage.getItem(uStoreId);

    if (typeof storedProviders === 'string') {
      initialProviders = JSON.parse(window.localStorage.getItem(uStoreId));
    }
  }

  let [ selectedProviders, setSelectedProviders ] = useState(initialProviders);

  const handleProviderChange = useCallback(
    newSelectedProviders => setSelectedProviders(newSelectedProviders),
    []
  );
  const activeProvidersList = useMemo(() => {
    let selected = availableProviders;

    if (selectedProviders !== 'all' && Array.isArray(selectedProviders) && selectedProviders.length !== 0) {
      const filteredProviders = selectedProviders.filter(p => availableProviders.includes(p));

      if (filteredProviders.length !== selectedProviders.length) {
        setSelectedProviders(filteredProviders);
      }

      selected = filteredProviders;
    }

    return selected;
  }, [ selectedProviders, availableProviders, setSelectedProviders ]);

  useEffect(() => {
    if (uStoreId && doStorageCheck) {
      window.localStorage.setItem(uStoreId, JSON.stringify(selectedProviders));
    }
  }, [ uStoreId, selectedProviders, doStorageCheck ]);

  return useMemo(() => {
    return {
      availableProviders,
      activeProvidersList,
      selectedProviders,
      handleProviderChange
    }
  }, [ availableProviders, activeProvidersList, selectedProviders, handleProviderChange ]);
}