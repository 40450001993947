import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import StandardPage from '../standardPage';
import ContentfulRichText from '../../components/contentfulRichText';
import ContentListItem from '../../components/contentListItem';


export default function List({ data }) {
  const { t } = useTranslation();
  const { contentfulPage: { url: baseUrl } } = data;

  return (
    <StandardPage data={data} bottomPageData={true} topPageData={false}>
    </StandardPage>
  );
};

export const pageQuery = graphql`
  query($key: String!, $cid: String!, $language: String!, $headerImageMaxWidth: Int!, $imgQuality: Int!) {
    contentfulPage(contentful_id: {eq: $cid}) {
      title
      content {
        raw
      }
      metaTitle
      metaDescription
      url
      hero {
        fluid(
          maxWidth: $headerImageMaxWidth,
          quality: $imgQuality
        ) {
          ...GatsbyContentfulFluid
        }
        alt: title
      }
    }
    alternateLanguagePages: allContentfulPage(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
  }
`;
