import { SET, START_LOADING, RESET_ALL } from './types';
import { defaultProviderKeys } from '../../util';

const initialState = {
  list: defaultProviderKeys.slice(),
  defaultList: true,
  loading: false
};

export default function gameProviders(
  state = initialState,
  action
) {
  const { type, list } = action;
  let newState = {};

  switch (type) {
    case SET:
      if (list) {
        newState.list = list;
        newState.defaultList = false;
        newState.loading = false;
      }
      break;

    case START_LOADING:
      newState.loading = true;
      break;

    case RESET_ALL:
      newState = initialState;
      break;

    default:
  }

  return { ...state, ...newState };
};
