import React from 'react';

import FieldError from './fieldError';
import Label from './labelText';


export default function TextField({
  type = 'text', name, label, required, placeholder, prefix, error,
  value, wrapperClasses, xl, hideError, ...props
}) {
  return (
    <div className={wrapperClasses}>
      <label className="block">
        {label && <Label required={required} xl={xl}>{label}</Label>}
        <div
          className={`flex align-items ${label && (xl ? 'mt-3' : 'mt-1')}`}
        >
          {prefix && (
            <div
              className={`text-inputText py-2 pl-3 pr-1 border border-transparent ${xl ? 'text-2xl sm:text-4xl' : 'text-lg sm:text-xl'}`}
            >{prefix}</div>
          )}
          <input
            className={`form-input text-inputText block w-full ${xl ? 'text-2xl sm:text-4xl' : 'text-lg sm:text-xl'}`}
            placeholder={placeholder}
            name={name}
            type={type}
            {...props}
            value={value || ''}
          />
        </div>
      </label>
      {!hideError && <FieldError error={error} />}
    </div>
  );
}