import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import Tabs from '../tabs';
import AccessControl from '../accessControl';
import Bonuses from './bonuses';
import GameLogs from './gameLogs';
import Promotions from './promotions';
import Restrictions from './restrictions';
import Settings from './settings';
import Transactions from './transactions';


export default function Profile({ section }) {
  const { navigate, t } = useI18next();
  section = section || 'index';
  let Component = null;

  switch (section) {
    case 'index':
      Component = Settings;
      break;

    case 'restrictions':
      Component = Restrictions;
      break;

    case 'bonuses':
      Component = Bonuses;
      break;

    case 'game_logs':
      Component = GameLogs;
      break;

    case 'transactions':
      Component = Transactions;
      break;

    case 'promotions':
      Component = Promotions;
      break;

    default:
  }

  return (
    <AccessControl>
      <div className="pb-5">
        <Tabs
          tabDefinitions={{
            index: t('auth:profile.tabSettings'),
            restrictions: t('auth:profile.tabRestrictions'),
            transactions: t('auth:profile.tabTransactions'),
            bonuses: t('auth:profile.tabBonuses'),
            game_logs: t('auth:profile.tabGameLogs'),
            promotions: t('auth:profile.tabPromotions')
          }}
          selected={section}
          onChange={tab => navigate(tab === 'index' ? '/profile/' : `/profile/${tab}/`)}
        />
      </div>
      {Component && <Component />}
    </AccessControl>
  );
}