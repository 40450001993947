// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog/details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog/list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-cashier-js": () => import("./../../../src/templates/cashier.js" /* webpackChunkName: "component---src-templates-cashier-js" */),
  "component---src-templates-casino-table-games-js": () => import("./../../../src/templates/casino-table-games.js" /* webpackChunkName: "component---src-templates-casino-table-games-js" */),
  "component---src-templates-cryptocurrency-casino-js": () => import("./../../../src/templates/cryptocurrency-casino.js" /* webpackChunkName: "component---src-templates-cryptocurrency-casino-js" */),
  "component---src-templates-game-js": () => import("./../../../src/templates/game.js" /* webpackChunkName: "component---src-templates-game-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-live-dealer-js": () => import("./../../../src/templates/live-dealer.js" /* webpackChunkName: "component---src-templates-live-dealer-js" */),
  "component---src-templates-login-js": () => import("./../../../src/templates/login.js" /* webpackChunkName: "component---src-templates-login-js" */),
  "component---src-templates-logout-js": () => import("./../../../src/templates/logout.js" /* webpackChunkName: "component---src-templates-logout-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-password-recovery-js": () => import("./../../../src/templates/password-recovery.js" /* webpackChunkName: "component---src-templates-password-recovery-js" */),
  "component---src-templates-profile-js": () => import("./../../../src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */),
  "component---src-templates-promo-details-js": () => import("./../../../src/templates/promo/details.js" /* webpackChunkName: "component---src-templates-promo-details-js" */),
  "component---src-templates-promo-list-js": () => import("./../../../src/templates/promo/list.js" /* webpackChunkName: "component---src-templates-promo-list-js" */),
  "component---src-templates-reviews-details-js": () => import("./../../../src/templates/reviews/details.js" /* webpackChunkName: "component---src-templates-reviews-details-js" */),
  "component---src-templates-reviews-list-js": () => import("./../../../src/templates/reviews/list.js" /* webpackChunkName: "component---src-templates-reviews-list-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-sign-up-confirmation-js": () => import("./../../../src/templates/sign-up-confirmation.js" /* webpackChunkName: "component---src-templates-sign-up-confirmation-js" */),
  "component---src-templates-sign-up-js": () => import("./../../../src/templates/sign-up.js" /* webpackChunkName: "component---src-templates-sign-up-js" */),
  "component---src-templates-slots-js": () => import("./../../../src/templates/slots.js" /* webpackChunkName: "component---src-templates-slots-js" */),
  "component---src-templates-sportsbook-js": () => import("./../../../src/templates/sportsbook.js" /* webpackChunkName: "component---src-templates-sportsbook-js" */)
}

