let baseDomain = process.env.BASE_DOMAIN;

if (!baseDomain) {
  baseDomain = '777bay.com';
}

module.exports = {
  "shortTitle": "777Bay",
  "baseDomain": baseDomain,
  "siteUrl": `https://www.${baseDomain}`,
  "api": {
    "root": `https://spa.777bay.com/api/bog/v2_1_1/`,
    "key": "36F5dSZl6LghjJF2fWrvnTs8Lk0XI1Os",
    "imageRoot": `https://spa.777bay.com`
  },
  "defaultLanguage": "en",
  "languages": ["en", "es", "de", "pt"],
  "traceSVGColor": "#d36041",
  "headerImgMaxWidth": 1456,
  "contentImgMaxWidth": 800,
  "imgQuality": 80,
  "robotsBlock": process.env.ROBOTS_BLOCK === 'true'
};
