import React from 'react';


export default function Pt() {
  return (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
    <path style={{ fill: '#E52521'}} d="M24,12c0,6.6-5.4,12-12,12c-2.4,0-4.5-0.7-6.4-1.8v-9.9l0-10.4C7.4,0.7,9.6,0,12,0C18.6,0,24,5.4,24,12z"/>
    <path style={{ fill: '#0C682F'}} d="M5.6,1.8C2.2,4,0,7.7,0,12s2.2,8,5.6,10.2L5.6,1.8z"/>
    <g>
      <circle style={{ fill: '#F4E600'}} cx="5.6" cy="11.9" r="3.3"/>
      <path style={{ fill: '#E52521'}} d="M3.7,12.4L3.7,12.4c0,0.5,0.2,1,0.6,1.3c0.4,0.4,0.8,0.6,1.4,0.6s1-0.2,1.4-0.6c0.4-0.3,0.6-0.8,0.6-1.3l0,0V9.9H3.7L3.7,12.4"/>
    </g>
  </svg>
  );
};
