import React, { useCallback, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import cx from 'clsx';

import { gameProviders } from '../../util';
import { ArrowRightSvg, SearchSvg } from '../svg';


function ProviderSwitch({ providerId, selected, onSwitch, big }) {
  let name = providerId;

  if (gameProviders.hasOwnProperty(providerId)) {
    name = gameProviders[providerId].name;
  }

  let textSizeClasses = 'text-lg sm:text-xl';

  if (big) {
    textSizeClasses = 'text-xl sm:text-2xl';
  }

  return (
    <label className="inline-flex items-center">
      <input
        type="checkbox"
        className={`form-checkbox ${textSizeClasses}`}
        checked={selected}
        onChange={ev => onSwitch(providerId, ev.target.checked)}
      />
      <span className={`ml-2 text-white ${textSizeClasses}`}>{name}</span>
    </label>
  );
}

function ProvidersFilter({ availableProviders, selectedProviders, onChange, showSearch }) {
  const { navigate, t } = useI18next();
  const [ show, setShow ] = useState(false);
  const [ searchValue, setSearchValue ] = useState();
  const handleSwitch = useCallback((key, isOn) => {
    let newSelectedProviders = 'all';

    if (key !== 'all') {
      if (isOn) {
        newSelectedProviders = selectedProviders === 'all' ? [] : selectedProviders.slice();

        if (newSelectedProviders.indexOf(key) === -1) {
          newSelectedProviders.push(key);
        }

        if (newSelectedProviders.length === availableProviders.length) {
          newSelectedProviders = 'all';
        }
      } else {
        if (selectedProviders === 'all' || (Array.isArray(selectedProviders) && selectedProviders.length === 0)) {
          newSelectedProviders = [ key ];
        } else {
          newSelectedProviders = selectedProviders === 'all' ? availableProviders.slice() : selectedProviders.slice();
          newSelectedProviders = newSelectedProviders.filter(p => p !== key);
        }
      }
    } else {
      if (!isOn) {
        newSelectedProviders = [];
      }
    }

    onChange(newSelectedProviders);
  }, [ availableProviders, selectedProviders, onChange ]);

  let selectedProvidersText = '';

  if (Array.isArray(selectedProviders)) {
    for (const selectedProvider of selectedProviders) {
      let name = selectedProvider;

      if (gameProviders.hasOwnProperty(selectedProvider)) {
        name = gameProviders[selectedProvider].name;
      }

      selectedProvidersText += name + ', ';
    }
  }

  if (selectedProvidersText) {
    selectedProvidersText = selectedProvidersText.substr(0, selectedProvidersText.length - 2);
  }

  return (
    <>
      <div className="p-3 flex flex-col md:flex-row-reverse items-center flex-nowrap justify-between">
        {showSearch &&
          <div className="flex flex-row mb-4 md:mb-0">
            <input
              type="search"
              className="form-input flex-grow bg-transparent border-2 border-accent1 rounded-md text-accent1 text-xl"
              placeholder={t('game.searchFieldPlaceholder')}
              value={searchValue || ''}
              onChange={ev => setSearchValue(ev.currentTarget.value)}
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  navigate(`/search/?q=${encodeURIComponent(searchValue)}`);
                }
              }}
            />
            <SearchSvg className="ml-2 flex-shrink-0" onClick={ev => {
              if (searchValue) {
                navigate(`/search/?q=${encodeURIComponent(searchValue)}`);
              }
            }} />
          </div>
        }
        <button
          className="inline-flex text-2xl py-2 px-3 rounded-md uppercase bg-accent1 hover:bg-accent1-alt transition-colors"
          onClick={() => setShow(!show)}
        >
          <ArrowRightSvg className="mr-2" r90 />{t('providers.providersLabel')}
        </button>
      </div>
      <div className={cx({
        'block': true,
        'h-auto p-3 pt-0': show,
        'invisible h-0 min-h-0': !show
      })}>
        <div className="p-2 flex justify-start">
          <ProviderSwitch
            providerId={'all'}
            selected={selectedProviders === 'all'}
            onSwitch={handleSwitch}
            big
          />
        </div>
        <ol className="flex flex-wrap items-center justify-start list-none m-0">
          {availableProviders.map(key => {
            return (
              <li key={key} className="flex-shrink-0 p-2">
                <ProviderSwitch
                  providerId={key}
                  selected={selectedProviders === 'all' || selectedProviders.includes(key)}
                  onSwitch={handleSwitch}
                />
              </li>
            );
          })}
        </ol>
      </div>
    </>
  );
}

export default ProvidersFilter;
