import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ProvidersFilter from './providersFilter';
import GameList from './gameList';
import Search from './search';
import {
  useGameProvidersFilter, useApiGames, useGamesByCategories,
  useApiGamesFilter, useApiFetch
} from '../../hooks';
import Tabs from '../tabs';
import { gameCategories, paginateGames } from '../../util';
import { apiGamesToNodes } from '@2diglobal/gatsby-source-bog';


function MultiCategoryGameList({
  gqlData,
  linkUrlBase = '',
  availableCategories,
  selectedCategory = 'all',
  currentPage = 1,
  gamesPerPage,
  scrollToOnPagination = '',
  withSearch,
  apiGamesFilter,
  hideProviderSelection,
  blockRequest,
  hideOnEmptySearch = false,
  appendPaginationCategory = false,
  hideLoadMore = false,
  initialSearchQuery = ''
}) {
  const { t } = useTranslation();
  const {
    availableProviders, selectedProviders, handleProviderChange
  } = useGameProvidersFilter('all', linkUrlBase);
  const { apiUserFetch } = useApiFetch();

  const categoriesFilter = useMemo(() => {
    if (selectedCategory === 'all') {
      if (!Array.isArray(availableCategories)) {
        return 'all';
      }

      return availableCategories.filter(c => c !== 'all');
    }

    return selectedCategory;
  }, [ selectedCategory, availableCategories ]);

  const [ searchTerm, setSearchTerm ] = useState(initialSearchQuery);
  const [ searchedGames, setSearchedGames ] = useState();

  const apiGames = useApiGames('all', selectedProviders, blockRequest);
  let filteredGames = useGamesByCategories(apiGames, categoriesFilter);

  useEffect(() => {
    if (withSearch && initialSearchQuery) {
      apiUserFetch(`games/search/${initialSearchQuery}/1000`, {})
        .then(response => {
          if (response.data.totalGames > 0) {
            setSearchedGames(apiGamesToNodes(response.data.games));
          }
        })
        .catch(() => {
          console.log('Search error');
        });
    }
  }, [ withSearch, initialSearchQuery, apiUserFetch ]);

  if (withSearch) {
    filteredGames = searchedGames;
  }

  filteredGames = useApiGamesFilter(apiGamesFilter, filteredGames);

  let paginatedGames = paginateGames(filteredGames, currentPage, gamesPerPage);
  let totalCount = 0;

  if (paginatedGames) {
    totalCount = filteredGames.length;
  } else {
    if (gqlData) {
      if (gqlData.edges) {
        paginatedGames = gqlData.edges;
        totalCount = paginatedGames.length;
      }

      if (gqlData.totalCount) {
        totalCount = gqlData.totalCount;

        if (paginatedGames && paginatedGames.length === totalCount) {
          paginatedGames = paginateGames(paginatedGames, currentPage, gamesPerPage);
        }
      }
    }
  }

  const pageCount = Math.ceil(totalCount / gamesPerPage);

  const handleCategoryChange = useCallback(category => {
    let navigateTo = `/${linkUrlBase}/`;

    if (category !== 'all') {
      navigateTo += `${category}/`;
    }

    navigate(navigateTo, {
      state: {
        scrollTo: scrollToOnPagination
      }
    });
  }, [ linkUrlBase, scrollToOnPagination ]);

  let paginationUrlBase = linkUrlBase;
  if (appendPaginationCategory && selectedCategory !== 'all') {
    paginationUrlBase += `/${selectedCategory}`;
  }

  return (
    <div>
      {withSearch && <Search value={searchTerm} onChange={setSearchTerm} />}
      {(!hideProviderSelection && (!withSearch || initialSearchQuery)) && (
        <ProvidersFilter
          availableProviders={availableProviders}
          selectedProviders={selectedProviders}
          onChange={handleProviderChange}
          showSearch={!withSearch}
        />
      )}
      {Array.isArray(availableCategories) && (
        <Tabs
          tabDefinitions={gameCategories}
          availableTabs={availableCategories}
          selected={selectedCategory}
          onChange={handleCategoryChange}
        />
      )}
      <GameList
        games={paginatedGames}
        emptyMsg={withSearch && !initialSearchQuery ? '' : t('noGamesFound')}
      />
      {pageCount > 0 && currentPage < pageCount && !hideLoadMore && (
        <div className="pt-2 pb-10 m-0 flex justify-center">
          <Link
            className="button primary"
            to={`/${paginationUrlBase}/${currentPage + 1}/`}
            state={{ scrollTo: false }}
          >
            {t('loadMore')}
          </Link>
        </div>
      )}
    </div>
  );
}

export default MultiCategoryGameList;
