import { useState, useRef } from 'react';
import useIsMounted from 'ismounted';
import { apiGamesToNodes } from '@2diglobal/gatsby-source-bog';

import {
  useCache, useApiFetch, usePlayerProvidersLoaded,
  useIsomorphicLayoutEffect
} from './';
import { gameProviderNames } from '../util/gameProviders';
import { isMobileBrowser } from '../util';


const isMobile = isMobileBrowser();

export default function useApiGames(categories, providers, blockRequest = false, requestOptions = {}, mobileCheck = true) {
  const cache = useCache();
  const lastCacheKey = useRef(undefined);
  const [ gameNodes, setGameNodes ] = useState(null);
  const { apiUserFetch } = useApiFetch();
  const isMounted = useIsMounted();
  const arePlayerProviders = usePlayerProvidersLoaded();

  const joinedCategories = typeof categories === 'string' ? categories : categories.join(',');
  const joinedProviders = typeof providers === 'string' ? providers : providers.join(',');
  const categoriesProvidersUrlChunk = `${joinedCategories}/${joinedProviders}`;

  const cacheKey = `${arePlayerProviders ? 'playerGames' : 'games'}:${categoriesProvidersUrlChunk}`;

  useIsomorphicLayoutEffect(() => {
    if (blockRequest || lastCacheKey.current === cacheKey) {
      return;
    }

    lastCacheKey.current = cacheKey;

    if (cache.has(cacheKey)) {
      setGameNodes(cache.get(cacheKey));
      return;
    }

    setGameNodes(undefined);

    apiUserFetch(
      `games/filter/${categoriesProvidersUrlChunk}/nosort/19000/0`,
      requestOptions
    )
      .then(response => {
        let _filteredGameNodes = null;

        if (response && response.data && response.data.games) {
          let _gameNodes = apiGamesToNodes(response.data.games, gameProviderNames);
          _filteredGameNodes = [];

          for (const gameNode of _gameNodes) {
            if (mobileCheck && ((isMobile && !gameNode.isMobile) || (!isMobile && gameNode.isMobile))) {
              continue;
            }

            _filteredGameNodes.push(gameNode);
            cache.set(`game:${gameNode.slug}`, gameNode);
          }

          cache.set(cacheKey, _filteredGameNodes)
        }

        if (isMounted.current && lastCacheKey.current === cacheKey) {
          setGameNodes(_filteredGameNodes);
        }
      })
      .catch(() => {
        if (isMounted.current && lastCacheKey.current === cacheKey) {
          setGameNodes(null);
        }
      });
  }, [
    arePlayerProviders, apiUserFetch, categoriesProvidersUrlChunk,
    isMounted, cache, blockRequest, cacheKey
  ]);

  return gameNodes;
}