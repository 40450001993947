import { makeApiFetch } from '..';
import { SET, START_LOADING, RESET } from './types';


export const setPlayerBalance = (balance, bonus, status) => ({
  type: SET,
  balance,
  bonus,
  status
});

export const startLoading = () => ({
  type: START_LOADING
});

export const reset = () => ({
  type: RESET
});

export const fetchPlayerBalance = () => (dispatch, getState) => {
  const { player, playerBalance } = getState();

  if (!player.playerKey || playerBalance.loading) {
    return;
  }

  dispatch(startLoading());

  return makeApiFetch(
    `player/balance`,
    getState()
  )
    .then(resp => {
      if (resp.info && resp.info.success) {
        dispatch(setPlayerBalance(
          parseFloat(resp.data.balance.replace(/,/ig, ''), 10),
          parseFloat(resp.data.bonus.replace(/,/ig, ''), 10),
          resp.data.status
        ));

        return;
      }

      return Promise.reject();
    })
    .catch(() => {
      dispatch(reset());
    });
};
