export default function sanitizeBonus({
  id, bonus_cleared: clearedPercentage, active: isActive,
  bonus_amount: amount, deposit_amount: depositAmount,
  points_needed: pointsNeeded, contribution_total: contributionTotal,
  issue_time: issuedOn, finished_on: finishedOn, valid_until: validUntil,
  message_to_player: playerMessage,
  bonus_data: { id: bonusId, code, name, wagering_requirement: wageringRequirement }
}) {
  return {
    id,
    isActive,
    amount,
    depositAmount,
    clearedPercentage,
    pointsNeeded,
    contributionTotal,
    issuedOn,
    finishedOn,
    validUntil,
    playerMessage,
    bonusMeta: {
      id: bonusId,
      code,
      name,
      wageringRequirement
    }
  };
}