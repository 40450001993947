import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { useSelector } from 'react-redux';

import StandardPage from './standardPage';
import SignUpForm from '../components/signUp';
import { useRedirectUser } from '../hooks';


function goftSelector(s) {
  return s.api.goft;
}

function papSelector(s) {
  return s.api.pap;
}

const SignUp = props => {
  const { t } = useTranslation();
  const [ userData, setUserData ] = useState(undefined);
  const goftToken = useSelector(goftSelector);
  const pap = useSelector(papSelector);

  useRedirectUser('/');

  return (
    <StandardPage {...props}>
      {!userData && <SignUpForm onSignUp={setUserData} goftToken={goftToken} aAid={pap.aAid} aBid={pap.aBid} />}
      {userData && (
        <>
          <h2 className="text-center">{t('auth:signUp.successTitle')}</h2>
          <p className="text-center">{t('auth:signUp.successMessage', { username: userData.username })}</p>
          <div className="flex justify-center">
            <Link className="button m-2 sm:m-4 primary" to="/">{t('auth:homeRedirectButton')}</Link>
          </div>
        </>
      )}
    </StandardPage>
  );
};

export default SignUp;

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $traceSVGColor: String!, $key: String!, $language: String!) {
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contentfulPage(language: {code: {eq: $language}}, key: {eq: $key}) {
      title
      metaTitle
      metaDescription
    }
    alternateLanguagePages: allContentfulPage(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
  }
`;
