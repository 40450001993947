import { SET_LOADING, SET_METHODS, RESET_ALL } from './types';

const initialState = {
  loading: false,
  deposit: null,
  withdrawal: null
};

export default function cashierMethods(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_METHODS:
      const updatedState = {};

      for (const key of Object.keys(initialState)) {
        if (action.hasOwnProperty(key)) {
          updatedState[key] = action[key];
        }
      }

      return { ...state, ...updatedState, loading: false };

    case SET_LOADING:
      return { ...state, loading: !!action.loading };

    case RESET_ALL:
      return { ...initialState };

    default:
      return state;
  }
};
