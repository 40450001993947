import React from 'react';
import { graphql } from 'gatsby';

import { useCache, useApiGames } from '../hooks';
import Game from './game';


const Sportsbook = props => {
  const { site } = props.data;
  const cache = useCache();
  let gameSlug = 'di-sportsbook';
  let game = cache.get(`game:${gameSlug}`);

  if (!game) {
    gameSlug = 'di-sportsbook-mobile';
    game = cache.get(`game:${gameSlug}`);
  }

  useApiGames('sportsbook', 'all', !!game, { data: { sportsbook: 'true' } }, false);

  return (
    <Game
      {...props}
      slug={gameSlug}
      baseDomain={site.siteMetadata.env.baseDomain}
      requestOptions={{ prefer_url: false }}
      skipResize
    />
  );
};

export default Sportsbook;

export const pageQuery = graphql`
  query($contentImgMaxWidth: Int!, $imgQuality: Int!) {
    contentfulGame(key: {eq: "sportsbook"}, language: {code: {eq: "en"}}) {
      title
      summary {
        raw
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            cid: contentful_id
            fixed(
              width: $contentImgMaxWidth,
              quality: $imgQuality
            ) {
              src
            }
            alt: title
            href: description
          }
        }
      }
      metaTitle
      metaDescription
    }
    site {
      siteMetadata {
        title
        env {
          baseDomain
        }
      }
    }
  }
`;
