import React, { useState, useCallback, useRef, useMemo } from 'react';
import useIsMounted from 'ismounted';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ApiForm from '../apiForm';
import WalletInfo from './walletInfo';
import { useApiFetch, usePlayer } from '../../hooks';
import { isBrowser } from '../../util';


function closeWindow() {
  window.close();
}

const iFrameStyle = {
  width: '100%',
  height: '100%'
};

function Method({ methodType, method, bonusMessage }) {
  const { t } = useTranslation();
  const [ submitting, setSubmitting ] = useState(false);
  const [ formError, setFormError ] = useState();
  const [ iframeData, setIframeData ] = useState();
  const [ wallet, setWallet ] = useState();
  const { apiUserFetch } = useApiFetch();
  const isMounted = useIsMounted();
  const iframeSubmitted = useRef(false);
  const htmlIframeRef = useRef();
  const { city } = usePlayer();
  const [ postForm, setPostForm ] = useState();

  const handleSubmit = useCallback(data => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    setFormError(undefined);

    delete data.cvv;
    delete data.creditCardNumber;

    apiUserFetch(method.form.url, {
      data,
      method: method.form.method
    })
      .then(response => {
        setSubmitting(false);

        if (
          !response ||
          !response.data ||
          !response.info
        ) {
          setFormError(t('cashier:generalProcessingError'));
          return;
        }

        if (!response.info.success) {
          const message = response.data.messages || response.info.message;

          if (message) {
            setFormError(message.replace(/(<([^>]+)>)/ig, ''));
            return;
          }
        }

        switch (response.data.status) {
          case 'redirect':
            const {
              container, url, parameters, method: responseMethod, html
            } = response.data.redirect;
            let {
              height, width
            } = response.data.redirect;

            if (container === 'iframe') {
              height = parseInt(height, 10);
              width = parseInt(width, 10);

              if (isNaN(height) || height < 600) {
                height = 600;
              }

              if (method.code === 'mifinity' && height < 1700) {
                height = 1700;
              }

              if (isNaN(width) || width < 600) {
                width = 600;
              }

              setIframeData({
                height,
                width,
                url,
                html,
                parameters,
                method: responseMethod
              });

              if (isBrowser()) {
                window.resizeTo(width + 60, height + 150);
              }
            } else {
              if (url) {
                if (responseMethod === 'POST') {
                  setPostForm({
                    url: url,
                    parameters: parameters || {}
                  });
                } else {
                  let urlParams = '';

                  if (parameters) {
                    for (const key of Object.keys(parameters)) {
                      urlParams += encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]) + '&';
                    }
                  }

                  window.location.replace(url + (urlParams ? `?${urlParams}` : ''));
                }
              } else if (html) {
                document.open();
                document.write(html);
                document.close();
              }
            }
            break;

          case 'show_wallet':
            let wallet = undefined;

            if (
              typeof response.data.crypto_wallet === 'object' &&
              response.data.crypto_wallet.address &&
              typeof response.data.crypto_wallet.exchange_rate === 'object'
            ) {
              const { currFromStr, currToStr } = response.data.crypto_wallet.exchange_rate;

              wallet = {
                exchangeRateMessage: t('cashier:currentExchangeRate', { exchangeRate: `${currFromStr} = ${currToStr}` }),
                address: response.data.crypto_wallet.address,
                destinationTag: response.data.crypto_wallet.destinationTag,
                network: method.network,
                bottomMessage: method.bottomMessage
              };
            }

            setWallet(wallet);
            break;

          case 'complete':
            closeWindow();
            break;

          default:
            // TODO sentry error, unknown payment method response
        }
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);
        setFormError(t('cashier:unknownDataProcessingError'));
      });
  }, [ submitting, method, isMounted, apiUserFetch, t ]);

  const fields = useMemo(() => {
    if (method.code !== 'visa' && method.code !== 'mastercard') {
      return method.form.fields;
    }

    return [
      ...method.form.fields,
      {
        name: t('cashier:cityFieldLabel'),
        code: 'city',
        fieldType: 'hidden',
        type: 'string',
        defaultValue: city,
        required: true,
        updatable: false
      }
    ];
  }, [ method.code, method.form.fields, city, t ]);

  const htmlIframeMount = useCallback(iframeEl => {
    if (iframeEl === htmlIframeRef.current) {
      return;
    }

    htmlIframeRef.current = iframeEl;
    iframeEl.contentWindow.document.write(iframeData.html);
  }, [ htmlIframeRef, iframeData ]);

  if (wallet) {
    return (
      <WalletInfo wallet={wallet} />
    );
  }

  if (iframeData) {
    const { url, html, method, parameters, height } = iframeData;
    const iframeStyle = {
      minHeight: height,
      ...iFrameStyle
    };

    if (method === 'GET' && (!Array.isArray(parameters) || parameters.length === 0)) {
      if (html) {
        return (
          <iframe
            title={t('cashier:title')}
            name="redirectTo"
            style={iframeStyle}
            ref={htmlIframeMount}
          />
        );
      }

      return (
        <iframe
          title={t('cashier:title')}
          name="redirectTo"
          style={iframeStyle}
          src={url}
        />
      );
    }

    return (
      <>
        <iframe
          title={t('cashier:title')}
          name="redirectTo"
          style={iframeStyle}
        />
        <form
          action={url}
          method={method}
          target="redirectTo"
          ref={form => {
            if (iframeSubmitted.current) {
              return;
            }

            form.submit();
            iframeSubmitted.current = true;
          }}
        >
          {parameters && Object.keys(parameters).map(key => <input key={key} type="hidden" name={key} value={parameters[key]} />)}
        </form>
      </>
    );
  }

  if (postForm) {
    const { url, parameters } = postForm;
    return (
      <form
        action={url}
        method="POST"
        ref={formEl => {
          formEl.submit();
        }}
      >
        {parameters && Object.keys(parameters).map(key => <input key={key} type="hidden" name={key} value={parameters[key]} />)}
      </form>
    );
  }

  const methodTitle = t(`cashier:${methodType === 'deposit' ? 'depositTitle' : 'withdrawTitle'}`);

  return (
    <>
      <div className="max-w-3xl m-auto">
        <p className="text-center micro:text-2xl text-3xl text-primary mt-4 mb-0">{methodTitle} - <span className="font-bold">{method.name}</span></p>
        <p className="text-center micro:text-lg text-xl text-gray-700 mb-3">{method.limits.min.text}; {method.limits.max.text}</p>
        {method.userMessageKey && (
          <p className="text-center micro:text-lg text-lg text-primary mt-3">{t(method.userMessageKey)}</p>
        )}
        {bonusMessage && <p className="text-center micro:text-xl text-2xl text-primary mb-2" dangerouslySetInnerHTML={{ __html: bonusMessage }} />}
        <ApiForm
          fields={fields}
          onSubmit={handleSubmit}
          submitting={submitting}
          formErrors={formError ? { '__form__': formError } : undefined}
          submitText={methodTitle}
        />
      </div>
      {method.footer ? method.footer : null}
    </>
  );
}

export default Method;