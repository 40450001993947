module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/locales","languages":["en","es","de","pt"],"defaultLanguage":"en","redirect":true,"siteUrl":"https://www.777bay1.com","i18nextOptions":{"ns":["common","auth","forms"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/:path*","getLanguageFromPath":true,"excludeLanguages":["en","es","de","pt"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"777Bay","short_name":"777Bay","start_url":"/","background_color":"#191515","theme_color":"#d36041","display":"standalone","icon":"src/images/fav.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5b582faa87d6a366b177df017e44763d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-192246171-1","pageTransitionDelay":0,"head":false,"anonymize":false,"respectDNT":false,"exclude":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
