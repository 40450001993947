import { format, subYears } from 'date-fns';

import { makeApiFetch } from '..';
import { SET, START_LOADING, RESET } from './types';


export const setPlayerTransactions = transactions => ({
  type: SET,
  transactions
});

export const startLoading = () => ({
  type: START_LOADING
});

export const reset = () => ({
  type: RESET
});

export const fetchPlayerTransactions = (pendingWithdrawalIds = []) => (dispatch, getState) => {
  const { player, playerTransactions } = getState();

  if (!player.playerKey || playerTransactions.loading) {
    return;
  }

  dispatch(startLoading());

  const nowDate = Date.now();

  return makeApiFetch(
    `player/transactions`,
    getState(),
    {
      data: {
        player_id: player.id,
        limit: 999999,
        filters: {
          offset: 0,
          date_request_from: format(subYears(nowDate, 1), 'yyyy-MM-dd') + ' 00:00:00',
          date_request_to: format(nowDate, 'yyyy-MM-dd') + ' 23:59:59'
        }
      },
      method: 'post'
    }
  )
    .then(response => {
      if (!response || !response.info || !response.info.success) {
        throw Promise.reject();
      }

      if (Array.isArray(response.data.transactions) && response.data.transactions.length > 0) {
        let filteredTransactions = [].concat(response.data.transactions);
        filteredTransactions = filteredTransactions.map(trans => {
          if (pendingWithdrawalIds.length > 0) {
            trans.isPW = pendingWithdrawalIds.includes(trans.id);
          } else {
            trans.isPW = false;
          }

          trans.processedDate = new Date(trans.date_processed);

          if (isNaN(trans.processedDate)) {
            trans.processedDate = null;
          }

          trans.requestDate = new Date(trans.date_request);

          return trans;
        });

        dispatch(setPlayerTransactions(filteredTransactions));
      }
    })
    .catch(() => {
      dispatch(reset());
    });
};
