import { useState, useCallback } from 'react';


export default function useInternalValue(onChange) {
  const [ internalValue, setInternalValue ] = useState();
  const setValue = useCallback(v => {
    setInternalValue(v);

    if (onChange) {
      onChange(v);
    }
  }, [ onChange ]);

  return [
    internalValue,
    setValue
  ];
}