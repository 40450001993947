import { makeApiFetch } from '..';
import { SET_LOADING, SET_METHODS, RESET_ALL } from './types';

import sanitizeMethods from './sanitizeMethods';


export const setLoading = loading => ({
  type: SET_LOADING,
  loading
});

export const setMethods = (deposit, withdrawal) => ({
  type: SET_METHODS,
  deposit,
  withdrawal
});

export const resetAll = () => ({
  type: RESET_ALL
});

export const fetchMethodsIfNeeded = () => (dispatch, getState) => {
  const { deposit, loading } = getState().cashierMethods;

  if (!deposit && !loading) {
    dispatch(setLoading(true));

    return makeApiFetch(
      `player/cashier/both`,
      getState()
    )
      .then(resp => {
        if (!resp.info || !resp.info.success) {
          return Promise.reject();
        }

        const { deposit_methods, withdrawal_methods } = resp.data;

        dispatch(setMethods(sanitizeMethods(deposit_methods), sanitizeMethods(withdrawal_methods, true)));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }

  
};
