import { SET_GOFT, SET_PAP } from './types';


export default function api(
  state = {
    root: undefined,
    key: undefined,
    imageRoot: undefined,
    goft: undefined,
    pap: {
      aAid: undefined,
      aBid: undefined
    }
  },
  action
) {
  const { type, goft, aAid, aBid } = action;

  switch (type) {
    case SET_GOFT:
      return { ...state, goft };

    case SET_PAP:
      if (aAid && aBid) {
        return { ...state, pap: { aAid, aBid } };
      }

      return state;

    default:
      return state;
  }
};
