import React from "react";
import { Link } from "gatsby";


const Table = ({ body }) => {
  if (!Array.isArray(body) || body.length === 0) {
    return null;
  }

  return (
    <div className="w-full overflow-x-auto mt-4 mb-6">
      <table cellSpacing={0} className="standard">
        <tbody>
          {body.map((row, i) => {
            if (!Array.isArray(row) || row.length === 0) {
              return null;
            }

            return (
              <tr key={i}>
                {
                  row.map((content, j) => {
                    let cellContent = null;

                    if (typeof content === 'string' || typeof content === 'number') {
                      cellContent = content;
                    } else if (content && typeof content === 'object') {
                      const url = content.url;
                      cellContent = [];

                      if (content.text) {
                        if (url) {
                          cellContent.push(<Link key="text" to={url}>{content.text}</Link>);
                        } else {
                          cellContent.push(<span key="text">{content.text}</span>);
                        }
                      }

                      if (content.img) {
                        if (url) {
                          cellContent.push(<Link key="img" to={url}><img className="responsiveImg" src={content.img} alt={content.alt || ''} /></Link>);
                        } else {
                          cellContent.push(<img key="img" className="responsiveImg" src={content.img} alt={content.alt || ''} />);
                        }
                      }

                      if (content.button) {
                        if (url) {
                          cellContent.push(<Link to={url} className="button" key="button">{content.button}</Link>);
                        }
                      }
                    }

                    return (
                      <td key={j}>{cellContent}</td>
                    );
                  })
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;