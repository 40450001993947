import { SET_LOADING, SET, RESET_ALL } from './types';

const initialState = {
  loading: false,
  list: null
};

export default function bonuses(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET:
      return { list: action.bonuses, loading: false };

    case SET_LOADING:
      return { ...state, loading: !!action.loading };

    case RESET_ALL:
      return { ...initialState };

    default:
      return state;
  }
};
