import React, { useState, useEffect, useRef, useCallback } from 'react';
import { subMonths, format, parseISO } from 'date-fns';
import useIsMounted from 'ismounted';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import DateField from '../form/dateField';
import { useApiFetch, usePlayer, usePendingWithdrawals } from '../../hooks';
import { formatCurrency, noop } from '../../util';
import { fetchPendingWithdrawals } from '../../store/pendingWithdrawals/actions';
import { fetchPlayerBalance } from '../../store/playerBalance/actions';


function TransactionItem({
  transaction: {
    status, requestDate, amount, isPW, id, type, message, processedDate, method
  },
  currencyCode, apiUserFetch, onCancel
}) {
  const { t } = useTranslation();
  const [ showDetails, setShowDetails ] = useState(false);

  // id	19239849
  // - amount	20
  // withdrawals_limit_exceeded	false
  // processor	"paymentiq"
  // method	"MiFinityEWalletDeposit"
  // transid	"777bay_832975869"
  // canCancelTransaction	true
  // canPlayerCancelWithdraw	false
  // -type	"deposit"
  // - status	"CANCELLED"
  // -message	"Denied by support: contact support for more information"
  // - requestDate new Date("2022-03-03 22:09:26")
  // -processedDate new Date("2022-03-11 05:00:08")

  return (
    <>
      <tr role="button" tabIndex="0" className="py-4 border-t-2 cursor-pointer" onKeyPress={noop} onClick={ev => {
        if (ev.target.tagName === 'BUTTON') {
          return;
        }

        setShowDetails(!showDetails);
      }}>
        <td className="p-1 text-center">
          {isPW && (
            <button className="button secondary" onClick={() => {
              apiUserFetch('player/transactions/pendingWithdrawals/cancel', {
                method: 'POST',
                data: {
                  trans_ids: [ id ]
                }
              })
                .then(onCancel)
                .catch(onCancel);
            }}>
              {t('auth:transactions.cancelLabel')}
            </button>
          )}
          {!isPW && (
            <span>-</span>
          )}
        </td>
        <td className="p-1 text-left">
          <span className="text-xl truncate">{format(requestDate, 'do MMM yyyy\', \'pp')}</span>
        </td>
        <td className="p-1 text-center">
          <span className="text-xl truncate">{type}</span>
        </td>
        <td className="p-1 text-center">
          <span className="text-xl truncate">{formatCurrency(amount, currencyCode)}</span>
        </td>
        <td className="p-1 text-center">
          <span className={clsx('text-xl truncate', {
            'text-red-500': status === 'CANCELLED',
            'text-green-500': status === 'PROCESSED'
          })}>{status.toLowerCase()}</span>
        </td>
      </tr>
      {showDetails && (
        <tr className="border-t border-gray-700">
          <td className="p-1 text-center" colSpan={3}>
            <span className="text-lg">{message}</span>
          </td>
          <td className="p-1 text-center">
            {processedDate !== null && <span className="text-lg">Processed on {format(processedDate, 'do MMM yyyy\', \'pp')}</span>}
          </td>
          <td className="p-1 text-center">
            <span className="text-lg">Method: {method}</span>
          </td>
        </tr>
      )}
    </>
  );
}

export default function Transactions() {
  const { t } = useTranslation();
  const [ to, setTo ] = useState(new Date());
  const [ from, setFrom ] = useState(subMonths(to, 1));
  const activeDates = useRef();
  const { apiUserFetch } = useApiFetch();
  const { id: playerId, currencycode } = usePlayer();
  const [ transactions, setTransactions ] = useState(null);
  const [ error, setError ] = useState();
  const [ isReset, reset ] = useState(true);
  const isMounted = useIsMounted();
  const { transactionIds: pendingWithdrawalIds } = usePendingWithdrawals();
  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    dispatch(fetchPendingWithdrawals());
    dispatch(fetchPlayerBalance());
    reset(true);
  }, [ dispatch ]);

  useEffect(() => {
    if (!isReset) {
      return;
    }

    const dates = { to, from };
    activeDates.current = dates;

    if (from > to) {
      setError(t('auth:transactions.dateErrorMessage'));

      return;
    }
    setError(undefined);
    setTransactions(undefined);

    if (!Array.isArray(pendingWithdrawalIds)) {
      return;
    }

    reset(false);

    apiUserFetch('player/transactions', {
      data: {
        player_id: playerId,
        limit: 999999,
        filters: {
          offset: 0,
          date_request_from: format(from, 'yyyy-MM-dd') + ' 00:00:00',
          date_request_to: format(to, 'yyyy-MM-dd') + ' 23:59:59'
        }
      },
      method: 'post'
    })
      .then(response => {
        if (!isMounted.current || dates !== activeDates.current) {
          return;
        }

        if (!response || !response.info || !response.info.success) {
          throw new Error();
        }

        let filteredTransactions = [];

        if (Array.isArray(response.data.transactions) && response.data.transactions.length > 0) {
          filteredTransactions = response.data.transactions;
          filteredTransactions = filteredTransactions.map(trans => {
            if (pendingWithdrawalIds.length > 0) {
              trans.isPW = pendingWithdrawalIds.includes(trans.id);
            } else {
              trans.isPW = false;
            }

            trans.processedDate = new Date(trans.date_processed);

            if (isNaN(trans.processedDate)) {
              trans.processedDate = null;
            }

            trans.requestDate = new Date(trans.date_request);

            return trans;
          });
        }

        setTransactions(filteredTransactions);
      })
      .catch(() => {
        if (isMounted.current) {
          setError(t('auth:transactions.fetchErrorMessage'));
        }
      });
  }, [ to, from, pendingWithdrawalIds, isReset, apiUserFetch, playerId, isMounted, t ]);

  return (
    <div className="my-3">
      <h2 className="text-center">{t('auth:transactions.label')}</h2>
      <div className="sm:flex">
        <div className="sm:w-1/2 p-2">
          <DateField
            name="fromDate"
            label={t('auth:transactions.fromLabel')}
            onChange={value => {
              reset(true);
              setFrom(parseISO(value));
            }}
            value={format(from, 'yyyy-MM-dd')}
          />
        </div>
        <div className="sm:w-1/2 p-2">
          <DateField
            name="toDate"
            label={t('auth:transactions.toLabel')}
            onChange={value => {
              reset(true);
              setTo(parseISO(value));
            }}
            value={format(to, 'yyyy-MM-dd')}
          />
        </div>
      </div>
      <div className="p-2 overflow-x-auto max-w-full">
        {transactions === undefined && <p className="text-center text-2xl">{t('auth:transactions.loading')}</p>}
        {error && <p className="text-center text-2xl text-red-600">{error}</p>}
        {Array.isArray(transactions) && transactions.length === 0 && (
          <p className="text-center text-2xl">{t('auth:transactions.noTransactions')}</p>
        )}
        {Array.isArray(transactions) && transactions.length > 0 && (
        <div>
          <table className="">
            <tbody>
            <tr>
              <th className="text-center">{t('auth:transactions.actionLabel')}</th>
              <th className="text-left">{t('auth:transactions.dataRequestedLabel')}</th>
              <th className="text-center">&nbsp;</th>
              <th className="text-center">{t('auth:transactions.amountLabel')}</th>
              <th className="text-center">{t('auth:transactions.statusLabel')}</th>
            </tr>
            {transactions.map(trans => (
              <TransactionItem
                key={trans.id}
                transaction={trans}
                currencyCode={currencycode}
                apiUserFetch={apiUserFetch}
                onCancel={handleCancel}
              />
            ))}
            </tbody>
          </table>
        </div>
        )}
        {false && Array.isArray(transactions) && transactions.length > 0 && (
          <div className="hidden relative">
            <div className="flex">
              <div className="w-2/12 flex-none text-center">
                <span className="text-2xl truncate">{t('auth:transactions.actionLabel')}</span>
              </div>
              <div className="w-6/12 flex-none text-left">
                <span className="text-2xl truncate">{t('auth:transactions.dataRequestedLabel')}</span>
              </div>
              <div className="w-1/12 flex-none text-center"></div>
              <div className="w-2/12 flex-none text-center">
                <span className="text-2xl truncate">{t('auth:transactions.amountLabel')}</span>
              </div>
              <div className="w-1/12 flex-none text-center">
                <span className="text-2xl truncate">{t('auth:transactions.statusLabel')}</span>
              </div>
            </div>
            {transactions.map(trans => (
              <TransactionItem
                key={trans.id}
                transaction={trans}
                currencyCode={currencycode}
                apiUserFetch={apiUserFetch}
                onCancel={handleCancel}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}