const { urlFromSlug } = require('./urlFromSlug');
const { defaultLanguage } = require('../../env');


function generateLanguageUrl(url, languageCode, alternateLanguageBases = {}) {
  if (alternateLanguageBases && Array.isArray(alternateLanguageBases.edges)) {
    const basePage = alternateLanguageBases.edges.find(({ node }) => node.language.code === languageCode);

    if (basePage && basePage.node) {
      url = `${basePage.node.url}/${url}`;
    }
  }

  url = urlFromSlug(url);

  if (languageCode === defaultLanguage || url.startsWith(`/${languageCode}/`)) {
    return url;
  }

  return `/${languageCode}${url}`;
}

exports.generateLanguageUrl = generateLanguageUrl;