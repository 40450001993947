export default function sanitizeBonuses(bonuses) {
  return bonuses.map(bonus => {
    const {
      name, bonusId: id, text: description, form, image
    } = bonus;

    return {
      id: name === 'No Thanks' ? -1 : id,
      name,
      description,
      form,
      image: {
        url: image
      }
    };
  });
}