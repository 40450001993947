import React from 'react';


export default function FieldError({ error, className, ...props }) {
  return (
    <span
      className={`block mt-1 mb-2 text-red-700 font-bold text-base sm:text-lg ${className}`}
      {...props}
    >
      {error || '\u00a0'}
    </span>
  );
}