import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import qs from 'query-string';

import Seo from '../components/seo';
import StandardPage from './standardPage';
import { usePathParams } from '../hooks';
import { gameCategories, generateLanguageUrl } from '../util';
import MultiCategoryGameList from '../components/games/multiCategoryGameList';


const wrapperId = 'casino-main';

const Search = props => {
  const { location } = props;
  const { language } = useI18next();
  let [ page ] = usePathParams(location.pathname, 'search');

  if (page) {
    page = parseInt(page);

    if (isNaN(page) || page < 1) {
      page = 1;
    }
  }

  let searchQuery = props.location.search || '';

  if (searchQuery.indexOf('?q=') === 0) {
    searchQuery = qs.parse(searchQuery).q || '';
  }

  let linkUrlBase = generateLanguageUrl('search', language).substring(1);
  linkUrlBase = linkUrlBase.substring(0, linkUrlBase.length - 1);

  return (
    <StandardPage topPageData={false} {...props}>
      <Seo
        title="Search"
        description=""
      />
      <div className="centeredContentWrapper" id={wrapperId}>
        <h1 className="text-center">Search games</h1>
        <MultiCategoryGameList
          linkUrlBase={linkUrlBase}
          availableCategories={gameCategories}
          selectedCategory={'all'}
          currentPage={page || 1}
          gamesPerPage={20}
          scrollToOnPagination={wrapperId}
          initialSearchQuery={searchQuery}
          withSearch
          hideOnEmptySearch
          appendPaginationCategory={false}
        />
      </div>
    </StandardPage>
  );
};

export default Search;

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $traceSVGColor: String!) {
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
