import { graphql } from 'gatsby';

import StandardPage from '../standardPage';


export default StandardPage;

export const pageQuery = graphql`
  query($key: String!, $basePageKey: String!, $cid: String!, $language: String!, $headerImageMaxWidth: Int!, $contentImgMaxWidth: Int!, $imgQuality: Int!) {
    contentfulPage: contentfulBlog(contentful_id: {eq: $cid}) {
      title
      content {
        raw
        references {
          ... on ContentfulAsset {
            cid: contentful_id
            fixed(
              width: $contentImgMaxWidth,
              quality: $imgQuality
            ) {
              src
            }
            alt: title
            href: description
          }
        }
      }
      metaTitle
      metaDescription
      hero {
        fluid(
          maxWidth: $headerImageMaxWidth,
          quality: $imgQuality
        ) {
          ...GatsbyContentfulFluid
        }
        alt: title
      }
      tables {
        key
        body
      }
    }
    alternateLanguagePages: allContentfulBlog(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url: slug
          language {
            code
          }
        }
      }
    }
    alternateLanguageBases: allContentfulPage(filter: {key: {eq: $basePageKey}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
  }
`;
