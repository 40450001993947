const formatNumber = require('format-number');


const currencyDefinitions = {
  'USD': {
    refCode: 'ed9d35a5',
    friendlyName: 'US Dollar',
    symbol: '$',
    signInFront: true
  },
  'EUR': {
    refCode: '1eb1702e',
    friendlyName: 'Euro',
    symbol: '€',
    signInFront: false
  },
  'COP': {
    refCode: '974bd790',
    friendlyName: 'Peso colombiano',
    symbol: '$',
    signInFront: true
  },
  'PEN': {
    refCode: 'e5b163d8',
    friendlyName: 'Sol peruano',
    symbol: 'S/.',
    signInFront: true
  },
  'CLP': {
    refCode: '7cf46296',
    friendlyName: 'Peso chileno',
    symbol: '$',
    signInFront: true
  },
  'PYG': {
    refCode: 'da5b689c',
    friendlyName: 'Guaraní paraguayo',
    symbol: '₲',
    signInFront: false
  },
  'ARS': {
    refCode: 'af090689',
    friendlyName: 'Peso argentino',
    symbol: '$',
    signInFront: true
  },
  'AUD': {
    refCode: '873d53e2',
    friendlyName: 'Australian Dollar',
    symbol: '$',
    signInFront: true
  },
  'CAD': {
    refCode: '0ee97da5',
    friendlyName: 'Canadian Dollar',
    symbol: '$',
    signInFront: true
  },
  'BRL': {
    refCode: '8f4fca53',
    friendlyName: 'Brazilian Real',
    symbol: 'R$',
    signInFront: true
  }
};

const currencies = Object.keys(currencyDefinitions);
const currencyOptions = currencies.map(code => {
  return {
    label: currencyDefinitions[code].friendlyName,
    value: code
  };
});

const amountFormatter = formatNumber({ round: 2, padRight: 2 });

function formatAmount(amount) {
  return amountFormatter(amount);
}

function formatCurrency(amount, currencyCode) {
  if (currencyCode && currencyDefinitions.hasOwnProperty(currencyCode)) {
    const { symbol, signInFront } = currencyDefinitions[currencyCode];

    if (signInFront) {
      return `${symbol} ${amountFormatter(amount)}`;
    }

    return `${amountFormatter(amount)} ${symbol}`;
  }

  return amountFormatter(amount);
}

module.exports = {
  currencyDefinitions,
  currencies,
  currencyOptions,
  formatAmount,
  formatCurrency
};