import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { apiFetch } from '../util';
import api from './api/reducer';
import bonuses from './bonuses/reducer';
import cashierMethods from './cashierMethods/reducer';
import gameProviders from './gameProviders/reducer';
import pendingWithdrawals from './pendingWithdrawals/reducer';
import player from './player/reducer';
import playerBalance from './playerBalance/reducer';
import playerBonus from './playerBonus/reducer';
import playerTransactions from './playerTransactions/reducer';
import ui from './ui/reducer';


export function makeApiFetch(
  url,
  state,
  {
    method = 'GET',
    responseType,
    anonKey,
    playerKey,
    data,
    headers
  } = {}
) {
  if (anonKey !== false && !anonKey) {
    anonKey = state.player.anonKey;
  }

  if (playerKey !== false && !playerKey) {
    playerKey = state.player.playerKey;
  }

  return apiFetch(
    url,
    {
      apiRoot: state.api.root,
      apiKey: state.api.key,
      anonKey,
      playerKey,
      method,
      responseType,
      data,
      headers
    }
  );
}

export default function makeStore(initialState = undefined) {
  return createStore(
    combineReducers({
      api,
      bonuses,
      cashierMethods,
      gameProviders,
      pendingWithdrawals,
      player,
      playerBalance,
      playerBonus,
      playerTransactions,
      ui
    }),
    initialState,
    composeWithDevTools(
      applyMiddleware(thunk),
    )
  );
};
