import { useMemo } from 'react';


export default function useGamesByCategories(games, categories) {
  return useMemo(() => {
    let localCategories = typeof categories === 'string' ? [ categories ] : categories;

    if (!Array.isArray(games) || localCategories.includes('all')) {
      return games;
    }

    const getNew = localCategories.includes('new');
    const getJackpot = localCategories.includes('jackpot');

    return games.filter(g => {
      return (
        localCategories.includes(g.category) ||
        (getNew && g.isNew) ||
        (getJackpot && g.hasJackpot)
      );
    });
  }, [ games, categories ]);
}