import { makeApiFetch } from '..';
import { SET, START_LOADING, RESET } from './types';


export const setPendingWithdrawals = (transactionIds, amountStr, amount) => ({
  type: SET,
  transactionIds,
  amountStr,
  amount
});

export const startLoading = () => ({
  type: START_LOADING
});

export const reset = () => ({
  type: RESET
});

export const fetchPendingWithdrawals = () => (dispatch, getState) => {
  const { player, pendingWithdrawals } = getState();

  if (!player.playerKey || pendingWithdrawals.loading) {
    return;
  }

  dispatch(startLoading());

  return makeApiFetch(
    `player/transactions/pendingWithdrawals`,
    getState()
  )
    .then(resp => {
      if (
        !resp ||
        !resp.info ||
        !resp.info.success ||
        !resp.data ||
        !Array.isArray(resp.data.trans_ids)
      ) {
        dispatch(reset());

        return;
      }

      if (resp.info && resp.info.success) {
        dispatch(setPendingWithdrawals(
          resp.data.trans_ids,
          resp.data.pendingWithdrawalAmountStr || '',
          resp.data.pendingWithdrawalAmountVal || 0
        ));

        return;
      }

      return Promise.reject();
    })
    .catch(() => {
      dispatch(reset());
    });
};
