import React from 'react';

import { usePlayer } from '../hooks';
import { ChatbubblesSvg } from './svg';


function TawkToButton() {
  const { level } = usePlayer();
  let chatUrl = '602e53ef918aa26127401de5/1euqffol7';

  if (level > 2) {
    chatUrl = '62c3ea18b0d10b6f3e7ad62c/1g76kksqd';
  }

  return (
    <a
      aria-label="Open live chat support"
      title="Open live chat support"
      className="fixed right-4 bottom-4 xl:bottom-4 m-0 rounded-full bg-accent1 p-2 z-50"
      target="_blank"
      href={`https://tawk.to/chat/${chatUrl}`}
      rel="noopener noreferrer"
    >
      <ChatbubblesSvg hColor="text-white" className="w-10 h-10 xl:w-12 xl:h-12" />
    </a>
  );
}

export default TawkToButton;