import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby-plugin-react-i18next';

import Seo from '../components/seo';
import ContentfulPageContent from '../components/contentfulPageContent';
import Logo from '../components/logo';
import GameFrame from '../components/games/gameFrame';
import { useApiGames, useCache, usePathParams, useIsPlayer } from '../hooks';
import { allProviderKeys } from '../util';


function Game({ pageContext, location, data, slug = '', requestOptions, baseDomain, skipResize }) {
  const cache = useCache();
  const isPlayer = useIsPlayer();

  let { game, playerOnly, basePath = 'game' } = pageContext;

  if (!slug) {
    slug = game ? game.slug : '';
  }

  const [ gameSlugParam ] = usePathParams(location.pathname, basePath);

  if (!slug && gameSlugParam) {
    slug = gameSlugParam;
  }

  if (cache.has(`game:${slug}`)) {
    game = cache.get(`game:${slug}`);
  }

  const apiGames = useApiGames('all', allProviderKeys, !!game);

  let metaTitleText = '';
  let metaDescText = '';
  let body = null;
  let wrapperStyle = undefined;

  if (playerOnly && !isPlayer) {
    metaTitleText = 'Registered players only';
    body = (
      <div className="paper my-3">
        <h2 className="text-center">Player game only</h2>
        <p className="text-center text-green-600">Please signup/login to access this game.</p>
      </div>
    );
  } else {
    if (game) {
      //const onMobile = isMobileBrowser();
      let loadPlayUrls = true;

      // if (
      //   isBrowser() &&
      //   game.category !== 'livecasino' &&
      //   game.equivalentGame &&
      //   (
      //     (game.isMobile && !onMobile) ||
      //     (!game.isMobile && onMobile)
      //   )
      // ) {
      //   navigate(`/${basePath}/${game.equivalentGame}/`, { replace: true });
      //   loadPlayUrls = false;
      // }

      metaTitleText = `${game.title}${game.isMobile ? ' mobile' : ''} by ${game.provider}`;
      metaDescText = `${metaTitleText} - ${game.description.en}`
      wrapperStyle = {
        backgroundImage: `url(${game.images.background.url})`
      };

      body = (
        <GameFrame
          game={game}
          siteTitle={data.site.siteMetadata.title}
          loadPlayUrls={loadPlayUrls}
          requestOptions={requestOptions}
          baseDomain={baseDomain}
          skipResize={skipResize}
          noTitle={!!data.contentfulGame}
        />
      );
    } else {
      if (apiGames === undefined) {
        metaTitleText = 'Loading game data...';

        body = (
          <p className="text-center text-2xl sm:text-4xl mb-40">Loading game data...</p>
        );
      } else if (apiGames === null || !game) {
        metaTitleText = 'Game not found';

        body = (
          <>
            <h1 className="text-center">Game not found</h1>
            <p className="text-center text-2xl sm:text-4xl mb-40">The game you're looking for doesn't exist or was removed.</p>
          </>
        );
      }
    }
  }

  if (data.contentfulGame) {
    metaTitleText = data.contentfulGame.metaTitle || `${metaTitleText} - ${data.site.siteMetadata.title} games`;
    metaDescText = data.contentfulGame.metaDescription || metaDescText;
  }

  return (
    <div className="site-width-boundary mx-auto">
      <Seo
        title={metaTitleText}
        description={metaDescText}
      />
      <div className="bg-cover bg-no-repeat bg-center" style={wrapperStyle}>
        <div className="py-10 max-w-4xl xl:py-12 xl:max-w-6xl m-auto">
          <div className="flex items-center justify-center">
            <Link
              to="/"
              className="block text-logo micro:w-32 pl-6 w-40 xl:w-50 xl:mb-6"
            >
              <Logo />
            </Link>
          </div>
          {body}
        </div>
      </div>
      {data.contentfulGame && (
        <div className="py-10 xl:py-22 centeredContentWrapper">
          <h1 className="text-left">{data.contentfulGame.title}</h1>
          <ContentfulPageContent data={data.contentfulGame} />
        </div>
      )}
    </div>
  );
}

export default Game;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
