import React from 'react';
import cx from 'clsx';


function SvgIcon({
  color = '', hColor = 'text-accent1',
  lg, sm, vHeight, vWidth, className, children, transitionOff = false,
  r90, r180, rn90, ...rest
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${vWidth} ${vHeight}`}
      className={cx(
        'fill-current h-auto',
        color,
        `hover:${hColor}`,
        className,
        {
          'transition-colors duration-300 ease-in-out': !transitionOff,
          'w-8': !sm && !lg,
          'w-5': sm,
          'w-12': lg,
          'transform rotate-90': r90,
          'transform rotate-180': r180,
          'transform -rotate-90': rn90
        }
      )}
      {...rest}
    >
      {children}
    </svg>
  );
}

export function SearchSvg(props) {
  return (
    <SvgIcon vWidth={19} vHeight={19} {...props}>
      <path d="M2.375,8.313A5.88,5.88,0,0,1,8.313,2.375,5.88,5.88,0,0,1,14.25,8.313,5.88,5.88,0,0,1,8.313,14.25,5.88,5.88,0,0,1,2.375,8.313ZM16.981,18.644a1.176,1.176,0,0,0,1.662-1.662L14.963,13.3a8.131,8.131,0,0,0,1.662-4.988A8.261,8.261,0,0,0,8.313,0,8.261,8.261,0,0,0,0,8.313a8.261,8.261,0,0,0,8.313,8.313A8.131,8.131,0,0,0,13.3,14.963Z" />
    </SvgIcon>
  );
}

export function RefreshSvg(props) {
  return (
    <SvgIcon vWidth={17.52} vHeight={18.868} {...props}>
      <g>
        <g transform="translate(0)">
          <path d="M32.565,0a.674.674,0,0,0-.674.674V3.135a8.743,8.743,0,0,0-14.825,6.3.674.674,0,1,0,1.348,0,7.412,7.412,0,0,1,12.88-5l-2.985,1a.675.675,0,0,0,.426,1.28l4.043-1.348a.674.674,0,0,0,.461-.641V.674A.674.674,0,0,0,32.565,0Z" transform="translate(-17.067)" />
        </g>
      </g>
      <g transform="translate(1.348 8.76)">
        <g transform="translate(0)">
          <path d="M66.7,221.867a.674.674,0,0,0-.674.674,7.412,7.412,0,0,1-12.88,5l2.985-1a.675.675,0,1,0-.426-1.28l-4.043,1.348a.674.674,0,0,0-.461.641V231.3a.674.674,0,1,0,1.348,0v-2.462a8.743,8.743,0,0,0,14.825-6.3A.674.674,0,0,0,66.7,221.867Z" transform="translate(-51.198 -221.867)" />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ChatbubblesSvg(props) {
  return (
    <SvgIcon vWidth={512} vHeight={512} {...props}>
      <path d="M60.44,389.17c0,.07,0,.2-.08.38C60.39,389.43,60.41,389.3,60.44,389.17Z"/>
      <path d="M439.9,405.6a26.77,26.77,0,0,1-9.59-2l-56.78-20.13-.42-.17a9.88,9.88,0,0,0-3.91-.76,10.32,10.32,0,0,0-3.62.66c-1.38.52-13.81,5.19-26.85,8.77-7.07,1.94-31.68,8.27-51.43,8.27-50.48,0-97.68-19.4-132.89-54.63A183.38,183.38,0,0,1,100.3,215.1a175.9,175.9,0,0,1,4.06-37.58c8.79-40.62,32.07-77.57,65.55-104A194.76,194.76,0,0,1,290.3,32c52.21,0,100.86,20,137,56.18,34.16,34.27,52.88,79.33,52.73,126.87a177.86,177.86,0,0,1-30.3,99.15l-.19.28-.74,1c-.17.23-.34.45-.5.68l-.15.27a21.63,21.63,0,0,0-1.08,2.09l15.74,55.94a26.42,26.42,0,0,1,1.12,7.11A24,24,0,0,1,439.9,405.6Z"/>
      <path d="M299.87,425.39a15.74,15.74,0,0,0-10.29-8.1c-5.78-1.53-12.52-1.27-17.67-1.65a201.78,201.78,0,0,1-128.82-58.75A199.21,199.21,0,0,1,86.4,244.16C85,234.42,85,232,85,232a16,16,0,0,0-28-10.58h0S49.12,230,45.4,238.61a162.09,162.09,0,0,0,11,150.06C59,393,59,395,58.42,399.5c-2.73,14.11-7.51,39-10,51.91a24,24,0,0,0,8,22.92l.46.39A24.34,24.34,0,0,0,72,480a23.42,23.42,0,0,0,9-1.79l53.51-20.65a8.05,8.05,0,0,1,5.72,0c21.07,7.84,43,12,63.78,12a176,176,0,0,0,74.91-16.66c5.46-2.56,14-5.34,19-11.12A15,15,0,0,0,299.87,425.39Z"/>
    </SvgIcon>
  );
}

export function ChevronRightSvg(props) {
  return (
    <SvgIcon vWidth={52.538} vHeight={88.911} {...props}>
      <path d="M44.456,0,36.373,8.083l30.6,30.6V50.229l-30.6,30.6,8.083,8.083L88.911,44.456Z" transform="translate(-36.373)" />
    </SvgIcon>
  );
}

export function PlaySvg(props) {
  return (
    <SvgIcon vWidth={512} vHeight={512} {...props}>
      <path d="M346.541,221.958l-121.198-87.091c-11.977-8.591-27.338-11.977-39.315-5.077c-11.977,5.077-18.746,18.747-18.746,34.107
        v184.335c0,15.361,6.769,27.338,18.746,34.107c5.077,1.692,10.284,3.385,15.361,3.385c8.592,0,17.054-3.385,23.953-10.285
        l121.198-87.092c11.977-8.591,18.746-20.439,18.746-34.107C365.286,242.397,358.518,230.42,346.541,221.958z M326.102,262.835
        l-121.198,87.091c-1.692,0-3.385,1.692-3.385,1.692s0-1.692,0-3.385V163.899c0-1.692,0-3.385,0-3.385
        c1.692,0,1.692,1.692,5.077,3.385l121.197,87.091c1.692,0,3.385,3.385,3.385,5.077S329.487,261.141,326.102,262.835z"/>
      <path d="M256.065,0C114.43,0,0,114.298,0,256.065S114.298,512.13,256.065,512.13S512.13,397.832,512.13,256.065
        S397.702,0,256.065,0z M256.065,477.892c-122.891,0-221.828-98.937-221.828-221.828S133.175,34.236,256.065,34.236
        s221.828,98.937,221.828,221.828S378.956,477.892,256.065,477.892z"/>
    </SvgIcon>
  );
}

export function ClockSvg(props) {
  return (
    <SvgIcon vWidth={23} vHeight={23} {...props}>
      <path d="M3.459,3.459A10.468,10.468,0,0,1,11.531,0,10.468,10.468,0,0,1,19.6,3.459a10.468,10.468,0,0,1,3.459,8.072A10.468,10.468,0,0,1,19.6,19.6a10.468,10.468,0,0,1-8.072,3.459A10.468,10.468,0,0,1,3.459,19.6,11.508,11.508,0,0,1,0,11.531,10.468,10.468,0,0,1,3.459,3.459ZM16.72,16.72l1.345-1.345-4.8-4.8-1.73-7.687H9.609v8.648a1.745,1.745,0,0,0,.577,1.345.671.671,0,0,0,.384.192Z" />
    </SvgIcon>
  );
}

export function ArrowRightSvg(props) {
  return (
    <SvgIcon vWidth={38} vHeight={27} color="text-trueGray-200" {...props}>
      <path d="M25.443,0,23.791,1.985S30.73,9.132,33.7,11.912c-10.434-.111-33.7,0-33.7,0v2.382s23.277-.087,33.7,0c-2.974,3.176-9.913,10.324-9.913,10.324L25.443,27,38,13.1Z"/>
    </SvgIcon>
  );
}

export function MoneySvg(props) {
  return (
    <SvgIcon vWidth={24} vHeight={24} color="text-trueGray-200" {...props}>
      <path fill="currentColor" d="M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 0 0,1 19,8H9M1,10H3V20H19V22H1V10Z" />
    </SvgIcon>
  );
}