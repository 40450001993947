import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ContentfulRichText from './contentfulRichText';


const wrapperClasses = 'ml-0';

export default function ContentfulPageContent({ data }) {
  const { t } = useTranslation();
  const [ showContent, setShowContent ] = useState(false);
  const { content, summary, tables } = data || {};
  const hasContent = content && !!content.raw;

  if (summary && summary.raw) {
    return (
      <>
        <div className={wrapperClasses}>
          <ContentfulRichText raw={summary.raw} />
          {hasContent && (
            <div
              className={`mt-2 ${showContent ? '' : 'overflow-hidden h-px'}`}
            >
              <ContentfulRichText raw={content.raw} references={content.references} tables={tables} />
            </div>
          )}
        </div>
        {hasContent && (
          <div className="flex justify-center py-2">
            <button className="button secondary" onClick={() => setShowContent(!showContent)}>{t(showContent ? 'collapseContentButton' : 'expandContentButton')}</button>
          </div>
        )}
      </>
    );
  }

  if (hasContent) {
    return (
      <div className={wrapperClasses}>
        <ContentfulRichText raw={content.raw} references={content.references} tables={tables} />
      </div>
    );
  }

  return null;
};