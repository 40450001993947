import { useMemo } from 'react';


export default function useApiGamesFilter(filterFunc, games) {
  return useMemo(() => {
    if (typeof filterFunc !== 'function' || !Array.isArray(games)) {
      return games;
    }

    return filterFunc(games.slice());
  }, [ filterFunc, games ]);
}