import React, { useCallback, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import qs from 'query-string';
import useIsMounted from 'ismounted';

import StandardPage from './standardPage';
import { useIsPlayer, useApiFetch } from '../hooks';


const ConfirmSignUp = props => {
  const { t } = useTranslation();
  const [ requested, setRequested ] = useState(false);
  const { apiUserFetch } = useApiFetch();
  const isMounted = useIsMounted();
  const [ signUpComplete, setSignUpComplete ] = useState(undefined);

  const handleResponse = useCallback(response => {
    if (!isMounted.current) {
      return;
    }

    if (!response || !response.info) {
      return;
    }

    setSignUpComplete(!!response.info.success);
  }, [ isMounted ]);

  const playerLoggedIn = useIsPlayer();
  const token = qs.parse(props.location.search).token || '';

  useEffect(() => {
    if (requested || signUpComplete !== undefined || !token || playerLoggedIn) {
      return;
    }

    setRequested(true);

    apiUserFetch('register/complete/token', {
      data: {
        token
      },
      method: 'POST'
    })
      .then(handleResponse)
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setSignUpComplete(false);
      });
  }, [
    playerLoggedIn, token, apiUserFetch, handleResponse,
    isMounted, signUpComplete, setSignUpComplete, requested
  ]);

  return (
    <StandardPage {...props}>
      <div className="centeredContentWrapper">
        {
          playerLoggedIn
            ?
              <p className="text-center text-red-600">{t('auth:signUpConfirmation.alreadyLoggedIn')}</p>
            :
              (
                !token
                  ?
                    <p className="text-center text-red-600">{t('auth:signUpConfirmation.noToken')}</p>
                  :
                    <>
                      {
                        signUpComplete === undefined &&
                        <p className="text-center">{t('auth:signUpConfirmation.inProgress')}</p>
                      }
                      {
                        signUpComplete === false &&
                        <p className="text-center text-red-600">{t('auth:signUpConfirmation.tokenFailed')}</p>
                      }
                      {
                        signUpComplete === true &&
                        <>
                          <p className="text-center">{t('auth:signUpConfirmation.complete')}</p>
                          <div className="flex justify-center">
                            <Link
                              to="/login/"
                              className="button primary"
                            >
                              {t('auth:loginRedirectButton')}
                            </Link>
                          </div>
                        </>
                      }
                    </>
              )
        }
      </div>
    </StandardPage>
  );
};

export default ConfirmSignUp;

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $traceSVGColor: String!, $key: String!, $language: String!) {
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contentfulPage(language: {code: {eq: $language}}, key: {eq: $key}) {
      title
      metaTitle
      metaDescription
    }
    alternateLanguagePages: allContentfulPage(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
  }
`;
