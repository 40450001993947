import { useMemo, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { apiFetch } from '../util';
import { useIsomorphicLayoutEffect } from '../hooks';


function selector(s) {
  const { key: apiKey, root: apiRoot } = s.api;
  const { anonKey, playerKey } = s.player;

  return {
    apiRoot,
    apiKey,
    anonKey,
    playerKey
  };
}

export default function useApiFetch() {
  const {
    apiRoot, apiKey, anonKey, playerKey
  } = useSelector(selector, shallowEqual);
  const params = useRef({ apiRoot, apiKey, anonKey, playerKey });

  useIsomorphicLayoutEffect(() => {
    params.current = { apiRoot, apiKey, anonKey, playerKey };
  }, [ apiRoot, apiKey, anonKey, playerKey ]);

  return useMemo(() => {
    const localApiFetch = (url, options = {}) => {
      return apiFetch(url, { ...params.current, ...options });
    };

    return {
      apiFetch: localApiFetch,
      apiUserFetch: (url, options = {}) => {
        const { anonKey } = params.current;
        
        if (!anonKey) {
          return new Promise(res => {
            let interval = setInterval(() => {
              const { anonKey: localAnonKey } = params.current;

              if (localAnonKey) {
                clearInterval(interval);
                res(localApiFetch(url, options));
              }
            }, 100)
          });
        }

        return localApiFetch(url, options);
      }
    };
  // eslint-disable-next-line
  }, [ apiRoot, apiKey, anonKey, playerKey ]);
}