import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import PageTemplate from '../templates/page';
import Seo from '../components/seo';


export default function NotFound(props) {
  const { t } = useTranslation();

  return (
    <PageTemplate {...props}>
      <Seo title={t('404MetaTitle')} description="" />
      <h1 className="text-center mt-10 sm:mt-20">{t('404Title')}</h1>
      <p className="text-center text-2xl">{t('404Message')}</p>
    </PageTemplate>
  );
};

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $traceSVGColor: String!) {
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
