import React from 'react';
import Img from 'gatsby-image';

import PageLayout from '../components/pageLayout';
import PaddedContainer from '../components/paddedContainer';
import ContentfulPage from '../components/contentfulPage';
import AffTable from '../components/affTable';


export default function StandardPage({ data, children, topPageData = true, bottomPageData = false, hideAffTable = false }) {
  const { header, contentfulPage, alternateLanguagePages, alternateLanguageBases, hideFoooter } = data;
  let headerImg = null;

  if (header && header.childImageSharp) {
    headerImg = <Img fluid={header.childImageSharp.fluid} alt={header.alt || ''} />;
  } else if (contentfulPage && contentfulPage.hero) {
    headerImg = <Img fluid={contentfulPage.hero.fluid} alt={contentfulPage.hero.alt || ''} />;
  }

  return (
    <PageLayout hideFoooter={hideFoooter}>
      {headerImg}
      <PaddedContainer>
        {topPageData && contentfulPage && <ContentfulPage data={contentfulPage} alternateLanguagePages={alternateLanguagePages} alternateLanguageBases={alternateLanguageBases} />}
        {!hideAffTable && <AffTable />}
        {children}
        {bottomPageData && contentfulPage && <ContentfulPage data={contentfulPage} alternateLanguagePages={alternateLanguagePages} alternateLanguageBases={alternateLanguageBases} />}
      </PaddedContainer>
    </PageLayout>
  );
};