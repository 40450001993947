import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import Img from 'gatsby-image';

import { showMenu, hideMenu } from '../store/ui/actions';
import { noop, openCashier } from '../util';
import { useIsPlayer } from '../hooks';
import PlayerBalanceStatus from './playerBalanceStatus';
import ApgSeal from './apgSeal';
import Pap from './pap';
import Logo from './logo';
import Hamburger from './hamburger';
import En from './icons/en';
import Es from './icons/es';
import De from './icons/de';
import Pt from './icons/pt';
import Menu from './menu';
import Carousel from './carousel';
import CookiesTooltip from './cookiesTooltip';
import './pageLayout.css';


const langIconClass = 'inline-block w-5 2xl:w-6';
const langs = [
  {
    to: {
      en: '/'
    },
    language: 'en',
    element: <span className={langIconClass}><En /></span>
  },
  {
    to: {
      en: '/'
    },
    language: 'es',
    element: <span className={langIconClass}><Es /></span>
  },
  {
    to: {
      en: '/'
    },
    language: 'de',
    element: <span className={langIconClass}><De /></span>
  },
  {
    to: {
      en: '/'
    },
    language: 'pt',
    element: <span className={langIconClass}><Pt /></span>
  }
];

function menuStateSelector(s) {
  return s.ui.showMenu;
}

function PageLayout({ children, hideFoooter }) {
  const isPlayer = useIsPlayer();
  const { t, i18n: { language } } = useTranslation();
  const {
    site: { siteMetadata: { shortTitle, menus: { main } } },
    legalAgeLogo: { publicURL: over18Img }, gameProviderLogos, paymentProviderLogos,
    askGamblers, lcb, bestBtc, playcasino, mrgamble
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            shortTitle
            menus {
              main {
                key
                to {
                  en
                  de
                  es
                  pt
                }
                partiallyActive
              }
              mobile {
                key
                to {
                  en
                  de
                  es
                  pt
                }
              }
            }
          }
        }
        legalAgeLogo: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "18-over.svg"}) {
          publicURL
        }
        askGamblers: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "askgamblers.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        lcb: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "lcb.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bestBtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "best-btc.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        playcasino: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "777-playcasino.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mrgamble: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "MrGamble-Seal.png"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gameProviderLogos: allFile(filter: {sourceInstanceName: {eq: "images"}, relativePath: {glob: "game-providers/*"}}) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        paymentProviderLogos: allFile(filter: {sourceInstanceName: {eq: "images"}, relativePath: {glob: "payment-providers/*"}}) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );
  const showMenuState = useSelector(menuStateSelector);
  const dispatch = useDispatch();
  const doMenuHide = () => dispatch(hideMenu());

  return (
    <>
      <div className="site-width-boundary mx-auto">
        <div className="w-full flex flex-nowrap">
          <div className={`sidebar pb-5${showMenuState ? ' show' : ''}`}>
            <div className="mobile:flex mobile:justify-between mobile:items-top">
              <Link
                to="/"
                className="block xl:w-3/5 xl:mb-6 xl:mx-auto text-logo mobile:text-primary micro:w-32 micro:pl-4 w-52 pl-6"
                onClick={doMenuHide}
              >
                <Logo className="hidden mobile:block" mobile />
                <Logo className="block mobile:hidden" />
              </Link>
              <span
                className="text-6xl hidden mobile:block pb-1 micro:px-2 px-3"
                aria-label={t('closeMenu')}
                onClick={doMenuHide}
                onKeyUp={noop}
                role="button"
                tabIndex="0"
              >
                &times;
              </span>
            </div>
            <Menu
              items={langs}
              language={language}
              onItemClick={doMenuHide}
              className="list-none w-1/2 my-0 mx-auto p-0 pl-4 flex flex-wrap justify-evenly"
              itemClassName="py-0.5 px-1 sm:px-2 sm:py-1 text-sm sm:text-base"
            />
            <div key="anon" className="flex flex-row justify-evenly menu login lg:ml-12 mb-5 mt-3 flex-wrap">
              <Link
                to="https://www.luckystar.io/blog/welcome-to-lucky-star?a_aid=66f2ee9966220&a_bid=e7658fe3"
                className="uppercase text-2xl 2xl:text-3xl p-2 m-1 font-bold"
                activeClassName="active"
                onClick={doMenuHide}
              >
                {t('auth:signUp.button')}
              </Link>
            </div>
            <Menu
              items={main}
              language={language}
              onItemClick={doMenuHide}
              className="menu main list-none mx-0 ml-24 2xl:ml-28 mr-4 2xl:mr-8 mb-12 2xl:mb-16 mobile:mx-auto"
              itemClassName="uppercase text-xl 2xl:text-2xl my-3.5 font-bold mobile:text-center"
            />
          </div>
          <div className="content flex-grow pb-5">
            <div className="justify-between items-center hidden mobile:flex">
              <Link className="m-4 sm:mx-10 w-full" style={{ maxWidth: '6rem' }} to="/">
                <Logo mobile />
              </Link>
              <span
                className="m-3 md:m-6 p-3 md:p-4"
                aria-label={t('openMenu')}
                onClick={() => dispatch(showMenu())}
                onKeyUp={noop}
                role="button"
                tabindex="0"
              >
                <Hamburger />
              </span>
            </div>
            {children}
          </div>
        </div>
        {!hideFoooter && (
          <div className="w-full py-2 bg-secondary-bg relative z-10">
            <div className="mx-auto px-4 sm:px-8 w-full max-w-screen-xl">
              <p className="text-center text-xs sm:text-sm">© 2024 {shortTitle}. {t('footer.allRightsReserved')}</p>
              <Pap />
            </div>
            <div className="absolute top-full h-16 bg-secondary-bg w-full xl:hidden"></div>
          </div>
        )}
      </div>
      <CookiesTooltip />
    </>
  );
}

export default PageLayout;
