import { useMemo, useState, useEffect } from 'react';
import * as Yup from 'yup';


let externalScriptIndex = 0;

function returnEmptyString() {
	return '';
}

function stringValidatorSchema({ type, minLength, maxLength, length }) {
  let fieldSchema = Yup.string();

  if (typeof length === 'number' && length) {
    fieldSchema = fieldSchema.length(length);
  } else {
    if (typeof minLength === 'number') {
      fieldSchema = fieldSchema.min(minLength);
    }

    if (typeof maxLength === 'number') {
      fieldSchema = fieldSchema.max(maxLength);
    }
  }

  if (type === 'email') {
    fieldSchema = fieldSchema.email();
  }

  return fieldSchema;
}

export default function useApiYup(fields) {
  const [ encriptionScripts, setEncriptionScripts ] = useState([]);

  useEffect(() => {
    return () => {
      for (const { id, method } of encriptionScripts) {
        const el = document.getElementById(id);

        if (el) {
          el.parentElement.removeChild(el);
        }

        window[method] = returnEmptyString;
      }
    };
  }, [ encriptionScripts ]);

  return useMemo(() => {
    const schemaShape = {};
    const hiddenFieldData = {};
    const numberFields = [];
    const fieldEncriptions = [];

    if (Array.isArray(fields) && fields.length !== 0) {
      for (const field of fields) {
        let fieldSchema;

        const {
          fieldType, type, code, name, defaultValue, required,
          min, max, encryption
        } = field;

        switch (fieldType) {
          case 'string':
            fieldSchema = stringValidatorSchema(field);
            break;

          case 'number':
            if (type === 'string') {
              fieldSchema = stringValidatorSchema(field);
            } else {
              fieldSchema = Yup.number();

              if (typeof min === 'number') {
                fieldSchema = fieldSchema.min(min);
              }

              if (typeof max === 'number') {
                fieldSchema = fieldSchema.max(max);
              }

              if (code.indexOf('amount') !== -1) {
                fieldSchema = fieldSchema.meta({
                  currency: true
                });
              }

              if (type === 'number') {
                numberFields.push(code);
              }
            }

            break;

          case 'dropdown':
            const { options } = field;
            const selectOptions = [];

            for (const option of Object.keys(options)) {
              selectOptions.push({
                value: option,
                label: options[option]
              });
            }

            fieldSchema = Yup.mixed()
              .oneOf(selectOptions, null)
              .meta({
                select: true
              });

            break;

          case 'hidden':
            if (encryption && code.indexOf('enc') === 0) {
              const lowercaseTargetField = code.substr(3).toLowerCase();
              let valueField;

              for (const { code } of fields) {
                if (code.toLowerCase() === lowercaseTargetField) {
                  valueField = code;
                }
              }

              if (valueField) {
                const id = `formEncScript-${externalScriptIndex++}`;
                const script = document.createElement('script');

                script.id = id;
                script.src = encryption.src;

                document.head.appendChild(script);

                fieldEncriptions.push({
                  id,
                  valueField,
                  targetField: code,
                  method: encryption.function
                });
              }
            } else {
              hiddenFieldData[code] = defaultValue;
            }

            break;

          default:
            console.warn('unhandled field', code, field);
        }

        if (fieldSchema) {
          if (name) {
            fieldSchema = fieldSchema.label(name);
          }

          if (defaultValue !== undefined || defaultValue !== null) {
            fieldSchema = fieldSchema.default(defaultValue);
          }

          if (required) {
            fieldSchema = fieldSchema.required();
          }

          schemaShape[code] = fieldSchema;
        }
      }
    }

    setEncriptionScripts(fieldEncriptions);

    return {
      validationSchema: Yup.object().shape(schemaShape),
      postProccessing: data => {
        for (const { targetField, valueField, method } of fieldEncriptions) {
          data[targetField] = window[method](data[valueField]);
        }

        for (const { code, fieldType } of fields) {
          if (fieldType === 'dropdown') {
            const selectedOption = data[code];

            if (selectedOption && selectedOption.value) {
              data[code] = selectedOption.value;
            }
          }
        }

        for (const code of numberFields) {
          if (data.hasOwnProperty(code)) {
            const num = parseFloat(data[code], 10);

            if (!isNaN(num)) {
              data[code] = num;
            }
          }
        }

        for (const hFieldCode of Object.keys(hiddenFieldData)) {
          data[hFieldCode] = hiddenFieldData[hFieldCode];
        }

        return data;
      }
    };
  }, [ fields ]);
}