import { set as setCookie, remove as removeCookie } from 'js-cookie';

import { makeApiFetch } from '..';
import { SET_PLAYER_DATA, RESET_PLAYER_DATA } from './types';
import { fetchPlayerProviders, reset as resetGameProviders } from '../gameProviders/actions';
import { fetchPendingWithdrawals } from '../pendingWithdrawals/actions';
import { fetchPlayerBalance } from '../playerBalance/actions';
import { fetchPlayerBonus } from '../playerBonus/actions';


export const setPlayerData = data => {
  const newData = { ...data };

  if (data.level && typeof data.level === 'string') {
    newData.level = parseInt(data.level);
  }

  return {
    type: SET_PLAYER_DATA,
    data: newData
  };
};

export const resetPlayerData = () => ({
  type: RESET_PLAYER_DATA
});

export const resetPlayer = () => dispatch => {
  dispatch(resetPlayerData());
  dispatch(resetGameProviders());
};

export const refreshPlayerMeta = () => dispatch => {
  dispatch(fetchPendingWithdrawals());
  dispatch(fetchPlayerProviders());
  dispatch(fetchPlayerBalance());
  dispatch(fetchPlayerBonus());
};

export const fetchAnonKey = () => (dispatch, getState) => {
  return makeApiFetch(
    'anon',
    getState(),
    {
      anonKey: false,
      playerKey: false
    }
  )
    .then(resp => {
      if (resp.info && resp.info.success) {
        const { sessionid: anonKey } = resp.data;

        setCookie('anonKey', anonKey);

        dispatch(setPlayerData({
          anonKey
        }));
      }
    });
};

export const checkAnonKey = anonKey => (dispatch, getState) => {
  return makeApiFetch(
    'checkAnonSession',
    getState(),
    {
      anonKey,
      playerKey: false
    }
  )
    .then(resp => {
      if (resp.info && resp.info.success) {
        return dispatch(setPlayerData({
          anonKey
        }));
      }

      return Promise.reject();
    })
    .catch(() => {
      removeCookie('anonKey');
      dispatch(fetchAnonKey());
    });
};

export const fetchPlayer = (anonKey, playerKey) => (dispatch, getState) => {
  return makeApiFetch(
    'player',
    getState(),
    {
      anonKey,
      playerKey
    }
  )
    .then(resp => {
      if (resp.info && resp.info.success) {
        const {
          sessionid: playerKey,
          username,
          address,
          birthdate,
          countrycode,
          currencycode,
          level,
          email,
          fname,
          lname,
          newsletter,
          id,
          city,
          phone,
          mobile
        } = resp.data;

        dispatch(setPlayerData({
          anonKey,
          playerKey,
          username,
          address,
          birthdate,
          countrycode,
          currencycode,
          level,
          email,
          fname,
          lname,
          newsletter,
          id,
          city,
          phone,
          mobile
        }));

        dispatch(refreshPlayerMeta());

        return;
      }

      return Promise.reject();
    })
    .catch(() => {
      removeCookie('anonKey');
      removeCookie('playerKey');
      dispatch(resetGameProviders());
      dispatch(fetchAnonKey());
    });
};
