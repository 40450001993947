import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Seo from '../components/seo';
import AccessControl from '../components/accessControl';
import CashierComponent from '../components/cashier';
import StandardPage from './standardPage';


function closeWindow() {
  window.close();
}

function receiveMessage(ev) {
  let json;

  if (ev && typeof ev.data === 'string') {
    try {
      json = JSON.parse(ev.data);
    } catch (ex) {}
  }

  if (json && json.action === 'close') {
    closeWindow();
  }
}

const Cashier = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage, false);
    }
  }, []);

  return (
    <StandardPage {...props}>
      <div className="w-full max-w-6xl mx-auto flex flex-col p-4">
        <Seo title={t('cashier:checkingCredentialsMetaTitle')} />
        <AccessControl>
          <Seo title={t('cashier:title')} />
          <div className="bg-accent flex-none flex items-center py-2 relative">
            <div className="flex-1 text-2xl sm:text-4xl text-white text-center">
              {t('cashier:title')}
            </div>
          </div>
          <Router className="flex-1 pt-4">
            <CashierComponent path="/cashier/:methodType/:methodCode/*" />
            <CashierComponent path="/cashier/:methodType/" />
            <CashierComponent default />
          </Router>
        </AccessControl>
      </div>
    </StandardPage>
  );
};

export default Cashier;

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $traceSVGColor: String!) {
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
