import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';

import './contentListItem.css';


export default function ContentListItem({ title, content, url, detailsButtonText, image = null }) {
  return (
    <article className="ml-0 mb-14 contentListItem">
      <h2 className="title pl-4 pt-6"><Link to={url}>{title}</Link></h2>
      <div className="mb-2 md:mb-4">
        {image}
      </div>
      <div>
        <div>
          {content}
        </div>
        {url && detailsButtonText && (
          <div className="text-right px-10 pt-3">
            <Link
              to={url}
              className="detailsButton pb-4 uppercase font-bold text-lg text-accent1 hover:text-accent1-alt"
            >
              {detailsButtonText}
            </Link>
          </div>
        )}
      </div>
    </article>
  );
};