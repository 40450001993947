const React = require('react');
const { get: getCookie } = require('js-cookie');

const RootWrapper = require('./src/components/rootWrapper').default;
const Layout = require('./src/layouts/index').default;
const makeStore = require('./src/store').default;
const env = require('./env');


const store = makeStore({
  api: {
    root: env.api.root,
    key: env.api.key,
    imageRoot: env.api.imageRoot,
    pap: {}
  }
});

const anonKey = getCookie('anonKey');
const playerKey = getCookie('playerKey');

exports.wrapRootElement = ({ element }) => {
  return (
    <RootWrapper store={store} element={element} anonKey={anonKey} playerKey={playerKey} />
  );
};

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.shouldUpdateScroll = ({
  routerProps: { location: { state } }
}) => {
  const { scrollTo } = state || {};

  if (scrollTo !== undefined) {
    return scrollTo;
  }

  return true;
};