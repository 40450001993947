import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

import { generateLanguageUrl } from '../util';


export default function Seo({
  title,
  description,
  meta = [],
  canonical,
  alternateLanguagePages,
  alternateLanguageBases,
  children
}) {
  const props = {
    meta: [
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ].concat(meta),
    titleTemplate: `%s`
  };

  if (typeof title === 'string') {
    props.title = title;
  }

  if (alternateLanguagePages && alternateLanguagePages.edges) {
    alternateLanguagePages = alternateLanguagePages.edges.map(({ node: { url, language: { code } } }) => ({
      path: url !== 'index' ? url : '',
      language: code
    }));
  }

  const { language, originalPath, defaultLanguage, siteUrl } = useContext(I18nextContext);
  let defaultLangPath = '';
  let alternatePages = null;

  if (Array.isArray(alternateLanguagePages)) {
    alternatePages = alternateLanguagePages.map(({ path, language: altLanguage }) => {
      if (altLanguage === defaultLanguage) {
        defaultLangPath = path;
      }

      return (
        <link rel="alternate" key={altLanguage} href={siteUrl + generateLanguageUrl(path, altLanguage, alternateLanguageBases)} hrefLang={altLanguage} />
      );
    });
  }

  return (
    <Helmet
      {...props}
    >
      <html lang={language} />
      <link rel="canonical" href={siteUrl + (canonical || generateLanguageUrl(originalPath, language))} />
      {defaultLangPath && (
        <link rel="alternate" href={siteUrl + generateLanguageUrl(defaultLangPath, defaultLanguage, alternateLanguageBases)} hrefLang="x-default" />
      )}
      {alternatePages}
      {children}
    </Helmet>
  );
};
