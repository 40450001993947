import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';

import { useApiFetch } from '../hooks';


const defaultState = {
  campaign_id: null,
  click_tracker: null,
  click_tracker_url: null,
  impression_tracker: null,
  impression_tracker_url: null
};

function papSelector(s) {
  return s.api.pap;
}

function Pap() {
  const { apiUserFetch } = useApiFetch();
  const { aAid, aBid } = useSelector(papSelector);
  const [ papConfig, setPapConfig ] = useState(defaultState);

  useEffect(() => {
    if (!aAid || !aBid) {
      return;
    }

    let trackingInitInterval;

    apiUserFetch('skinConfig/pap')
      .then(response => {
        const { data, info } = response;

        if (!info || !info.success || !data.pap_affiliate) {
          return;
        }

        setPapConfig(data.pap_affiliate);

        trackingInitInterval = setInterval(() => {
          const { PostAffTracker } = window;

          if (!PostAffTracker) {
            return;
          }

          clearInterval(trackingInitInterval);

          PostAffTracker.setAccountId('default1');

          try {
              // eslint-disable-next-line
              var AffiliateID = aAid;
              // eslint-disable-next-line
              var BannerID = aBid;
              // eslint-disable-next-line
              var CampaignID = papConfig.campaign_id;

              PostAffTracker.track();
          } catch (ex) {}
        }, 250);
      });

    return () => {
      clearInterval(trackingInitInterval);
    };
  }, [ apiUserFetch, aAid, aBid, papConfig.campaign_id ]);

  if (!aAid || !aBid || !papConfig.campaign_id) {
    return null;
  }

  const {
    impression_tracker_url, impression_tracker, campaign_id,
    click_tracker_url, click_tracker
  } = papConfig;

  return <>
    {click_tracker_url && click_tracker && <Helmet script={[
      {
        async: true,
        id: 'pap_x2s6df8d',
        src: `//${click_tracker_url}/scripts/${click_tracker}`
      }
    ]} />}
    <img
      src={`//${impression_tracker_url}/scripts/${impression_tracker}?a_aid=${aAid}&a_bid=${aBid}&a_cid=${campaign_id}`}
      style={{ border: 'none' }}
      width="1"
      height="1"
      alt=""
    />
  </>;
}

export default Pap;
