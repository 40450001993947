import { makeApiFetch } from '..';
import { SET_LOADING, SET, RESET_ALL } from './types';

import sanitizeBonuses from './sanitizeBonuses';


export const setLoading = loading => ({
  type: SET_LOADING,
  loading
});

export const setBonuses = bonuses => ({
  type: SET,
  bonuses
});

export const resetAll = () => ({
  type: RESET_ALL
});

export const fetchBonusesIfNeeded = () => (dispatch, getState) => {
  const { list, loading } = getState().bonuses;

  if (!list && !loading) {
    dispatch(setLoading(true));

    return makeApiFetch(
      'player/bonus/list',
      getState()
    )
      .then(resp => {
        if (
          !resp ||
          !resp.info ||
          !resp.info.success
        ) {
          return;
        }

        dispatch(setBonuses(sanitizeBonuses(resp.data.bonusList)));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }
};
