import React, { useCallback, useState, useRef } from 'react';
import useIsMounted from 'ismounted';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useApiFetch, useApiImageRoot } from '../../hooks';


function Bonus({ bonus, onSubmit, onError, submitting = false }) {
  const { t } = useTranslation();
  const promoInput = useRef(null);
  const apiImageRoot = useApiImageRoot();
  let body = null;

  if (bonus.id === -1) {
    body = (
      <div>
        <h4>{t('cashier:bonus.noBonusText')}</h4>
        <p className="micro:text-lg text-xl">{t('cashier:bonus.activeBonusDeactivation')}</p>
      </div>
    );
  } else if (bonus.id === 0) {
    body = (
      <div>
        <h4>{bonus.name}</h4>
        <p className="micro:text-lg text-xl">{bonus.description}</p>
        <input ref={promoInput} className="form-input mb-3 micro:text-xl text-2xl w-full max-w-sm" name="promotionCode" autoComplete="off" />
      </div>
    );
  } else {
    body = (
      <div>
        <h4>{bonus.name}</h4>
        <p className="micro:text-lg text-xl">{bonus.description}</p>
      </div>
    );
  }

  return (
    <li className="micro:w-full w-1/2 text-center p-2 text-xl sm:text-2xl">
      <div className="paper">
        <img className="w-full mb-2" src={`${apiImageRoot}${bonus.image.url}`} alt={bonus.name} />
        {body}
        <button className="button primary mb-2" disabled={submitting} onClick={() => {
          const data = {
            bonusId: bonus.id
          };

          if (bonus.id === 0) {
            data.promotionCode = promoInput.current.value;

            if (!data.promotionCode) {
              onError(t('cashier:bonus.specifyPromoCode'));
              return;
            }
          }

          onSubmit(data);
        }}>{t(`cashier:bonus.${bonus.id === -1 ? 'noBonusText' : 'activateText'}`)}</button>
      </div>
    </li>
  );
}

function ChooseBonus({ bonuses, method, onSubmit }) {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { apiUserFetch } = useApiFetch();
  const [ submitting, setSubmitting ] = useState(false);
  const [ error, setError ] = useState();
  const handleError = useCallback(error => setError(error), []);
  const handleSubmit = useCallback(data => {
    if (submitting) {
      return;
    }

    if (data.bonusId === -1) {
      onSubmit(t('cashier:bonus.noBonusSelectedText'));
      return;
    }

    setSubmitting(true);

    data.fromCashier = true;

    apiUserFetch(
      'player/bonus/activate',
      {
        method: 'POST',
        data
      }
    )
      .then(({ data, info }) => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);

        if (!info.success) {
          setError(data.message);
          return;
        }

        onSubmit(data.message);
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }

        setError(t('cashier:bonus.unknownErrorText'));
        setSubmitting(false);
      });
  }, [ submitting, apiUserFetch, onSubmit, isMounted, t ]);

  return (
    <>
      <p className="text-center micro:text-xl text-2xl text-primary my-2">{t('cashier:bonus.depositWithMethodText', { methodName: method.name })}</p>
      <p className="text-center micro:text-2xl text-3xl text-primary my-4">{t('cashier:bonus.choseText')}</p>
      {error && <p className="text-center p-2 text-xl sm:text-2xl">{error}</p>}
      <ul className="list-none m-0 micro:block flex flex-wrap items-start justify-center">
        {bonuses.map(b => <Bonus key={b.id} bonus={b} onSubmit={handleSubmit} onError={handleError} submitting={submitting} />)}
      </ul>
    </>
  );
}

export default ChooseBonus;