import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import { remove as removeCookie } from 'js-cookie';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import Seo from '../components/seo';
import StandardPage from './standardPage';
import { resetPlayer, fetchAnonKey } from '../store/player/actions';
import { usePlayer, useApiFetch } from '../hooks';


const Logout = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { username } = usePlayer();
  const { apiUserFetch } = useApiFetch();

  const doLogout = useCallback(() => {
    dispatch(resetPlayer());
    removeCookie('playerKey');
    removeCookie('anonKey');

    dispatch(fetchAnonKey());
  }, [ dispatch ]);

  useEffect(() => {
    if (username) {
      apiUserFetch('player/logout')
        .then(doLogout)
        .catch(doLogout);
    }
  }, [ doLogout, apiUserFetch, username ]);

  const { contentfulPage, alternateLanguagePages } = props.data;
  const { title, metaTitle, metaDescription } = contentfulPage;

  return (
    <StandardPage topPageData={false} {...props}>
      <Seo title={metaTitle} description={metaDescription} alternateLanguagePages={alternateLanguagePages} />
      <div className="centeredContentWrapper">
        <h1 className="text-center">{title}</h1>
        {!!username && (
          <p className="text-center text-2xl sm:text-4xl">{t('auth:logout.loggingOut', { username })}</p>
        )}
        {!username && (
          <>
            <p className="text-center text-xl sm:text-2xl">{t('auth:logout.logoutConfirmation')}</p>
            <div className="flex flex-row justify-center">
              <Link to="/" className="button primary m-2 sm:m-4">{t('auth:homeRedirectButton')}</Link>
              <Link to="/login/" className="button secondary m-2 sm:m-4">{t('auth:loginRedirectButton')}</Link>
            </div>
          </>
        )}
      </div>
    </StandardPage>
  );
};

export default Logout;

export const pageQuery = graphql`
  query($headerImageMaxWidth: Int!, $traceSVGColor: String!, $key: String!, $language: String!) {
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: $headerImageMaxWidth,
          traceSVG: {
            color: $traceSVGColor
          }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contentfulPage(language: {code: {eq: $language}}, key: {eq: $key}) {
      title
      metaTitle
      metaDescription
    }
    alternateLanguagePages: allContentfulPage(filter: {key: {eq: $key}, language: {code: {ne: $language}}}) {
      edges {
        node {
          url
          language {
            code
          }
        }
      }
    }
  }
`;
